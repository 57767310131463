import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from './navbar';
import Footer from './footer';
import axios from '../axios.js';

import { useRef } from 'react';


function Register(){

//VARIABLES
//first_name
const[first_name,setfirst_name]=useState();const updatefirst_name = (event) => { setfirst_name(event.target.value);}; const[first_name_er,setfirst_name_er]=useState();
//last_name
const[last_name,setlast_name]=useState();const updatelast_name = (event) => { setlast_name(event.target.value);}; const[last_name_er,setlast_name_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
//password
const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);}; const[password_er,setpassword_er]=useState();
//cpassword
const[cpassword,setcpassword]=useState();const updatecpassword = (event) => { setcpassword(event.target.value);}; const[cpassword_er,setcpassword_er]=useState();
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();

const [isChecked, setIsChecked] = useState(false); // State variable for checkbox

const handleChange = (event) => {
  setIsChecked(event.target.checked);
}


/***************** REGISTER FUNCTION START  ******************/
const register = (event) =>{

 // event.preventDefault();

    //Setting Error Variables
    setfirst_name_er(" "); setlast_name_er(" ");setemail_er(" ");setcontact_er(" ");setpassword_er(" ");setcpassword_er(" ");
    seterror_msg(" ");
    
    
    //Fetching Form Data
      let noerror = 0;
      const $first_name = document.getElementById("first_name").value; if($first_name==""){ setfirst_name_er("First Name is required !");noerror = noerror+1; }//Fetching First Name Value
      const $last_name = document.getElementById("last_name").value; if($last_name==""){ setlast_name_er("Last Name is required !");noerror = noerror+1; }//Fetching last Name Value
      const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
      const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
      const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1; }//Fetching Password Value
      const $cpassword = document.getElementById("cpassword").value; if($cpassword==""){ setcpassword_er("Confirm Password is required !");noerror = noerror+1; }//Fetching Confirm Password Value
    
      //Fetching User Consent Acceptance
      const iagree = document.getElementById('iagree');
      //User Agrees to Terms and Conditions and privacy policy continue to registration
    if (iagree.checked) {
    
    
    //No primary errors
    if(noerror==0){
      //Sending Data through axios
      axios.post("create-new-user", { 
       First_name:$first_name,
       Last_name:$last_name,
       Email:$email,
       Contact:$contact,
       Password:$password,
       Confirm_password:$cpassword
     
    })
    
     .then((response) => {
    
       //Validation Errors
     if(response.data.validatorerror){
       setfirst_name_er(response.data.validatorerror.First_name);
       setlast_name_er(response.data.validatorerror.Last_name);
       setcontact_er(response.data.validatorerror.Contact);
       setemail_er(response.data.validatorerror.Email);
       setpassword_er(response.data.validatorerror.Password);
       setcpassword_er(response.data.validatorerror.Confirm_password);
          
     }
     //Invalid Entries
     if(response.data.error==102){ 
        
        setfirst_name_er(response.data.first_name_er); 
        setlast_name_er(response.data.last_name_er); 
        setemail_er(response.data.email_er); 
        setcontact_er(response.data.contact_er); 
        setpassword_er(response.data.password_er); 
        setcpassword_er(response.data.cpassword_er);    
    
    
    }
    
    //Password and Confirm Password don't match
     if(response.data.error==103){ seterror_msg(response.data.password_er); }
    
     
    //Username Already Exists
    if(response.data.error==104){ seterror_msg(response.data.error_msg); }
    
    
    //Name Already Exists
    if(response.data.error==105){ seterror_msg(response.data.error_msg); }
    
     //Success
     if(response.data.successvar==1){
      
     //window.location.replace("/businesstools-startworking");
     document.getElementById("success-card").style.display="block";
     
     }
     //failure
     if(response.data.successvar==2){
     seterror_msg("Account Not Created!");
     }
    }    );
    
    }
    //Return Errors
    else{
      return;
      }
    
    }
    //User Not Select the CheckBox
    else{
      //Show error
      seterror_msg("You need to agree to our Terms and Conditions and Privacy Policy to Continue!");
    }
    
    

  //  formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

    
    
    }
    /***************** REGSITER FUNCTION END  ******************/


return(
<>

<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="description" content="Ashion Template" /> 
    <meta name="keywords" content="Ashion, unica, creative, html" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />

   {/* CUSTOM GOOGLE FONTS IMPORTED */}
   <link rel="preconnect" href="https://fonts.googleapis.com" />
   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
   <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />

    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 
    
</head>

<body>
   <Navbar/>

    {/* Breadcrumb Begin */}
    <div class="breadcrumb-option">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb__links">
                        <a href="/"><i class="fa fa-home"></i> Home</a>
                        <span>Register</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb End */}
  
    {/* Checkout Section Begin */}
    <section class="checkout spad">
        <div class="container">

        {/* SUCCESS CARD START */}
        <div class="alert alert-success" role="alert" id="success-card" style={{textAlign:"center", display:"none"}}>
        <h5 style={{color:"#046b42"}}><b>Account Created Successfully !</b></h5>
        <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/login"); }}>Continue to login page</button>
        </div>
        {/* SUCCESS CARD END */}

            <div class="row">
                <div class="col-lg-12">
                    <h4 class="coupon__link"> Register Now and Get Started with your Journey with us !!!</h4>
                   
                </div>
            </div>
            <form class="checkout__form" onSubmit={e => e.preventDefault()} /*ref={formRef} onSubmit={handleSubmit}*/>
                <div class="row">
                    <div class="col-lg-8">
                        <h5>Personal Details</h5>
                        <code>{error_msg}</code>
                        <div class="row mt-4">
                            <div class="col-lg-6 col-md-6 col-sm-6 mt-2">
                                <div class="checkout__form__input">
                                    <p>First Name <span>*</span></p>
                                    <input type="text" placeholder="Stuart" class="form-control" id="first_name" onChange={updatefirst_name} />
                                    <code>{first_name_er}</code>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 mt-2">
                                <div class="checkout__form__input">
                                    <p>Last Name <span>*</span></p>
                                    <input type="text" placeholder="Doe" class="form-control" id="last_name" onChange={updatelast_name} />
                                    <code>{last_name_er}</code>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 mt-2">
                                <div class="checkout__form__input">
                                    <p>Phone <span>*</span></p>
                                    <input type="text" placeholder="91 8000000000" class="form-control" id="contact" onChange={updatecontact} />
                                    <code>{contact_er}</code>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 mt-2">
                                <div class="checkout__form__input">
                                    <p>Email <span>*</span></p>
                                    <input type="text" placeholder="myemail@gmail.com" class="form-control" id="email" onChange={updateemail} />
                                    <code>{email_er}</code>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 mt-2">
                                <div class="checkout__form__input">
                                    <p>Password<span>*</span></p>
                                    <input type="password" placeholder="**********" class="form-control" id="password" onChange={updatepassword} />
                                    <code>{password_er}</code>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 mt-2">
                                <div class="checkout__form__input">
                                    <p>Confirm Password <span>*</span></p>
                                    <input type="password" placeholder="**********" class="form-control" id="cpassword" onChange={updatecpassword} />
                                    <code>{cpassword_er}</code>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-2">
                                <div class="checkout__form__checkbox">
                                   {/*} <label for="acc">
                                        I agree with the terms and conditions and privacy policy of Whatjhumka.in
                                        <input type="checkbox"  id="iagree" checked={isChecked} onChange={handleChange} />
                                        <span class="checkmark"></span>
                                    </label> */}
                                    <input type="checkbox" id="iagree" checked={isChecked} onChange={handleChange}></input> 
                                     I agree with the terms and conditions and privacy policy of Whatjhumka.in
                                 {/*}   <p>Create am acount by entering the information below. If you are a returing
                                        customer login at the <br />top of the page</p> */}
                                    </div>
                                {/*}    <div class="checkout__form__input">
                                        <p>Account Password <span>*</span></p>
                                        <input type="text" />
                                    </div>
                                    <div class="checkout__form__checkbox">
                                        <label for="note">
                                            Note about your order, e.g, special noe for delivery
                                            <input type="checkbox" id="note" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="checkout__form__input">
                                        <p>Oder notes <span>*</span></p>
                                        <input type="text"
                                        placeholder="Note about your order, e.g, special noe for delivery" />
                                    </div> */}
                                    <button type="submit" style={{backgroundColor:"#004761"}} onClick={register} class="site-btn">Register me</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="checkout__order">
                             <img src="https://images.pexels.com/photos/7919082/pexels-photo-7919082.jpeg?auto=compress&cs=tinysrgb&w=600" height="100%" width="100%" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        {/* Checkout Section End */}

     

       <Footer/>

        {/* Js Plugins */}
        <script src="js/jquery-3.3.1.min.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/jquery.magnific-popup.min.js"></script>
        <script src="js/jquery-ui.min.js"></script>
        <script src="js/mixitup.min.js"></script>
        <script src="js/jquery.countdown.min.js"></script>
        <script src="js/jquery.slicknav.js"></script>
        <script src="js/owl.carousel.min.js"></script>
        <script src="js/jquery.nicescroll.min.js"></script>
        <script src="js/main.js"></script>
    </body>

    </html>
</>
);


}

export default Register;