import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from '../Supportingfiles/navbar';
import Footer from '../Supportingfiles/footer';
import axios from '../axios.js';



  //Checking Sessions
  // Access value associated with the login status
 
  //Checking Sessions
  // Access value associated with the login status
  let logstatus=sessionStorage.getItem("logStatus00");if((logstatus==null)||(logstatus==" ")||(logstatus=="undefined")){ logstatus=true; }
  let logid=sessionStorage.getItem("logId00");if((logid==null)||(logid==" ")||(logid=="undefined")){ logid=0; }
  let token=sessionStorage.getItem("logToken00");if((token==null)||(token==" ")||(token=="undefined")){ token="0000000000000000000000000"; }
  let logname=sessionStorage.getItem("logName00");if((logname==null)||(logname==" ")||(logname=="undefined")){ logname="000"; }
  let logtype=sessionStorage.getItem("logType00");if((logtype==null)||(logtype==" ")||(logtype=="undefined")){ logtype="user"; }
  const product_id=sessionStorage.getItem("productId00");


function Productdetails(){


    const[data,setData] = useState([]);//SetData Variable
    const[data1,setData1] = useState([]);//SetData1 Variable
   
     //const imgpath = 'http://127.0.0.1:8000/storage/';
     //const imgpath = 'https://api.whatjhumka.in/storage/';
     const imgpath = 'https://whatjhumka.in/api/storage/';
   
   const [error_msg, seterror_msg] = useState();
   const [error_msg1, seterror_msg1] = useState();
   const [wishlist_action, setwishlist_action] = useState();
   
   //SETTING PAGE VARIABLES
   const [product_code, setproduct_code] = useState();
   const [product_hsn, setproduct_hsn] = useState();
   const [product_brand, setproduct_brand] = useState();
   const [product_name, setproduct_name] = useState();
   const [product_label, setproduct_label] = useState();
   const [product_text, setproduct_text] = useState();
   const [product_description, setproduct_description] = useState();
   const [product_rating, setproduct_rating] = useState();
   const [product_price, setproduct_price] = useState();
   const [product_mrp, setproduct_mrp] = useState();
   const [product_image_link, setproduct_image_link] = useState();
   const [product_category, setproduct_category] = useState();

   const [review_count, setreview_count] = useState();

   const [cart_quantity, setcart_quantity] = useState(1);const updatecart_quantity = (event) => { setcart_quantity(event.target.value);}; const[cart_quantity_er,setcart_quantity_er]=useState();

   useEffect(()=>{
      
       getData();
       getReviews();
       
       },[])
       
     //******************* GET DATA FUNCTION START  ********************//
     function getData(){
     
        //Checking if user loggedin
       if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(product_id>0)){

       //Checking if user loggedin
      
      axios.post("view-product_details", {
        Log_id:logid,
        Token:token,
        Product_id:product_id,
       
     
        })
      .then((response) => {
     
       //Success Data Fetched
       if(response.data.successvar==1){

        //Setting Values
        setproduct_code(response.data.product_code);
        setproduct_hsn(response.data.product_hsn);
        setproduct_name(response.data.product_name);
        setproduct_brand(response.data.product_brand);
        setproduct_rating(response.data.product_rating);
        setproduct_mrp(response.data.product_mrp);
        setproduct_price(response.data.product_price);
        setproduct_text(response.data.product_text);
        setproduct_description(response.data.product_description);
        setproduct_label(response.data.product_label);
        setproduct_category(response.data.product_category);
        setproduct_image_link((response.data.product_image_link).slice(7));

        setreview_count(response.data.review_count);

         //Setting Data
        // setData(response.data.data.data);
     
       }
       //Cannot Fetch Data
      else{
      // alert("Cannot Fetch Data");
      window.location.replace("/login");
      }
      
     
      
      });
      
    }
      //User not Logedin
    else{

    //Remove Session Variables and redirect to login
   /* sessionStorage.removeItem("logStatus00");
    sessionStorage.removeItem("logId00");
    sessionStorage.removeItem("logToken00");
    sessionStorage.removeItem("logName00"); */
    sessionStorage.removeItem("productId00");
    window.location.replace("/");
    
    
    }    

     }
      
      //******************* GET DATA FUNCTION END  ********************//
   

      //REVIEW FUNCTION VARIABLES
      //title
      const[title,settitle]=useState();const updatetitle = (event) => { settitle(event.target.value);}; const[title_er,settitle_er]=useState();  
      //rating
      const[rating,setrating]=useState();const updaterating = (event) => { setrating(event.target.value);}; const[rating_er,setrating_er]=useState();  
      //feedback
      const[feedback,setfeedback]=useState();const updatefeedback = (event) => { setfeedback(event.target.value);}; const[feedback_er,setfeedback_er]=useState();  
      //review error msg
      const[review_error_msg, setreview_error_msg]=useState();

      /***************** CREATE REVIEW FUNCTION START  ******************/
     function createreview(){

  
    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(product_id>0)){
  
  
    //Setting Error Variables
    settitle_er(" ");setrating_er(" ");setfeedback_er(" ");setreview_error_msg(" ");

    
  //Fetching Form Data
  let noerror = 0;
  const $title = document.getElementById("title").value; 
  const $rating = document.getElementById("rating").value; 
  const $feedback = document.getElementById("feedback").value; 
  //const $review_error_msg = document.getElementById("review_error_msg").value; 
  
  //No primary errors
  if(noerror==0){
  
  
    //Sending Data through axios
    axios.post("add-new-product-review", { 
    Log_id:logid,
    Token:token,
    Title:$title,
    Rating:$rating,
    Feedback:$feedback,
    Product_id:product_id,
  
    }
      )
    
    .then((response) => {
    
    //Validation Errors
    if(response.data.validatorerror){
    settitle_er(response.data.validatorerror.Title);
    setrating_er(response.data.validatorerror.Rating);
    setfeedback_er(response.data.validatorerror.Feedback);
   // setproduct_id_er(response.data.validatorerror.Product_id);
    setreview_error_msg("Cannot Add Your Review !");
    
    
    }
    //Invalid Entries
    if(response.data.error==102){ 
    settitle_er(response.data.title_er);
    setrating_er(response.data.rating_er);
    setfeedback_er(response.data.feedback_er);
    //  setproduct_id_er(response.data.product_id_er);

    setreview_error_msg("Cannot Add Your Review !");
    
    
    }
    
    //Mentor Already Exists
    if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
    
    
    
    //Success
    if(response.data.successvar==1){
    
    document.getElementById("review-success-card").style.display="block";
  
    
    }
    //failure
    if(response.data.successvar==6){
    setreview_error_msg("Cannot Add Your Review !");
    }
    }    );
    
    }
    
  
    }
    //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   
   window.location.replace("/");
   
   
   }    
  
  }
  
  /***************** CREATE REVIEW FUNCTION END  ******************/
  
  
  //******************* GET REVIEWS FUNCTION START  ********************//
  function getReviews(){
  
    //Checking if user loggedin
   
   axios.post("get-product-reviews", {
    Log_id:logid,
    Token:token,
    Product_id:product_id
  
   })
   .then((response) => {
  
    //Success Data Fetched
    if(response.data.successvar==1){
  
      //Setting Data
      setData1(response.data.data.data);
  
    }
    //Cannot Fetch Data
   else{
  //  alert("Cannot Fetch Data");
  return;
   }
   
  
   
   });
  }
 //******************* GET REVIEWS FUNCTION END  ********************// 


 
/***************ADD PRODUCT TO WISHLIST FUNCTION START ******************/ 
function addtowishlist(){


    const wishlist_product_id = product_id;

     //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(wishlist_product_id>0)){
    
    
    //Setting Error Variables
   // seterror_msg(" "); //setdisplay_rack_id_er(" ");
    
    //Fetching Form Data
    
    //No primary errors
    
    
    //Sending Data through axios
    axios.post("manage-wishlist-product", { 
    Log_id:logid,
    Token:token,
    Product_id:wishlist_product_id,
    Quantity:1,
    // Action: wishlist_action
    Action:"add"
    
    }
    )
    
    .then((response) => {

          //Session Expired
          if(response.data.error==100){
           //Set Sessions
           sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
           sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
           sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
           sessionStorage.setItem("logType00", "user"); //Assign value to logtype
           //  sessionStorage.setItem("logName00", response.data.name); //Assign value to token
           window.location.replace("/");
           return;
    
    
           }
    
    
    //Success
    if(response.data.successvar==1){
    
    document.getElementById("success-card").style.display="block";
    
    
    }
    else{
    //Validation Errors
    if(response.data.validatorerror){
    //  setdisplay_product_id_er(response.data.validatorerror.Product_id);
    //setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
   // seterror_msg("Cannot Add Product to Wishlist !");
    //document.getElementById("error-card").style.display="block";
    
    
    }
    //Invalid Entries
    else if(response.data.error==102){ 
    
    // setdisplay_product_id_er(response.data.product_id_er);
    //setdisplay_rack_id_er(response.data.shop_id_er);
   // seterror_msg("Cannot Add Product to Wishlist !");
   //document.getElementById("error-card").style.display="block";
    
    }
    
    //Product Not Found
    else if(response.data.error==103){
    
  //  seterror_msg(response.data.error_msg);
//document.getElementById("error-card").style.display="block";
    
    }
    
    
    //failure
    else{
    //seterror_msg(response.data.error_msg);
    //document.getElementById("error-card").style.display="block";
    
    }
    }
    }    );
    
    
    
    
    }
    //User not Logedin
    else{
    
    //Remove Session Variables and redirect to login
    sessionStorage.removeItem("logStatus00");
    sessionStorage.removeItem("logId00");
    sessionStorage.removeItem("logToken00");
    sessionStorage.removeItem("logName00");
    
    window.location.replace("/");
    
    
    }    
}
    
    /***************** ADD PRODUCT TO WISHLIST FUNCTION END  ******************/
    

   
  /************************ADD TO CART FUNCTION START **********************/
  function addtocart(){

      
    let cart_quantity =1;
    cart_quantity = document.getElementById("cart-quantity").value;
    const cart_product_id = product_id;
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(cart_product_id>0)){
  
  
  //Setting Error Variables
  seterror_msg(" "); //setdisplay_rack_id_er(" ");
  
  //Fetching Form Data
  
  //No primary errors
  
  
  //Sending Data through axios
  axios.post("manage-cart-product", { 
  Log_id:logid,
  Token:token,
  Product_id:cart_product_id,
  Quantity:cart_quantity,
  // Action: wishlist_action
  Action:"add"
  
  }
  )
  
  .then((response) => {
  
  
  
  //Session Expired
  if(response.data.error==100){
  //Set Sessions
  sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
  sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
  sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
  sessionStorage.setItem("logType00", "user"); //Assign value to logtype
  //  sessionStorage.setItem("logName00", response.data.name); //Assign value to token
  window.location.replace("/shop");
  return;
  
  
  }
  
  //Success
  if(response.data.successvar==1){
  
  
  document.getElementById("success-card1").style.display="block";
  
  
  }
  else{
  //Validation Errors
  if(response.data.validatorerror){
  //  setdisplay_product_id_er(response.data.validatorerror.Product_id);
  //setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
  //seterror_msg1("Cannot Add Product to Cart !");
  //document.getElementById("error-card1").style.display="block";
  
  
  }
  //Invalid Entries
  else if(response.data.error==102){ 
  
  // setdisplay_product_id_er(response.data.product_id_er);
  //setdisplay_rack_id_er(response.data.shop_id_er);
  //seterror_msg1("Cannot Add Product to Cart !");
  //document.getElementById("error-card1").style.display="block";
  
  }
  
  //Product Not Found
  else if(response.data.error==103){
  
 // seterror_msg1(response.data.error_msg);
  //document.getElementById("error-card1").style.display="block";
  
  }
  
  
  //failure
  else{
  //seterror_msg1(response.data.error_msg);
  //document.getElementById("error-card1").style.display="block";
  
  }
  }
  }    );
  
  
  
  
  }
  //User not Logedin
  else{

    alert("this is")
  
  //Remove Session Variables and redirect to login
  sessionStorage.removeItem("logStatus00");
  sessionStorage.removeItem("logId00");
  sessionStorage.removeItem("logToken00");
  sessionStorage.removeItem("logName00");
  
  window.location.replace("/");
  
  
  }    
  }
  /************************ADD TO CART FUNCTION END **********************/


return(
<>

<html lang="zxx">

<head>
    <meta charset="UTF-8" />
    <meta name="description" content="Ashion Template" />
    <meta name="keywords" content="Ashion, unica, creative, html" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />

   {/* CUSTOM GOOGLE FONTS IMPORTED */}
   <link rel="preconnect" href="https://fonts.googleapis.com" />
   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
   <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />



    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 
    
</head>

<body>
    <Navbar/>

    {/* Breadcrumb Begin */}
    <div class="breadcrumb-option">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb__links">
                        <a href="./shop"><i class="fa fa-home"></i> Home</a>
                        <a href="#">#{product_code} </a>
                        <span>{product_name}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb End */}

    {/* Product Details Section Begin */}
    <section class="product-details spad">
        <div class="container">

              {/* WISHLIST SUCCESS CARD START */}
              <div class="alert alert-success" role="alert" id="success-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Product Successfully Added to Wishlist !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/product-details"); }}>Continue to shopping</button>
                </div>
               {/* WISHLIST SUCCESS CARD END */}

                {/* WISHLIST ERROR CARD START */}
                <div class="alert alert-danger" role="alert" id="error-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#cf6969"}}><b>{error_msg}</b></h5>
                <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/product-details"); }}>Continue to shopping</button>
                </div>
               {/* WISHLIST ERROR CARD END */}

               {/* CART SUCCESS CARD START */}
                <div class="alert alert-success" role="alert" id="success-card1" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Product Successfully Added to Cart !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/product-details"); }}>Continue to shopping</button>
                </div>
               {/* CART SUCCESS CARD END */}

                {/* CART ERROR CARD START */}
                <div class="alert alert-danger" role="alert" id="error-card1" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#cf6969"}}><b>{error_msg1}</b></h5>
                <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/product-details"); }}>Continue to shopping</button>
                </div>
               {/* CART ERROR CARD END */}


            <div class="row">
                <div class="col-lg-6">
                 {/*}   <div class="product__details__pic">
                        <div class="product__details__pic__left product__thumb nice-scroll">
                            <a class="pt active" href="#product-1">
                                <img src="img/product/details/thumb-1.jpg" alt="" />
                            </a>
                            <a class="pt" href="#product-2">
                                <img src="img/product/details/thumb-2.jpg" alt="" />
                            </a>
                            <a class="pt" href="#product-3">
                                <img src="img/product/details/thumb-3.jpg" alt="" />
                            </a>
                            <a class="pt" href="#product-4">
                                <img src="img/product/details/thumb-4.jpg" alt="" />
                            </a>
                        </div>
                        <div class="product__details__slider__content">
                            <div class="product__details__pic__slider owl-carousel" >
                                <img data-hash="product-1" class="product__big__img" src="img/product/details/product-1.jpg" alt="" />
                                <img data-hash="product-2" class="product__big__img" src="img/product/details/product-3.jpg" alt="" />
                                <img data-hash="product-3" class="product__big__img" src="img/product/details/product-2.jpg" alt="" />
                                <img data-hash="product-4" class="product__big__img" src="img/product/details/product-4.jpg" alt="" />
                            </div>
                        </div>
                    </div> */}
                    <img src={imgpath+product_image_link} style={{height:"90%", width:"80%", borderRadius:"0.7rem", boxShadow:"1px 2px 2px 1px #e0e0e0"}}></img>
                </div>
                <div class="col-lg-6">
                    <div class="product__details__text">
                        <h3>{product_name} <span>Brand: {product_brand}</span></h3>
                        <div class="rating">
                            <i style={{marginLeft:"0.1rem"}} class="fa fa-star" hidden={product_rating<5} ></i>
                            <i style={{marginLeft:"0.1rem"}} class="fa fa-star" hidden={product_rating<4} ></i>
                            <i style={{marginLeft:"0.1rem"}} class="fa fa-star" hidden={product_rating<3}></i>
                            <i style={{marginLeft:"0.1rem"}} class="fa fa-star" hidden={product_rating<2}></i>
                            <i style={{marginLeft:"0.1rem"}} class="fa fa-star" hidden={product_rating<1}></i>
                            <span>( {review_count} reviews )</span>
                        </div>
                        <div class="product__details__price" style={{color:"#002017"}}>₹ {product_price} <span>₹ {product_mrp}</span></div>
                        <p>{product_text}</p>
                        <div class="product__details__button">
                            <div class="quantity">
                                <span>Quantity:</span>
                                <div class="pro-qty">
                                    <input type="text" id="cart-quantity" value={cart_quantity} onChange={updatecart_quantity} />
                                </div>
                            </div>
                            <a style={{backgroundColor:"#002017"}} onClick={()=>{
                                addtocart();
                            }}
                                         class="cart-btn"><span class="icon_bag_alt"></span> Add to cart</a>
                            <ul>
                                <li onClick={()=>{
                                    document.getElementById("wishlist-btn").style.backgroundColor="black";
                                    document.getElementById("wishlist-btn-icon").style.color="white";
                                    addtowishlist();}}><a  id="wishlist-btn"><span class="icon_heart_alt" id="wishlist-btn-icon" ></span></a></li>
                               {/*} <li><a  ><span class="icon_adjust-horiz"></span></a></li> */}
                            </ul>
                        </div>
                        <div class="product__details__widget">
                            <ul>
                                <li>
                                    <span>Label:</span>
                                    <div class="stock__checkbox">
                                        <label for="stockin">
                                            {product_label}
                                          
                                          
                                        </label>
                                    </div>
                                </li>
                              {/*}  <li>
                                    <span>Available color:</span>
                                    <div class="color__checkbox">
                                        <label for="red">
                                            <input type="radio" name="color__radio" id="red" checked />
                                            <span class="checkmark"></span>
                                        </label>
                                        <label for="black">
                                            <input type="radio" name="color__radio" id="black" />
                                            <span class="checkmark black-bg"></span>
                                        </label>
                                        <label for="grey">
                                            <input type="radio" name="color__radio" id="grey" />
                                            <span class="checkmark grey-bg"></span>
                                        </label>
                                    </div>
                                </li> */}
                              {/*}  <li>
                                    <span>Available size:</span>
                                    <div class="size__btn">
                                        <label for="xs-btn" class="active">
                                            <input type="radio" id="xs-btn" />
                                            xs
                                        </label>
                                        <label for="s-btn">
                                            <input type="radio" id="s-btn" />
                                            s
                                        </label>
                                        <label for="m-btn">
                                            <input type="radio" id="m-btn" />
                                            m
                                        </label>
                                        <label for="l-btn">
                                            <input type="radio" id="l-btn" />
                                            l
                                        </label>
                                    </div>
                                </li>*/}
                                <li>
                                    <span>Category:</span>
                                    <p>{product_category}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="product__details__tab">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab" onClick={()=>{ document.getElementById("tabs-1").style.display="block"; document.getElementById("tabs-3").style.display="none";      }}>Description</a>
                            </li>
                       {/*}     <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Specification</a>
                            </li> */}
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab"  onClick={()=>{ document.getElementById("tabs-3").style.display="block"; document.getElementById("tabs-1").style.display="none";      }}>Reviews ( {review_count} )</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="tabs-1" role="tabpanel">
                                <h6>Description</h6>
                                <p>{product_description}</p>
                            </div>
                         {/*}   <div class="tab-pane" id="tabs-2" role="tabpanel">
                                <h6>Specification</h6>
                                <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut loret fugit, sed
                                    quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt loret.
                                    Neque porro lorem quisquam est, qui dolorem ipsum quia dolor si. Nemo enim ipsam
                                    voluptatem quia voluptas sit aspernatur aut odit aut loret fugit, sed quia ipsu
                                    consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Nulla
                                consequat massa quis enim.</p>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget
                                    dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,
                                    nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium
                                quis, sem.</p>
                            </div> */}
                            <div class="tab-pane" id="tabs-3" role="tabpanel">
                            <div class="d-flex justify-content-end" style={{marginBottom:"2rem"}}>
                      <button class="btn btn-success" onClick={()=>{
                      const createForm = document.getElementById('review-card');
                      const currentDisplay = createForm.style.display;
                      createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                      }}>Add Review</button>
                            </div>
                            
               {/* REVIEW SUCCESS CARD START */}
              <div class="alert alert-success" role="alert" id="review-success-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Review Added Successfully !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/product-details"); }}>Continue to shopping</button>
                </div>
               {/* REVIEW SUCCESS CARD END */}

                  {/* ADD REVIEW FORM START */}
                  <form class="checkout__form" id="review-card" style={{display:"none"}}>
                    <div class="row" style={{marginBottom:"8rem"}}>
                    <div class="col-lg-8">
                        <h5>ADD REVIEW</h5>
                        <code>{review_error_msg}</code>
                        <div class="row">
                          <div class="col-lg-12">
                                    <div class="checkout__form__input mt-3">
                                        <p>Title <span>*</span></p>
                                        <input type="text" placeholder="My Review Title"  id="title" onChange={updatetitle}  />
                                        <code>{title_er}</code>
                                    </div>
                                    <div class="checkout__form__input mt-3">
                                        <p>Rating <span>*</span></p>
                                        <select type="text"  id="rating" class="form-control" onChange={updaterating}>
                                        <option option="5">5</option>
                                        <option option="4">4</option>
                                        <option option="3">3</option>
                                        <option option="2">2</option>
                                        <option option="1">1</option>
                                        </select>
                                        <code>{rating_er}</code>
                                    </div>
                                    <div class="checkout__form__input mt-3">
                                        <p>Feedback <span>*</span></p>
                                        <input type="text" placeholder="my feedback for the product is" id="feedback" onChange={updatefeedback}  />
                                        <code>{feedback_er}</code>
                                    </div>
                               
                                    <button type="submit" style={{backgroundColor:"#004761"}} class="site-btn mt-3" onClick={createreview}>Submit Review</button>
                                    </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                        <div class="checkout__order">
                             <img src="https://img.freepik.com/free-vector/isometric-feedback-concept-with-people-giving-stars_52683-62300.jpg?t=st=1713891931~exp=1713895531~hmac=03acc7a676b0350a26a77e73049d8b86dd56a1cc8d29d65e1dc3cbf5a7e3dd41&w=740" height="100%" width="100%" />
                            </div>
                        </div>
                    </div>
                </form>
                {/* ADD REVIEW FORM END */}
                <h6>Reviews ( {review_count} )</h6>
                {
                       data1.map((curElm1,ind)=>{

                         return(
                         <>
                            <div>
                               
                                <p>                        
                                <div class="rating">
                                <i class="fa fa-star" style={{color:"#eadc0d"}} hidden={curElm1.Rating==5}></i>
                                <i class="fa fa-star" style={{color:"#eadc0d"}} hidden={curElm1.Rating==4}></i>
                                <i class="fa fa-star" style={{color:"#eadc0d"}} hidden={curElm1.Rating==3}></i>
                                <i class="fa fa-star" style={{color:"#eadc0d"}} hidden={curElm1.Rating==2}></i>
                                <i class="fa fa-star" style={{color:"#eadc0d"}} hidden={curElm1.Rating==1}></i>
                                </div></p>
                                <h5><b>{curElm1.Review_title}</b></h5>
                                <p>{curElm1.Review_message}</p>
                                <p style={{textAlign:"right", color:"#237084"}}><b>{curElm1.First_name} {curElm1.Last_name}</b></p>
                              
                            </div>
                            </>
                         )
                         }
					   
	                  		 )
                         }
                 

                            <hr/>

                       
                            </div>

                        </div>
                    </div>
                </div>
            </div>
         
          {/*}  <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="related__title">
                        <h5>RELATED PRODUCTS</h5>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="product__item">
                        <div class="product__item__pic set-bg" data-setbg="img/product/related/rp-1.jpg" style={{backgroundImage:"url(img/product/related/rp-1.jpg)"}}>
                            <div class="label new">New</div>
                            <ul class="product__hover">
                                <li><a href="img/product/related/rp-1.jpg" class="image-popup"><span class="arrow_expand"></span></a></li>
                                <li><a href="#"><span class="icon_heart_alt"></span></a></li>
                                <li><a href="#"><span class="icon_bag_alt"></span></a></li>
                            </ul>
                        </div>
                        <div class="product__item__text">
                            <h6><a href="#">Buttons tweed blazer</a></h6>
                            <div class="rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <div class="product__price">$ 59.0</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="product__item">
                        <div class="product__item__pic set-bg" data-setbg="img/product/related/rp-2.jpg" style={{backgroundImage:"url(img/product/related/rp-2.jpg)"}}>
                            <ul class="product__hover">
                                <li><a href="img/product/related/rp-2.jpg" class="image-popup"><span class="arrow_expand"></span></a></li>
                                <li><a href="#"><span class="icon_heart_alt"></span></a></li>
                                <li><a href="#"><span class="icon_bag_alt"></span></a></li>
                            </ul>
                        </div>
                        <div class="product__item__text">
                            <h6><a href="#">Flowy striped skirt</a></h6>
                            <div class="rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <div class="product__price">$ 49.0</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="product__item">
                        <div class="product__item__pic set-bg" data-setbg="img/product/related/rp-3.jpg" style={{backgroundImage:"url(img/product/related/rp-3.jpg)"}}>
                            <div class="label stockout">out of stock</div>
                            <ul class="product__hover">
                                <li><a href="img/product/related/rp-3.jpg" class="image-popup"><span class="arrow_expand"></span></a></li>
                                <li><a href="#"><span class="icon_heart_alt"></span></a></li>
                                <li><a href="#"><span class="icon_bag_alt"></span></a></li>
                            </ul>
                        </div>
                        <div class="product__item__text">
                            <h6><a href="#">Cotton T-Shirt</a></h6>
                            <div class="rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <div class="product__price">$ 59.0</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <div class="product__item">
                        <div class="product__item__pic set-bg" data-setbg="img/product/related/rp-4.jpg" style={{backgroundImage:"url(img/product/related/rp-4.jpg)"}}>
                            <ul class="product__hover">
                                <li><a href="img/product/related/rp-4.jpg" class="image-popup"><span class="arrow_expand"></span></a></li>
                                <li><a href="#"><span class="icon_heart_alt"></span></a></li>
                                <li><a href="#"><span class="icon_bag_alt"></span></a></li>
                            </ul>
                        </div>
                        <div class="product__item__text">
                            <h6><a href="#">Slim striped pocket shirt</a></h6>
                            <div class="rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <div class="product__price">$ 59.0</div>
                        </div>
                    </div>
                </div>
            </div>
            */}
        </div>
    </section>
    {/* Product Details Section End */}

    {/* Instagram Begin 
    <div class="instagram">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-1.jpg" style={{backgroundImage:"url(img/instagram/insta-1.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-2.jpg" style={{backgroundImage:"url(img/instagram/insta-2.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-3.jpg" style={{backgroundImage:"url(img/instagram/insta-3.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-4.jpg" style={{backgroundImage:"url(img/instagram/insta-4.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-5.jpg" style={{backgroundImage:"url(img/instagram/insta-5.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-6.jpg" style={{backgroundImage:"url(img/instagram/insta-6.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Instagram End */}

    <Footer/>

    {/* Js Plugins */}
    <script src="js/jquery-3.3.1.min.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script src="js/jquery.magnific-popup.min.js"></script>
    <script src="js/jquery-ui.min.js"></script>
    <script src="js/mixitup.min.js"></script>
    <script src="js/jquery.countdown.min.js"></script>
    <script src="js/jquery.slicknav.js"></script>
    <script src="js/owl.carousel.min.js"></script>
    <script src="js/jquery.nicescroll.min.js"></script>
    <script src="js/main.js"></script>
</body>

</html>
</>
);


}

export default Productdetails;