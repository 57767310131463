import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from '../Supportingfiles/navbar';
import Footer from '../Supportingfiles/footer';
import axios from '../axios.js';


  //Checking Sessions
  // Access value associated with the login status
  /*const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");*/

  
  //Checking Sessions
  // Access value associated with the login status
  let logstatus=sessionStorage.getItem("logStatus00");if((logstatus==null)||(logstatus==" ")){ logstatus=true; }
  let logid=sessionStorage.getItem("logId00");if((logid==null)||(logid==" ")){ logid=0; }
  let token=sessionStorage.getItem("logToken00");if((token==null)||(token==" ")){ token="0000000000000000000000000"; }
  let logname=sessionStorage.getItem("logName00");if((logname==null)||(logname==" ")){ logname="000"; }
  let logtype=sessionStorage.getItem("logType00");if((logtype==null)||(logtype==" ")){ logtype="user"; }




function Home_waaliyan(){

    const [bnb, setbnb] = useState(0);
    
 const[data,setData] = useState([]);//SetData Variable

 //Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

  //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://api.whatjhumka.in/storage/';
  const imgpath = 'https://whatjhumka.in/api/storage/';

  
//const imgpath = 'https://thestartupconnect.com/apidata/storage/';


//Wishlist Product Id
//const [wishlist_product_id, setwishlist_product_id] = useState(1);
const [error_msg, seterror_msg] = useState();
const [error_msg1, seterror_msg1] = useState();
const [wishlist_action, setwishlist_action] = useState();


//Filters Selected
const [selectedItems, setSelectedItems] = useState([]);


const [filter_val, setfilter_val] = useState(0);
//filter_start_price
const[filter_start_price,setfilter_start_price]=useState();const updatefilter_start_price = (event) => { setfilter_start_price(event.target.value);}; const[filter_start_price_er,setfilter_start_price_er]=useState();  
//filter_end_price
const[filter_end_price,setfilter_end_price]=useState();const updatefilter_end_price = (event) => { setfilter_end_price(event.target.value);}; const[filter_end_price_er,setfilter_end_price_er]=useState();  
//filter_label
const[filter_label,setfilter_label]=useState();const updatefilter_label = (event) => { setfilter_label(event.target.value);}; const[filter_label_er,setfilter_label_er]=useState();  
//filter_product_name
const[filter_product_name,setfilter_product_name]=useState();const updatefilter_product_name = (event) => { setfilter_product_name(event.target.value);}; const[filter_product_name_er,setfilter_product_name_er]=useState();  


useEffect(()=>{
   
    getData();
    
    },[])
    
  //******************* GET DATA FUNCTION START  ********************//
  function getData(){
  
    //Checking if user loggedin
   
   axios.post("get-shop-default-load", {
   //  Log_id:logid,
   //  Token:token,
     Shop_id:3,
     Filter_val:filter_val,
     //Filters
     Filter_start_price:filter_start_price,
     Filter_end_price:filter_end_price,
     Filter_label:filter_label,
     Filter_product_name:filter_product_name,
     //Code Edits for pagination
     'page' : pageNumber
  
     })
   .then((response) => {
  
    //Success Data Fetched
    if(response.data.successvar==1){
  
      //Setting Data
      setData(response.data.data.data);
  
    }
    //Cannot Fetch Data
   else{
    alert("Cannot Fetch Data");
   }
   
  
   
   });
   
   
  }
   
   //******************* GET DATA FUNCTION END  ********************//


  /************************ADD TO CART FUNCTION START **********************/
  function addtocart(){

      
    let cart_quantity =1;
    cart_quantity = document.getElementById("crt-quantity").value;
    const cart_product_id = sessionStorage.getItem("productId00");
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(cart_product_id>0)){
  
  
  //Setting Error Variables
  seterror_msg(" "); //setdisplay_rack_id_er(" ");
  
  //Fetching Form Data
  
  //No primary errors
  
  
  //Sending Data through axios
  axios.post("manage-cart-product", { 
  Log_id:logid,
  Token:token,
  Product_id:cart_product_id,
  Quantity:cart_quantity,
  // Action: wishlist_action
  Action:"add"
  
  }
  )
  
  .then((response) => {
  
  
  
  //Session Expired
  if(response.data.error==100){
  //Set Sessions
  sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
  sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
  sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
  sessionStorage.setItem("logType00", "user"); //Assign value to logtype
  //  sessionStorage.setItem("logName00", response.data.name); //Assign value to token
  window.location.replace("/shop");
  return;
  
  
  }
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("crt-hidden").style.display="none";
  document.getElementById("success-card1").style.display="block";
  
  
  }
  else{
  //Validation Errors
  if(response.data.validatorerror){
  //  setdisplay_product_id_er(response.data.validatorerror.Product_id);
  //setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
  //seterror_msg1("Cannot Add Product to Cart !");
  document.getElementById("crt-hidden").style.display="none";
  //document.getElementById("error-card1").style.display="block";
  
  
  }
  //Invalid Entries
  else if(response.data.error==102){ 
  
  // setdisplay_product_id_er(response.data.product_id_er);
  //setdisplay_rack_id_er(response.data.shop_id_er);
  //seterror_msg1("Cannot Add Product to Cart !");
  document.getElementById("crt-hidden").style.display="none";
  //document.getElementById("error-card1").style.display="block";
  
  }
  
  //Product Not Found
  else if(response.data.error==103){
  
  //seterror_msg1(response.data.error_msg);
  document.getElementById("crt-hidden").style.display="none";
  //document.getElementById("error-card1").style.display="block";
  
  }
  
  
  //failure
  else{
 // seterror_msg1(response.data.error_msg);
  document.getElementById("crt-hidden").style.display="none";
 // document.getElementById("error-card1").style.display="block";
  
  }
  }
  }    );
  
  
  
  
  }
  //User not Logedin
  else{
  
  //Remove Session Variables and redirect to login
  sessionStorage.removeItem("logStatus00");
  sessionStorage.removeItem("logId00");
  sessionStorage.removeItem("logToken00");
  sessionStorage.removeItem("logName00");
  
  window.location.replace("/");
  
  
  }    
  }
  /************************ADD TO CART FUNCTION END **********************/
  
return(
<>


<html lang="zxx">

<head>
    <meta charset="UTF-8" />
 {/*}   <meta name="description" content="Ashion Template" />
    <meta name="keywords" content="Ashion, unica, creative, html" /> */}
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="icon" type="image/x-icon" href="/whatjhumka_logo1.ico" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />

   {/* CUSTOM GOOGLE FONTS IMPORTED */}
   <link rel="preconnect" href="https://fonts.googleapis.com" />
   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
   <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />

    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

</head>

<body>
  <Navbar/>
    {/* Breadcrumb Begin */}
    <div class="breadcrumb-option">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb__links">
                        <a href="/"><i class="fa fa-home"></i> Home</a>
                        <span>Waaliyan</span>
                    </div>
                          {/* FILTER BUTTON */}
                   <div class="d-flex justify-content-end">
                   <img src="https://cdn-icons-png.flaticon.com/128/16794/16794659.png" style={{height:"1.6rem", width:"1.6rem"}}  id="fc-block" onClick={()=>{  const createForm = document.getElementById('filter-sidebar');
                   const currentDisplay = createForm.style.display;
                   createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }} />
                   
                   </div>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb End */}

  
  {/* Shop Section Begin */}
  <section class="shop spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3">
      {/* FILTERS SECTION START */}
      
{/*<div class="hello-div" id="hello-div1" ></div>*/}
    


                <div>
                <div class="shop__sidebar" id="filter-sidebar">
                        <div class="sidebar__categories">
                            <div class="section-title">
                                <h4>Filters</h4>
                            </div>
                         
                        </div>
                        <div class="sidebar__filter">
                            <div class="section-title">
                                <h4>Sort by price</h4>
                            </div>
                            <div class="filter-range-wrap">
                           
                                <div class="range-slider">                          
                                <label for="l" style={{textAlign:"left"}}> Starting Price</label>
                                <input type="text" class="form-group" placeholfer="129.00" id="filter_start_price" value={filter_start_price} onChange={updatefilter_start_price} style={{width:"100%", border:"0.1rem solid #002017"}} />
                                <code>{filter_start_price_er}</code>
                                <label for="l" style={{textAlign:"left"}}>Maximum Price</label>
                                <input type="text" class="form-group" placeholfer="499.00" id="filter_end_price" value={filter_end_price} onChange={updatefilter_end_price} style={{width:"100%", border:"0.1rem solid #002017"}} />         
                                <code>{filter_end_price_er}</code>
                            </div>
                            </div>
                            </div> 

                            <div class="sidebar__filter">
                            <div class="section-title">
                                <h4>Sort By Label</h4>
                            </div>
                            <div class="filter-range-wrap">
                                <select class="form-group"  id="filter_label" value={filter_label} onChange={updatefilter_label} style={{width:"100%", border:"0.1rem solid #002017"}}>
                                <option value="All">All</option>
                                <option value="Most Sold">Most Sold</option>
                                <option value="New Arrival">New Arrival</option>
                                <option value="Sale">Sale</option>
                                </select>
                                <code>{filter_label_er}</code>
                            </div>
                            </div>
                            <div class="sidebar__filter">
                            <div class="section-title">
                                <h4>Search By Product Name</h4>
                            </div>
                            <div class="filter-range-wrap">
                           
                                <div class="range-slider">                          
                                <label for="l" style={{textAlign:"left"}}>Product Name</label>
                                <input type="text" class="form-group" placeholfer="blue jhumkas" id="filter_product_name" value={filter_product_name} onChange={updatefilter_product_name} style={{width:"100%", border:"0.1rem solid #002017"}} />
                                <code>{filter_product_name_er}</code>
                            </div>
                            </div>
                            </div> 
                           
                          {/*}  <div class="sidebar__sizes">
                            <div class="section-title">
                                <h4>Categories</h4>
                            </div>
                            <div class="size__list">
                            {
                       data_filters.map((curElm5,ind)=>{

                         return(
                         <>
                                <label for="xxs">
                                    {curElm5.Filter_value}
                                    <input type="checkbox" id="xxs" value={curElm5.Filter_value} />
                                    <span class="checkmark"></span>
                                </label>

                                </>
                         )
                         }
					   
	                  		 )
                         }
                        </div>
                        </div> */}

                        <button class="btn btn-danger" style={{backgroundColor:"#009784", border:"none", marginBottom:"3rem"}} onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                        </div>

                    </div>
                    {/* FILTERS SECTION END */}
                    </div>
                <div class="col-lg-9 col-md-9">

                {/* ADD QUANTITIY BOX START */}
                <div class="row d-flex justify-content-center">
                <div class="col-md-6" id="crt-hidden" style={{display:"none", zIndex:"3", border:"0.2rem solid #00141a", position:"fixed", backgroundColor:"#ffffff", borderRadius:"7px", boxShadow:"2px 1px 2px 1px #bcbcbc"}}>
                <div style={{padding:"1rem"}}>
                <h5 style={{textAlign:"center", padding:"1rem"}}><b>Add Product to Cart</b></h5>
                <hr/>
                <div class="row">
                <div class="col-md-6">
                <label>Quantity</label>
                </div>
                <div class="col-md-6">
                <select class="form-control" id="crt-quantity" style={{width:"12rem", padding:"0.1rem", border:"0.1rem solid #00141a", borDderRadius:"10px"}} >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                </select>
                </div>
                </div> 
                <div class="row d-flex justify-content-center mt-3"><button class="btn btn-danger" style={{backgroundColor:"#00141a", border:"0.1rem solid #ffffff"}} onClick={addtocart}>Add to Cart</button></div>
                            
                </div>
                </div>
                </div>
                 {/* ADD QUANTITIY BOX END */}

                {/* WISHLIST SUCCESS CARD START */}
                <div class="alert alert-success" role="alert" id="success-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Product Successfully Added to Wishlist !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/waaliyan"); }}>Continue to shopping</button>
                </div>
               {/* WISHLIST SUCCESS CARD END */}

                {/* WISHLIST ERROR CARD START */}
                <div class="alert alert-danger" role="alert" id="error-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#cf6969"}}><b>{error_msg}</b></h5>
                <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/waaliyan"); }}>Continue to shopping</button>
                </div>
               {/* WISHLIST ERROR CARD END */}

               {/* CART SUCCESS CARD START */}
                <div class="alert alert-success" role="alert" id="success-card1" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Product Successfully Added to Cart !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/waaliyan"); }} hidden={bnb==1}>Continue to shopping</button>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/shopping-cart"); }} hidden={bnb==0}>Continue to shopping</button>

                </div>
               {/* CART SUCCESS CARD END */}

                {/* CART ERROR CARD START */}
                <div class="alert alert-danger" role="alert" id="error-card1" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#cf6969"}}><b>{error_msg1}</b></h5>
                <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/waaliyan"); }}>Continue to shopping</button>
                </div>
               {/* CART ERROR CARD END */}

                    <div class="row" id="product-page">
                    {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                        <div class="col-lg-4 col-md-6">
                            <div class="product__item" style={{backgroundColor:"#ececec"}}>
                                <div class="product__item__pic set-bg" data-setbg={imgpath+curElm.Product_image_link.slice(7)} style={{backgroundImage:`url(${imgpath+curElm.Product_image_link.slice(7)})`, backgroundSize: "contain"}}>
                               
                                    <div class="label new" style={{backgroundColor:"blue"}} hidden={(curElm.Product_label=="New Arrival")||(curElm.Product_label=="No Label")||(curElm.Product_label=="Out of Stock")||(curElm.Product_label=="Most Sold") } >{curElm.Product_label}</div>
                                    <div class="label new" hidden={(curElm.Product_label=="Sale")||(curElm.Product_label=="No Label")||(curElm.Product_label=="Out of Stock")||(curElm.Product_label=="Most Sold") } >{curElm.Product_label}</div>
                                    <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(curElm.Product_label=="Sale")||(curElm.Product_label=="No Label")||(curElm.Product_label=="New Arrival")||(curElm.Product_label=="Most Sold") } >{curElm.Product_label}</div>
                                    <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(curElm.Product_label=="Sale")||(curElm.Product_label=="No Label")||(curElm.Product_label=="New Arrival")||(curElm.Product_label=="Out of Stock") } >{curElm.Product_label}</div>



                                    <ul class="product__hover">
                                        <li><a href={imgpath+curElm.Product_image_link.slice(7)}  class="image-popup"><span class="arrow_expand"></span></a></li>
                                        <li onClick={()=>{

const wishlist_product_id = curElm.Product_id;
 //Checking if user loggedin
if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(wishlist_product_id>0)){


//Setting Error Variables
seterror_msg(" "); //setdisplay_rack_id_er(" ");

//Fetching Form Data

//No primary errors


//Sending Data through axios
axios.post("manage-wishlist-product", { 
Log_id:logid,
Token:token,
Product_id:wishlist_product_id,
Quantity:1,
// Action: wishlist_action
Action:"add"

}
)

.then((response) => {

      //Session Expired
      if(response.data.error==100){
       //Set Sessions
       sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
       sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
       sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
       sessionStorage.setItem("logType00", "user"); //Assign value to logtype
       //  sessionStorage.setItem("logName00", response.data.name); //Assign value to token
       window.location.replace("/waaliyan");
       return;


       }


//Success
if(response.data.successvar==1){

document.getElementById("success-card").style.display="block";


}
else{
//Validation Errors
if(response.data.validatorerror){
//  setdisplay_product_id_er(response.data.validatorerror.Product_id);
//setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
//seterror_msg("Cannot Add Product to Wishlist !");
//document.getElementById("error-card").style.display="block";


}
//Invalid Entries
else if(response.data.error==102){ 

// setdisplay_product_id_er(response.data.product_id_er);
//setdisplay_rack_id_er(response.data.shop_id_er);
//seterror_msg("Cannot Add Product to Wishlist !");
//document.getElementById("error-card").style.display="block";

}

//Product Not Found
else if(response.data.error==103){

//seterror_msg(response.data.error_msg);
//document.getElementById("error-card").style.display="block";

}


//failure
else{
//seterror_msg(response.data.error_msg);
//document.getElementById("error-card").style.display="block";

}
}
}    );




}
//User not Logedin
else{

//Remove Session Variables and redirect to login
sessionStorage.removeItem("logStatus00");
sessionStorage.removeItem("logId00");
sessionStorage.removeItem("logToken00");
sessionStorage.removeItem("logName00");

window.location.replace("/");


}    

/***************** ADD PRODUCT TO WISHLIST FUNCTION END  ******************/
}}><a><span class="icon_heart_alt"></span></a></li>
<li onClick={()=>{
                                    sessionStorage.setItem("productId00", curElm.Product_id);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}><a href="#"><span class="icon_bag_alt"></span></a></li>
                                    </ul>
                                </div>
                                <div class="product__item__text">
                                <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}><h6 onClick={()=>{
                                        //Setting Product Id Session
                                        sessionStorage.setItem("productId00",curElm.Product_id);
                                        window.location.replace("/product-details");
                                    }}><a><b>{curElm.Product_title}</b></a></h6></button>
                                    <div class="rating">
                                        <i class="fa fa-star" hidden={curElm.Product_rating<5}></i>
                                        <i class="fa fa-star" hidden={curElm.Product_rating<4}></i>
                                        <i class="fa fa-star" hidden={curElm.Product_rating<3}></i>
                                        <i class="fa fa-star" hidden={curElm.Product_rating<2}></i>
                                        <i class="fa fa-star" hidden={curElm.Product_rating<1}></i>
                                    </div>
                                    <div class="product__price" style={{color:"#da1731"}}>₹ {curElm.Product_price} <span>₹ {curElm.Product_mrp}</span></div>
                                </div>
                                <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", curElm.Product_id);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                            </div>
                        </div>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                       
                  
               
                     {/*}   <div class="col-lg-12 text-center">
                            <div class="pagination__option">
                                <a href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">3</a>
                                <a href="#"><i class="fa fa-angle-right"></i></a>
                            </div>
                        </div> */}
                    </div>
                      {/* PAGINATION SECTION START */}
                      <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/3236/3236910.png" style={{height:"3rem",width:"3rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/3236/3236907.png" style={{height:"3rem",width:"3rem"}}/></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                </div>
            </div>
        </div>
    </section>
    {/* Shop Section End */}

    {/* Instagram Begin 
    <div class="instagram">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-1.jpg" style={{backgroundImage:"url(img/instagram/insta-1.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-2.jpg" style={{backgroundImage:"url(img/instagram/insta-2.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-3.jpg" style={{backgroundImage:"url(img/instagram/insta-3.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-4.jpg" style={{backgroundImage:"url(img/instagram/insta-4.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-5.jpg" style={{backgroundImage:"url(img/instagram/insta-5.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-6.jpg" style={{backgroundImage:"url(img/instagram/insta-6.jpg)"}}>
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Instagram End */}

    <Footer/>

    {/* Js Plugins */}
    <script src="js/jquery-3.3.1.min.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script src="js/jquery.magnific-popup.min.js"></script>
    <script src="js/jquery-ui.min.js"></script>
    <script src="js/mixitup.min.js"></script>
    <script src="js/jquery.countdown.min.js"></script>
    <script src="js/jquery.slicknav.js"></script>
    <script src="js/owl.carousel.min.js"></script>
    <script src="js/jquery.nicescroll.min.js"></script>
    <script src="js/main.js"></script>
</body>

</html>

</>
);


}

export default Home_waaliyan;