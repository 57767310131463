import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");



function Admin_reviews(){

  
 const[data,setData] = useState([]);//SetData Variable

 //Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);


useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET DATA FUNCTION START  ********************//
function getData(){

  //Checking if user loggedin
 
 axios.post("admin-get-product-reviews", {
  Log_id:logid,
  Token:token,

   //Code Edits for pagination
   'page' : pageNumber

   })
 .then((response) => {


  //Success Data Fetched
  if(response.data.successvar==1){

    //Setting Data
    setData(response.data.data.data);

  }
  //Cannot Fetch Data
 else{
  alert("Cannot Fetch Data");
 }
 

 
 });

}
//******************* GET DATA FUNCTION END  ********************//


      //REVIEW FUNCTION VARIABLES
      //review_id
      const[review_id,setreview_id]=useState();
      //action
      const[action,setaction]=useState();
      //title
      const[title,settitle]=useState();const updatetitle = (event) => { settitle(event.target.value);}; const[title_er,settitle_er]=useState();  
      //rating
      const[rating,setrating]=useState();const updaterating = (event) => { setrating(event.target.value);}; const[rating_er,setrating_er]=useState();  
      //feedback
      const[feedback,setfeedback]=useState();const updatefeedback = (event) => { setfeedback(event.target.value);}; const[feedback_er,setfeedback_er]=useState();  
      //status
      const[status,setstatus]=useState();const updatestatus = (event) => { setstatus(event.target.value);}; const[status_er,setstatus_er]=useState();  
 
      //review error msg
      const[error_msg, seterror_msg]=useState();


     /***************** EDIT REVIEW FUNCTION START  ******************/
     function editreview(){

  
      //Checking if user loggedin
      if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(review_id>0)){
    
    
      //Setting Error Variables
      settitle_er(" ");setrating_er(" ");setfeedback_er(" ");setstatus_er(" ");seterror_msg(" ");
  
      
    //Fetching Form Data
    let noerror = 0;
    const $title = document.getElementById("title").value; 
    const $rating = document.getElementById("rating").value; 
    const $feedback = document.getElementById("feedback").value; 
    const $status = document.getElementById("status").value; 
    //const $review_error_msg = document.getElementById("review_error_msg").value; 
    
    //No primary errors
    if(noerror==0){
    
    
      //Sending Data through axios
      axios.post("edit-product-review", { 
      Log_id:logid,
      Token:token,
      Title:$title,
      Rating:$rating,
      Feedback:$feedback,
      Status:$status,
      Review_id:review_id,
    //  Action:action
      }
        )
      
      .then((response) => {
      
      //Validation Errors
      if(response.data.validatorerror){
      settitle_er(response.data.validatorerror.Title);
      setrating_er(response.data.validatorerror.Rating);
      setfeedback_er(response.data.validatorerror.Feedback);
      setstatus_er(response.data.validatorerror.Status);
     // setproduct_id_er(response.data.validatorerror.Product_id);
      seterror_msg("Cannot Update Review !");
      
      
      }
      //Invalid Entries
      if(response.data.error==102){ 
      settitle_er(response.data.title_er);
      setrating_er(response.data.rating_er);
      setfeedback_er(response.data.feedback_er);
      setstatus_er(response.data.status_er);
      //  setproduct_id_er(response.data.product_id_er);
  
      seterror_msg("Cannot Update Review !");
      
      
      }
      
      //Mentor Already Exists
      if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
      
      
      
      //Success
      if(response.data.successvar==1){
      
      document.getElementById("success-card").style.display="block";
    
      
      }
      //failure
      if(response.data.successvar==6){
      seterror_msg("Cannot Update Review !");
      }
      }    );
      
      }
      
    
      }
      //User not Logedin
     else{
     
     //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     
     window.location.replace("/");
     
     
     }    
    
    }
    
    /***************** EDIT REVIEW FUNCTION END  ******************/
    

    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">

              
             {/*  SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Review Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-reviews"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
              {/*}  <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                  <i class="mdi mdi-plus-circle"></i> Add New Review </button>  */}
              </div>
            </div>

        
              {/* EDIT REVIEW FORM START */}
              <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">EDIT REVIEW</h4>
                    <div class="form-sample">
                      <p class="card-description">edit review here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Review Id<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="review_id" value={review_id} />
                             
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Title<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="My Title" id="title" onChange={updatetitle} />
                              <code>{title_er}</code>
                            </div>
                          </div>
                        </div>
                          </div>
                      <div class="row">            
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rating<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" id="rating" onChange={updaterating} >
                              <option value="5">5</option>
                              <option value="4">4</option>
                              <option value="3">3</option>
                              <option value="2">2</option>
                              <option value="1">1</option>
                              </select>
                              <code>{rating_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" id="status" onChange={updatestatus} >
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{status_er}</code>
                            </div>
                          </div>
                        </div>
                          </div>
                      
                          <div class="row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Feedback<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="my feedback" class="form-control" id="feedback" onChange={updatefeedback} />
                              <code>{feedback_er}</code>
                            </div>
                          </div>
                        </div>
                          </div>
                      
                   
                          <button class="btn btn-lg btn-primary" onClick={editreview}>Edit Review</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* EDIT REVIEW FORM END */}
                </div>
         
        
            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Reviews</h4>
                    <p class="card-description">view product reviews submitted by customers
                    </p>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th><b>Review Id</b></th>
                            <th><b>User Id</b></th>
                            <th><b>Name</b></th>
                            <th><b>Product Id</b></th>
                            <th><b>Rating</b></th>
                            <th><b>Title</b></th>
                            <th><b>Feedback</b></th>
                            <th><b>Status</b></th>
                            <th><b>Date Updated</b></th>
                            <th><b>Time Updated</b></th>
                            <th><b>Edit</b></th>
                          {/*}  <th><b>Delete</b></th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr>
                            <td>{curElm.Review_id}</td>
                            <td>{curElm.User_id}</td>
                            <td>{curElm.First_name} {curElm.Last_name}</td>
                            <td>{curElm.Product_id}</td>
                            <td>
                            <i class="mdi mdi-star text-warning" hidden={curElm.Rating<5}></i>
                            <i class="mdi mdi-star text-warning" hidden={curElm.Rating<4}></i>
                            <i class="mdi mdi-star text-warning" hidden={curElm.Rating<3}></i>
                            <i class="mdi mdi-star text-warning" hidden={curElm.Rating<2}></i>
                            <i class="mdi mdi-star text-warning" hidden={curElm.Rating<1}></i>
                            <i class="mdi mdi-star" style={{color:"#d3d0d5"}} hidden={curElm.Rating>=5}></i>
                            <i class="mdi mdi-star" style={{color:"#d3d0d5"}} hidden={curElm.Rating>=4}></i>
                            <i class="mdi mdi-star" style={{color:"#d3d0d5"}} hidden={curElm.Rating>=3}></i>
                            <i class="mdi mdi-star" style={{color:"#d3d0d5"}} hidden={curElm.Rating>=2}></i>
                            <i class="mdi mdi-star" style={{color:"#d3d0d5"}} hidden={curElm.Rating>=1}></i>
                            </td>
                            <td>{curElm.Review_title}</td>
                            <td>{curElm.Review_message}</td>
                            <td>
                              <label class="badge badge-success" hidden={curElm.Status=="inactive"}>active</label>
                              <label class="badge badge-success" hidden={curElm.Status=="active"}>inactive</label>
                            </td>
                            <td>{curElm.Date_updated}</td>
                            <td>{curElm.Time_updated}</td>
                         
                            <td><button class="btn" onClick={()=>{
                              //EDIT FUNCTION 
                               setreview_id(curElm.Review_id);setaction("edit");
                               document.getElementById("title").value=curElm.Review_title;
                               document.getElementById("rating").value=curElm.Rating;                        
                               document.getElementById("feedback").value=curElm.Review_message;
                               document.getElementById("status").value=curElm.Status;
                               document.getElementById("create-form").style.display="block";

                              }}><i class="mdi mdi-pencil-box text-warning text-warning"></i></button></td>
                          {/*}  <td><button class="btn" onClick={()=>{
                              //DELETE FUNCTION 
                               setreview_id(curElm.Review_id);setaction("delete");
                               document.getElementById("title").value=curElm.Title;
                               document.getElementById("rating").value=curElm.Rating;                        
                               document.getElementById("feedback").value=curElm.Feedback;

                              }}><i className="mdi mdi-delete-forever text-danger"></i></button></td> */}
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }

                        
                        </tbody>
                      </table>

                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690080.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690084.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                    </div>
                  </div>
                </div>
              </div>

          </div>

          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_reviews;



