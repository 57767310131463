import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from './navbar';
import Footer from './footer';
import axios from '../axios.js';


function Login(){


    
//VARIABLES
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
//password
const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);}; const[password_er,setpassword_er]=useState();
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();


 
  /***************** LOGIN FUNCTION START  ******************/
  function userlogin(){



    //Setting Error Variables
    //setusername_er(" ");
    setemail_er(" ");
    setpassword_er(" ");
    seterror_msg(" ");
  
     
    //Fetching Form Data
      let noerror = 0;let enoerror = 0; //let $username =""; let $email="";
     // $username = document.getElementById("username").value; if($username==""){ seterror_msg("Username or Email is required !");noerror = noerror+1; }//Fetching Username Value
    //  if($username==""){
      const  $email = document.getElementById("email").value; if($email==""){ seterror_msg("Email is required !");noerror = noerror+1; }//}//Fetching Email Value
      const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1;enoerror = enoerror+1; }//Fetching Password Value
     
    //No primary errors
  if((noerror==0)){
      //Sending Data through axios
      axios.post("user-new-login", { 
       Email:$email,
       //Username:$username,
       Password:$password
     
  })
  
     .then((response) => {
  
  
       //Validation Errors
     if(response.data.validatorerror){
    //   setusername_er(response.data.validatorerror.Username);
       setemail_er(response.data.validatorerror.Email);
       setpassword_er(response.data.validatorerror.Password);
     
     }
     //Invalid Entries
     if(response.data.error==102){ seterror_msg("Invalid Email or Password!");  }
     if(response.data.error==106){ seterror_msg("Cannot Login, Try Again!"); }
  
     //Success
     if(response.data.successvar==1){
  
      //Setting Sessions 
      sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
      sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
      sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
      sessionStorage.setItem("logType00", "user"); //Assign value to logtype
      sessionStorage.setItem("logName00", response.data.name); //Assign value to token
      document.getElementById("success-card").style.display="block";
  
     //alert("User Logged In Successfully!");
     //  window.location.replace("/candidatedetails");
     //window.location.replace("/businesstools-startworking");
    // document.getElementById("success-card").style.display="block";
  
  
     
     }
     //failure
     else{
     seterror_msg("Invalid Email or Password!");
  
     
     }
   }    );
  
  }
  //Return Errors
  else{
  
    
  
   return;
  }
  
  }
    /***************** ADMIN LOGIN FUNCTION END  ******************/
  
  

return(
<>

<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="description" content="Ashion Template" /> 
    <meta name="keywords" content="Ashion, unica, creative, html" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />

   {/* CUSTOM GOOGLE FONTS IMPORTED */}
   <link rel="preconnect" href="https://fonts.googleapis.com" />
   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
   <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />

    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 
    
</head>

<body>
   <Navbar/>

    {/* Breadcrumb Begin */}
    <div class="breadcrumb-option">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb__links">
                        <a href="/"><i class="fa fa-home"></i> Home</a>
                        <span>Login</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb End */}

    {/* Checkout Section Begin */}
    <section class="checkout spad">
        <div class="container">

        {/* SUCCESS CARD START */}
        <div class="alert alert-success" role="alert" id="success-card" style={{textAlign:"center", display:"none"}}>
        <h5 style={{color:"#046b42"}}><b>Log In Successful !</b></h5>
        <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/shop"); }}>Continue to shop</button>
        </div>
        {/* SUCCESS CARD END */}

          {/*}  <div class="row">
                <div class="col-lg-12">
                    <h6 class="coupon__link">Login Now and Start Shopping</h6>
                </div>
            </div>*/}
            <form onSubmit={e => e.preventDefault()} class="checkout__form">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-6" style={{boxShadow:"1px 1px 1px 2px #a5a5a5", borderRadius:"12px"}}>
                        <h5 style={{padding:"1rem", textAllign:"center"}}>Login Now and Start Shopping</h5>
                        <code>{error_msg}</code>
                        <div class="row">
                          <div class="col-lg-12">
                                    <div class="checkout__form__input mt-3">
                                        <p>Email <span>*</span></p>
                                        <input type="text" placeholder="myemail@gmail.com"  id="email" onChange={updateemail}  />
                                        <code>{email_er}</code>
                                    </div>
                                    <div class="checkout__form__input mt-3">
                                        <p>Password <span>*</span></p>
                                        <input type="password" placeholder="************" id="password" onChange={updatepassword}  />
                                        <code>{password_er}</code>
                                    </div>
                               
                                    <button type="submit" style={{backgroundColor:"#004761"}} class="site-btn mt-3" onClick={userlogin}>Login</button>
                                    <br/><br/>
                                    <p>New Customer<a href="/register"><span style={{color:"#0a3568", fontWeight:'bold'}}> create account</span></a></p>
                                </div>
                            </div>
                        </div>
                       {/*}     <div class="col-lg-4">
                        <div class="checkout__order">
                             <img src="https://images.pexels.com/photos/7919082/pexels-photo-7919082.jpeg?auto=compress&cs=tinysrgb&w=600" height="100%" width="100%" />
                            </div>
                        </div> */}
                    </div>
                </form>
            </div>
        </section>
        {/* Checkout Section End */}

        {/* Instagram Begin 
        <div class="instagram">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-1.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-2.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-3.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-4.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-5.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-6.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Instagram End */}

       <Footer/>

        {/* Js Plugins */}
        <script src="js/jquery-3.3.1.min.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/jquery.magnific-popup.min.js"></script>
        <script src="js/jquery-ui.min.js"></script>
        <script src="js/mixitup.min.js"></script>
        <script src="js/jquery.countdown.min.js"></script>
        <script src="js/jquery.slicknav.js"></script>
        <script src="js/owl.carousel.min.js"></script>
        <script src="js/jquery.nicescroll.min.js"></script>
        <script src="js/main.js"></script>
    </body>

    </html>
</>
);


}

export default Login;