import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';



function Footer(){


    
  const logostyle1 = {
    fontFamily : "Sansita, sans-serif",
    fontWeight : "400",
    fontStyle: "normal",
    };
  
    const logostyle2 = {
      fontFamily: "Satisfy, cursive",
      fontWeight: "700",
      fontStyle: "bold",
      fontSize:"1.6rem"
    };
  
    const logostyle3 = {
      fontFamily: "Dancing Script, cursive",
      fontWeight: "700",
      fontStyle: "bold",
      color:"#cc018b",
      fontSize:"1.6rem"
    };
    const logostyle4 = {
      fontFamily: "Dancing Script, cursive",
      fontWeight: "700",
      fontStyle: "bold",
      color:"#000000",
      fontSize:"1.6rem"
    };
  


return(
<>
   
{/* Footer Section Begin */}
<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-7">
                <div class="footer__about">
                    <div class="footer__logo">
                      {/*}  <a href="./index.html">{/*<img src="img/logo.png" alt="" />

                        <span style={logostyle4}><b>What<span style={logostyle3}>Jhumka</span><span style={logostyle3}> .in</span></b></span>
                        </a>*/}
                    <a href="/"><img src="whatjhumka_logo_f.jpg" alt="logo" style={{padding:"0", marginLeft:"0"}} /></a> 
                    </div>
                   
                    <p>Buy Modern Jhumkas and Earings at Best Prices</p>

                    <p><img src="https://cdn-icons-png.flaticon.com/128/3670/3670051.png" style={{height:"1.6rem", width:"1.6rem"}} /> <b>+91 923894 0060</b></p>
                    <p><img src="https://cdn-icons-png.flaticon.com/128/3178/3178158.png" style={{height:"1.6rem", width:"1.6rem"}} /> <b>Whatjhumka2024@gmail.com</b></p>
   

              {/*}      <div class="footer__payment">
                        <a href="#"><img src="img/payment/payment-1.png" alt="" /> </a>
                        <a href="#"><img src="img/payment/payment-2.png" alt="" /> </a>
                        <a href="#"><img src="img/payment/payment-3.png" alt="" /> </a>
                        <a href="#"><img src="img/payment/payment-4.png" alt="" /> </a>
                        <a href="#"><img src="img/payment/payment-5.png" alt="" /> </a>
                    </div>  */}
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-5">
                <div class="footer__widget">
                    <h6>Quick links</h6>
                    <ul>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                        <li><a href="/termsandconditions">Terms and Conditions</a></li>
                        <li><a href="/exchange-policy">Exchange Policy</a></li>
                        <li><a href="/shipping-policy">Shipping Policy</a></li>
                    {/*}    <li><a href="#">Blogs</a></li> */}
                        <li><a href="/contact">Contact</a></li>
                  {/*}      <li><a href="#">FAQ</a></li> */}
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="footer__widget">
                    <h6>Account</h6>
                    <ul>
                   {/*}     <li><a href="#">My Account</a></li> */}
                   {/*   <li><a href="#">Orders Tracking</a></li> */}
                        <li><a href="/login">Login</a></li>
                        <li><a href="/shopping-cart">Cart</a></li>
                        <li><a href="/wishlist">Wishlist</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-8">
                <div class="footer__newslatter">
                    <h6>REACH OUT TO US AT</h6>
                {/*}    <form action="#">
                        <input type="text" placeholder="Email" />
                        <button type="submit" class="site-btn">Subscribe</button>
                    </form> */}
                    <div class="footer__social">
                        <a href="#"> <i class="fa fa-facebook"></i>{/*<img src="https://cdn-icons-png.flaticon.com/128/15047/15047435.png" />*/}</a>
                        <a href="#"> <i class="fa fa-twitter"></i>{/*<img src="https://cdn-icons-png.flaticon.com/128/3670/3670151.png" /> */}</a>
                        <a href="#"> <i class="fa fa-youtube-play"></i> {/*<img src="https://cdn-icons-png.flaticon.com/128/3670/3670147.png" /> */}</a>
                        <a href="https://www.instagram.com/whatjhumka.in?igsh=NWtwbTlqcm1nOGl3"> <i class="fa fa-instagram"></i>{/*<img src="https://cdn-icons-png.flaticon.com/128/3955/3955024.png" />*/}</a>
                    {/*}    <a href="#">{/* <i class="fa fa-pinterest"></i><img src="https://cdn-icons-png.flaticon.com/128/5968/5968795.png" /></a> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="f-footer row d-flex justify-content-end">
        <a href="/#product-page"><button style={{backgroundColor:"transparent", border:"none"}}><img src="https://cdn-icons-png.flaticon.com/128/10963/10963558.png" style={{height:"3rem", width:"3rem"}}></img></button></a>
        </div>
        <div class="row">
            <div class="col-lg-12">
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                <div class="footer__copyright__text">
              {/*}     <p>Copyright &copy; <script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i class="fa fa-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a></p>
               */}
               
               <p>Copyright &copy; 2024 All rights reserved {/*| This template is made with <i class="fa fa-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>*/}</p>
          
            </div>
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            </div>
        </div>

       
     
    </div>
</footer>
{/* Footer Section End */}

</>
);


}

export default Footer;