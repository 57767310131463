import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';



function Adminsidebar(){

  
  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");


return(
<>
      {/* partial:partials/_sidebar.html */}
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile border-bottom">
            <a href="#" class="nav-link flex-column">
              <div class="nav-profile-image">
                <img src="panel/assets/images/faces/face1.jpg" alt="profile" />
                {/*change to offline or busy as needed*/}
              </div>
              <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">{logname}</span>
                <span class="text-secondary icon-sm text-center">{logid}</span>
              </div>
            </a>
          </li>
          <li class="nav-item pt-3">
        {/*}    <a class="nav-link d-block" href="index.html">
              <img class="sidebar-brand-logo" src="../assets/images/logo.svg" alt="" />
              <img class="sidebar-brand-logomini" src="../assets/images/logo-mini.svg" alt="" />
              <div class="small font-weight-light pt-1">Responsive Dashboard</div>
            </a> */}
           {/*} <form class="d-flex align-items-center" action="#">
              <div class="input-group">
                <div class="input-group-prepend">
                  <i class="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" class="form-control border-0" placeholder="Search" />
              </div>
            </form> */}
          </li>
          <li class="pt-2 pb-1">
            <span class="nav-item-head">Main</span>
          </li> 
          <li class="nav-item">
            <a class="nav-link" href="/adminpanel">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-registrations">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Registrations</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-orders">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Orders</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-logins">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Logins</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-payments">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Payments</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-products">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Products</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-inventory">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Inventory</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-reviews">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Reviews</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-contact">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Contact</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-offers">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Offers/ Coupon Codes</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-cart">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Cart</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-wishlist">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Wishlist</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-filters">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Filters</span>
            </a>
          </li>
          <hr/>
          <li class="pt-2 pb-1">
            <span class="nav-item-head">Pages</span>
          </li> 
          <li class="nav-item">
         {/*}   <a class="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
              <i class="mdi mdi-crosshairs-gps menu-icon"></i>
              <span class="menu-title">Home Page</span>
              <i class="menu-arrow"></i>
            </a> */}
            <div class="collapse" id="ui-basic">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/buttons.html">Header Section</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/dropdowns.html">Products Section</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/typography.html">Banner Section</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/typography.html">Trends Section</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/typography.html">Offers Section</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="pages/ui-features/typography.html">Instagram Section</a>
                </li>
            
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-home">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Home</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-shop">
              <i class="mdi mdi-compass-outline menu-icon"></i>
              <span class="menu-title">Shop</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-giftboxes">
              <i class="mdi mdi-contacts menu-icon"></i>
              <span class="menu-title">Giftboxes</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-waaliyan">
              <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              <span class="menu-title">Waaliyan</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-rings">
              <i class="mdi mdi-chart-bar menu-icon"></i>
              <span class="menu-title">Rings</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-bracelets">
              <i class="mdi mdi-table-large menu-icon"></i>
              <span class="menu-title">Bracelets</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/admin-necklace">
              <i class="mdi mdi-table-large menu-icon"></i>
              <span class="menu-title">Necklace</span>
            </a>
          </li>
        
        </ul>
      </nav>
      {/* partial */}
</>
);


}

export default Adminsidebar;