import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from './navbar';
import Footer from './footer';
import axios from '../axios.js';
  
  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");
  const orderId=sessionStorage.getItem("OrderId00");


function Checkout(){



    
    const[data,setData] = useState([]);//SetData Variable


  
   //Wishlist Product Id
   //const [wishlist_product_id, setwishlist_product_id] = useState(1);
   const [error_msg, seterror_msg] = useState();

   //Form Variables
   const [first_name, setfirst_name] = useState();const updatefirst_name = (event) => { setfirst_name(event.target.value);}; const[first_name_er,setfirst_name_er]=useState();
   const [last_name, setlast_name] = useState();const updatelast_name = (event) => { setlast_name(event.target.value);}; const[last_name_er,setlast_name_er]=useState();
   const [email, setemail] = useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
   const [contact, setcontact] = useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
   const [address, setaddress] = useState();const updateaddress = (event) => { setaddress(event.target.value);}; const[address_er,setaddress_er]=useState();
   const [city, setcity] = useState();const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
   const [state, setstate] = useState();const updatestate = (event) => { setstate(event.target.value);}; const[state_er,setstate_er]=useState();
   const [country, setcountry] = useState();const updatecountry = (event) => { setcountry(event.target.value);}; const[country_er,setcountry_er]=useState();
   const [pincode, setpincode] = useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
   const [order_notes, setorder_notes] = useState();const updateorder_notes = (event) => { setorder_notes(event.target.value);}; const[order_notes_er,setorder_notes_er]=useState();
   const [items, setitems] = useState();//const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
   const [quantity, setquantity] = useState();
   const [amount, setamount] = useState();
   const [discount_per, setdiscount_per] = useState(); //const updatediscount_per = (event) => { setdiscount_per(event.target.value);}; const[discount_per_er,setdiscount_per_er]=useState();
   const [discount_amt, setdiscount_amt] = useState(); //const updatediscount_amt = (event) => { setdiscount_amt(event.target.value);}; const[discount_amt_er,setdiscount_amt_er]=useState();
   const [sgst_per, setsgst_per] = useState(); //const updatesgst_per = (event) => { setsgst_per(event.target.value);}; const[sgst_per_er,setsgst_per_er]=useState();
   const [cgst_per, setcgst_per] = useState(); //const updatecgst_per = (event) => { setcgst_per(event.target.value);}; const[cgst_per_er,setcgst_per_er]=useState();
   const [igst_per, setigst_per] = useState(); //const updateigst_per = (event) => { setigst_per(event.target.value);}; const[igst_per_er,setigst_per_er]=useState();
   const [sgst_amt, setsgst_amt] = useState(); //const updatesgst_amt = (event) => { setsgst_amt(event.target.value);}; const[sgst_amt_er,setsgst_amt_er]=useState();
   const [cgst_amt, setcgst_amt] = useState(); //const updatecgst_amt = (event) => { setcgst_amt(event.target.value);}; const[cgst_amt_er,setcgst_amt_er]=useState();
   const [igst_amt, setigst_amt] = useState(); //const updateigst_amt = (event) => { setigst_amt(event.target.value);}; const[igst_amt_er,setigst_amt_er]=useState();
   const [total, settotal] = useState(); 
   const [shipping_charges, setshipping_charges] = useState(); 
   //const [shipping, setshipping] = useState();

   const [coupon_applied, setcoupon_applied] = useState(0);//const updatecoupon_applied = (event) => { setcoupon_applied(event.target.value);}; const[coupon_applied_er,setcoupon_applied_er]=useState();
   const [coupon_code, setcoupon_code] = useState();const updatecoupon_code = (event) => { setcoupon_code(event.target.value);}; const[coupon_code_er,setcoupon_code_er]=useState();

   useEffect(()=>{
      
       getData();
       
       },[])
       
     //******************* GET DATA FUNCTION START  ********************//
     function getData(){
     
       //Checking if user loggedin
      
      axios.post("checkout-page-default-load", {
       Log_id:logid,
       Token:token,
       Order_id:orderId

     
        })
      .then((response) => {

        //console.log(response);
  
          //Success Data Fetched
       if(response.data.successvar==1){
     

         //Setting Data
         setfirst_name(response.data.first_name);
         setlast_name(response.data.last_name);
         setemail(response.data.email);
         setcontact(response.data.contact);
         setaddress(response.data.address);
         setcity(response.data.city);
         setstate(response.data.state);
         setcountry(response.data.country);
         setpincode(response.data.pincode);
         setitems(response.data.items);
         setquantity(response.data.quantity);
         setamount(response.data.amount);
      /*   setsgst_per(response.data.sgst_per);
         setsgst_amt(response.data.sgst_amt);
         setcgst_per(response.data.cgst_per);
         setcgst_amt(response.data.cgst_amt);
         setigst_per(response.data.igst_per);
         setigst_amt(response.data.igst_amt); */
         setdiscount_per(response.data.discount_per);
         setdiscount_amt(response.data.discount_amt);
         settotal(response.data.total);
         setData(response.data.order_items);
         if((response.data.total)>450){
            setshipping_charges(0);
         }
         else{setshipping_charges(50);}
     
         
       }
       //Cannot Fetch Data
      else{
       //alert("Cannot Fetch Data");
       window.location.replace("/login");
      }
      
     
      
      });
      
      
     }

     
/***************** UPDATE BILLING DATA FUNCTION START  ******************/
function update_billing_info(){


    //Setting Error Variables
    setfirst_name_er(" "); setlast_name_er(" ");setemail_er(" ");setcontact_er(" ");setaddress_er(" ");setcity_er(" ");setstate_er("");setpincode_er("");setorder_notes_er("");
    seterror_msg(" ");
    
    
    //Fetching Form Data
      let noerror = 0;
      const $first_name = document.getElementById("first_name").value; if($first_name==""){ setfirst_name_er("First Name is required !");noerror = noerror+1; }//Fetching First Name Value
      const $last_name = document.getElementById("last_name").value; if($last_name==""){ setlast_name_er("Last Name is required !");noerror = noerror+1; }//Fetching last Name Value
      const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
      const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
      const $address = document.getElementById("address").value; if($address==""){ setaddress_er("Address is required !");noerror = noerror+1; }//Fetching address Value
      const $city = document.getElementById("city").value; if($city==""){ setcity_er("City is required !");noerror = noerror+1; }//Fetching city Value
      const $state = document.getElementById("state").value; if($state==""){ setstate_er("State is required !");noerror = noerror+1; }//Fetching state Value
      const $pincode = document.getElementById("pincode").value; if($pincode==""){ setpincode_er("Pincode is required !");noerror = noerror+1; }//Fetching pincode Value
      const $order_notes = document.getElementById("order_notes").value;// if($order_notes==""){ setorder_notes_er("Order notes is required !");noerror = noerror+1; }//Fetching order_notes Value

    
    
    //No primary errors
    if(noerror==0){
      //Sending Data through axios
      axios.post("update-billing-details", { 
       Log_id:logid,
       Token:token,
       First_name:$first_name,
       Last_name:$last_name,
       Email:$email,
       Contact:$contact,
       Address:$address,
       City:$city,
       State:$state,
       Country:"India",
       Pincode:$pincode,
       Order_notes:$order_notes
     
    })
    
     .then((response) => {

       //Validation Errors
     if(response.data.validatorerror){
       setfirst_name_er(response.data.validatorerror.First_name);
       setlast_name_er(response.data.validatorerror.Last_name);
       setcontact_er(response.data.validatorerror.Contact);
       setemail_er(response.data.validatorerror.Email);
       setaddress_er(response.data.validatorerror.Address);
       setcity_er(response.data.validatorerror.City);
       setstate_er(response.data.validatorerror.State);
       setcountry_er(response.data.validatorerror.Country);
       setpincode_er(response.data.validatorerror.Pincode);
       setorder_notes_er(response.data.validatorerror.Order_notes);

          
     }
     //Invalid Entries
     if(response.data.error==102){ 
        
        setfirst_name_er(response.data.first_name_er); 
        setlast_name_er(response.data.last_name_er); 
        setemail_er(response.data.email_er); 
        setcontact_er(response.data.contact_er); 
        setcity_er(response.data.city_er); 
        setstate_er(response.data.state_er);    
        setcountry_er(response.data.country_er);  
        setpincode_er(response.data.pincode_er);  
        setorder_notes_er(response.data.order_notes_er);  
    
    }
    
   
     //Success
     if(response.data.successvar==1){
      
     document.getElementById("final-bill").style.display="block";
     getData();

     
     }
     //failure
     if(response.data.successvar==2){
     seterror_msg("Bill Details Not Updated !");
     }
    }    );
    
    }
    //Return Errors
    else{
      return;
      }
    
    
    
    
    
    }
    /***************** UPDATE BILLING DATA FUNCTION END  ******************/


    
     
/***************** APPLY COUPON CODE FUNCTION START  ******************/
function apply_coupon_code(){


    //Setting Error Variables
    setcoupon_code_er(" "); seterror_msg(" ");
    
    
    //Fetching Form Data
      let noerror = 0;
      const $coupon_code = document.getElementById("coupon_code").value; if($coupon_code==""){ setcoupon_code_er("Coupon Code is Required !");noerror = noerror+1; }//Fetching Coupon Code Value
     
    
    
    //No primary errors
    if(noerror==0){
      //Sending Data through axios
      axios.post("apply-coupon-code", { 
       Log_id:logid,
       Token:token,
       Order_id:orderId,
       Coupon_code:$coupon_code
     
    })
    
     .then((response) => {


       //Validation Errors
     if(response.data.validatorerror){
       setcoupon_code_er(response.data.validatorerror.Coupon_code);
          
     }
     //Invalid Entries
     if(response.data.error==102){ 
        
        setcoupon_code_er(response.data.coupon_code_er); 
     
    }
    if(response.data.error==104){ 
        
        setcoupon_code_er("Coupon Code Already Applied !"); 
     
    }
    if(response.data.error==105){ 
        
        setcoupon_code_er("Invalid Coupon Code !"); 
     
    }
    
   
     //Success
     if(response.data.successvar==1){
      
   //  document.getElementById("final-bill").style.display="block";
     setcoupon_applied(1);
     getData();

     
     }
     //failure
     if(response.data.successvar==2){
     seterror_msg("Cannot Apply Coupon Code  !");
     }
    }    );
    
    }
    //Return Errors
    else{
      return;
      }
    
    
    
    
    
    }
    /***************** APPLY COUPON CODE FUNCTION END  ******************/


    
     
/***************** INITIATE PAYMENT FUNCTION START  ******************/
/*function initiate_payment(){

        
      //Sending Data through axios
      axios.post("initiate-payment", { 
       Log_id:logid,
       Token:token,
       Order_id:orderId,
         
    })
    
     .then((response) => {

        console.log(response);
   
     //Success
     if(response.data.successvar==1){
      
   //  document.getElementById("final-bill").style.display="block";
  //   setcoupon_applied(1);
   //  getData();

     //return redirect()->route('dashboard'); // Assuming a named route
     //window.location.replace('http://127.0.0.1:8000/payment-form')
     //window.location.href = response.headers.location;


 /*   sessionStorage.setItem("order_idQWA", orderId);
    sessionStorage.setItem("log_idQWA", logid);
    sessionStorage.setItem("tokenQWA", token);
    sessionStorage.setItem("totalQWA", total); */

    // window.location.replace("http://127.0.0.1:8000/payment-form");
     //window.location.href = 'http://127.0.0.1:8000/payment-form';


    

     
  /*   }
    //failure
     if(response.data.successvar==2){
     alert("Cannot Complete Transaction  !");
     }
    }    );
    

    
    }
    /***************** INITIATE PAYMENT FUNCTION END  ******************/

      

return(
<>

<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="description" content="Ashion Template" /> 
    <meta name="keywords" content="Ashion, unica, creative, html" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />

   {/* CUSTOM GOOGLE FONTS IMPORTED */}
   <link rel="preconnect" href="https://fonts.googleapis.com" />
   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
   <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />

    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 
    
</head>

<body>
   <Navbar/>

    {/* Breadcrumb Begin */}
    <div class="breadcrumb-option">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb__links">
                        <a href="/"><i class="fa fa-home"></i> Home</a>
                        <span>Checkout</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb End */}

    {/* Checkout Section Begin */}
    <section class="checkout spad">
        <div class="container">
            <div class="row">
              {/*}  <div class="col-lg-12">
                    <h6 class="coupon__link"><span class="icon_tag_alt"></span> <a href="#">Have a coupon?</a> Click
                    here to enter your code.</h6>
                </div> */}
            </div>
            <div action="#" class="checkout__form">
                <div class="row">
                    <div class="col-lg-6">
                        <h5>Billing details</h5>
                        <code>{error_msg}</code>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="checkout__form__input">
                                    <p>First Name <span>*</span></p>
                                    <input type="text" id="first_name" value={first_name}  onChange={updatefirst_name} style={{marginBottom:"0"}} />
                                    <code>{first_name_er}</code>
                                 {/*}   <code class="text-muted" style={{marginBottom:"1rem"}}>this field cannot be changed</code>*/}
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="checkout__form__input">
                                    <p>Last Name <span>*</span></p>
                                    <input type="text"  id="last_name" value={last_name}  onChange={updatelast_name}  style={{marginBottom:"0"}} />
                                    <code>{last_name_er}</code>
                                   {/*} <code class="text-muted" style={{marginBottom:"1rem"}}>this field cannot be changed</code> */}
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="checkout__form__input">
                                    <p>Phone <span>*</span></p>
                                    <input type="text"  id="contact" value={contact} onChange={updatecontact} />
                                    <code>{contact_er}</code>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="checkout__form__input">
                                    <p>Email <span>*</span></p>
                                    <input type="text"  id="email" value={email} onChange={updateemail} style={{marginBottom:"0"}} />
                                    <code>{email_er}</code>
                                   {/*} <code class="text-muted" style={{marginBottom:"1rem"}}>this field cannot be changed</code> */}
                                </div>
                            </div>
                            <div class="col-lg-12">
                             {/*}   <div class="checkout__form__input">
                                    <p>Country <span>*</span></p>
                                    <input type="text"  id="country" value={country} onChange={updatecountry}  />
                                </div> */}
                                <div class="checkout__form__input">
                                    <p>Address <span>*</span></p>
                                    <input type="text" placeholder="Apartment. suite, unite ect ( optinal )"  id="address" value={address} onChange={updateaddress}  />
                                    <code>{address_er}</code>
                                </div>
                                <div class="checkout__form__input">
                                    <p>Town/City <span>*</span></p>
                                    <input type="text"  id="city" value={city} onChange={updatecity}  />
                                    <code>{city_er}</code>
                                </div>
                                <div class="checkout__form__input">
                                    <p>State <span>*</span></p>
                                    <select style={{width:"100%", padding:"1rem", marginBottom:"1rem", border:"0.1rem solid #c0c0c0"}}  id="state" value={state} onChange={updatestate} >
                                    <option value="">Select State</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chhatisgarh">Chhattisgarh</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujrat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                    <option value="Jharkhand">Jharkhand</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Manipur">Manipur</option>
                                    <option value="Meghalaya">Meghalaya</option>
                                    <option value="Mizoram">Mizoram</option>
                                    <option value="Nagaland">Nagaland</option>
                                    <option value="Odisha">Odisha</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Sikkim">Sikkim</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Telangana">Telangana</option>
                                    <option value="Tripura">Tripura</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="West Bengal">West Bengal</option>
                                    </select>
                                    <code>{state_er}</code>
                                </div>
                                <div class="checkout__form__input">
                                   {/*} <p>Country <span>*</span></p> */}
                                    <input type="hidden"  id="country" /*value={country}*/ value="India" /* onChange={updatecountry} */ />
                                </div>
                                <div class="checkout__form__input">
                                    <p>Postcode/Zip <span>*</span></p>
                                    <input type="text" id="pincode" value={pincode} onChange={updatepincode} />
                                    <code>{pincode_er}</code>
                                </div>
                            </div>
                         
                            <div class="col-lg-12">
                             
                                
                                    <div class="checkout__form__input">
                                        <p>Order notes <span>*</span></p>
                                        <input type="text"
                                        placeholder="Note about your order, e.g, special noe for delivery" value={order_notes} onChange={order_notes} id="order_notes" />
                                        <code>{order_notes_er}</code>
                                    </div>

                                    <button class="site-btn" style={{backgroundColor:"#1f7b58"}} onClick={update_billing_info}>Update Billing Details</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" id="final-bill" style={{display:"none"}}>
                            <div class="checkout__order">
                                <h5>Your order</h5>
                                <div class="checkout__order__product">
                                 
                        {/*                {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                                        <li>{curElm.Sn}. {curElm.Product_name}<span>{curElm.Quantity}</span> <span>₹ {curElm.Amount}</span></li>
                                        </>
                         )
                         }
					   
	                  		 )
                         }*/}
                                     
                                   

                                   <table class="table table-bordered">
                                   <tr>
                                    <th>Sn</th><th>Product Name</th><th>Qty</th><th>Rate</th><th>Amount</th>
                                   </tr>
                                    {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                                       <tr>
                                        <td>{curElm.Sn}.</td>
                                        <td>{curElm.Product_name}</td>
                                        <td>{curElm.Quantity}</td>
                                        <td>{curElm.Rate}</td>
                                        <td>{curElm.Amount}</td>
                                       </tr>
                                        </>
                         )
                         }
					   
	                  		 )
                         }
                         </table>
                                </div>
                                <div class="checkout__order__total">
                                    <ul>
                                        <li>Subtotal <span>₹ {amount}</span></li>
                                        <li>Discount <span>₹ {discount_amt}</span></li>
                                     {/*}   <li hidden={state!="Madhya Pradesh"}>SGST @ 1.50 %<span>{sgst_amt}</span></li>
                                        <li hidden={state!="Madhya Pradesh"}>CGST @ 1.50 %<span>{cgst_amt}</span></li>
                                        <li hidden={state=="Madhya Pradesh"}>IGST @ 3.00 %<span>{igst_amt}</span></li>
                                        <li hidden={state=="Madhya Pradesh"}>SGST<span>{sgst_amt}</span></li>
                                        <li hidden={state=="Madhya Pradesh"}>CGST<span>{cgst_amt}</span></li>
                                        <li hidden={state!="Madhya Pradesh"}>IGST<span>{igst_amt}</span></li> */}
                                        <li hidden={total>450}>Shipping Charges <span>₹ 50</span></li>

                                        <li>Total <span>₹ {total+shipping_charges}</span></li>
                                    </ul>
                                </div>
                             {/*}   <div class="checkout__order__widget">
                                    <label for="o-acc">
                                        Create an acount?
                                        <input type="checkbox" id="o-acc" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <p>Create am acount by entering the information below. If you are a returing customer
                                    login at the top of the page.</p>
                                    <label for="check-payment">
                                        Cheque payment
                                        <input type="checkbox" id="check-payment" />
                                        <span class="checkmark"></span>
                                    </label>
                                    <label for="paypal">
                                        PayPal
                                        <input type="checkbox" id="paypal" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>*/}
                                <div>

                                <div class="form-group"> <label>Have coupon?</label>
                                <div class="input-group"> <input type="text" class="form-control coupon" value={coupon_code} onChange={coupon_code} id="coupon_code" placeholder="Coupon code" /> <span class="input-group-append"> 
                                <button class="btn btn-primary btn-apply coupon" onClick={apply_coupon_code}>Apply</button> </span> </div>
                                <code>{coupon_code_er}</code>
                                <p hidden={coupon_applied==0} class="text-success">Coupon Applied Successfully</p>
                            </div>

                               
                                </div>
                              {/*}  <form action="https://api.whatjhumka.in/payment-form" method="post"> */}
                              <form action="https://whatjhumka.in/api/payment-form" method="post">
                           {/*}   <form action="http://127.0.0.1:8000/payment-form" method="post">  */}
                                <input type="hidden" value={orderId} name="Order_id" />
                                <input type="hidden" value={token} name="Token" />
                                <input type="hidden" value={logid} name="Log_id" />
                                <input type="hidden" value={total} name="Total" />
                                <button class="site-btn" type="submit" /**onClick={initiate_payment}*/>Place oder</button>


                               
                              {/*}  <label for="name">Name:</label>
                                <input type="text" id="name" name="name" />
                                <br />
                                <label for="message">Message:</label>
                                <textarea id="message" name="message"></textarea>
                                <br />
                                <button type="submit">Submit</button> */}
                                <p><b>Note:</b> (For orders of value less than Rs 450, shipping charges of Rs. 50 will be applicable)</p>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Checkout Section End */}

        {/* Instagram Begin 
        <div class="instagram">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-1.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-2.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-3.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-4.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-5.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div class="instagram__item set-bg" data-setbg="img/instagram/insta-6.jpg">
                            <div class="instagram__text">
                                <i class="fa fa-instagram"></i>
                                <a href="#">@ ashion_shop</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Instagram End */}

       <Footer/>

        {/* Js Plugins */}
        <script src="js/jquery-3.3.1.min.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/jquery.magnific-popup.min.js"></script>
        <script src="js/jquery-ui.min.js"></script>
        <script src="js/mixitup.min.js"></script>
        <script src="js/jquery.countdown.min.js"></script>
        <script src="js/jquery.slicknav.js"></script>
        <script src="js/owl.carousel.min.js"></script>
        <script src="js/jquery.nicescroll.min.js"></script>
        <script src="js/main.js"></script>
    </body>

    </html>
</>
);


}

export default Checkout;