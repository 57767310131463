import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';


  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");
  const orderId=sessionStorage.getItem("orderId00");

function Admin_view_order(){

    

useEffect(()=>{
   
    getData();
    
    },[])
  


    const[order_number,setorder_number]=useState();//order_number
    const[order_id,setorder_id]=useState();//order_id
    const[first_name,setfirst_name]=useState();//first_name
    const[last_name,setlast_name]=useState();//last_name
    const[email,setemail]=useState();//email
    const[contact,setcontact]=useState();//contact
    const[address,setaddress]=useState();//address
    const[city,setcity]=useState();//city
    const[state,setstate]=useState();//state
    const[country,setcountry]=useState();//country
    const[pincode,setpincode]=useState();//pincode
    const[order_date,setorder_date]=useState();//order_date
    const[order_status,setorder_status]=useState();//order_status
    const[coupon_applied,setcoupon_applied]=useState();//coupon_applied
    const[coupon_code,setcoupon_code]=useState();//coupon_code
    const[order_notes,setorder_notes]=useState();//order_notes
    const[amount,setamount]=useState();//amount
    const[discount_per,setdiscount_per]=useState();//discount_per
    const[discount_amt,setdiscount_amt]=useState();//discount_amt
    const[shipping_charges,setshipping_charges]=useState();//shipping_charges
    const[sgst_per,setsgst_per]=useState();//sgst_per
    const[sgst_amt,setsgst_amt]=useState();//sgst_amt
    const[cgst_per,setcgst_per]=useState();//cgst_per
    const[cgst_amt,setcgst_amt]=useState();//cgst_amt
    const[igst_per,setigst_per]=useState();//igst_per
    const[igst_amt,setigst_amt]=useState();//igst_amt
    const[total,settotal]=useState();//total
    const[order_items,setorder_items]=useState([],);//order_items

   //Error Variable
   const[errorvar,seterrorvar] = useState();
    

//******************* GET PRODUCT DATA FUNCTION START  ********************//
function getData(){
 

    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
   
   axios.post("admin-panel-view-order", {
     Log_id:logid,
     Token:token,
     'Order_id' : orderId,

     })
   .then((response) => {
  


    //Invalid Session
    if((response.data.error)==100){ 
        //Remove Session Variables and redirect to login
       sessionStorage.removeItem("logStatus00");
       sessionStorage.removeItem("logId00");
       sessionStorage.removeItem("logToken00");
       sessionStorage.removeItem("orderId00");
  
       window.location.replace("/");
    }
    //Success Data Fetched
    if(response.data.successvar==1){
     //Setting Data
     setorder_number(response.data.order_number);
     setfirst_name(response.data.first_name);
     setlast_name(response.data.last_name);
     setemail(response.data.email);
     setcontact(response.data.contact);
     setaddress(response.data.address);
     setcity(response.data.city);
     setstate(response.data.state);
     setcountry(response.data.country);
     setpincode(response.data.pincode);
     setorder_date(response.data.order_date);
     setorder_status(response.data.order_status);
     setcoupon_applied(response.data.coupon_applied);
     setcoupon_code(response.data.coupon_code);
     setorder_notes(response.data.order_notes);
     setamount(response.data.amount);
     setdiscount_per(response.data.discount_per);
     setdiscount_amt(response.data.discount_amt);
     setshipping_charges(response.data.shipping_charges);
     setsgst_per(response.data.sgst_per);
     setsgst_amt(response.data.sgst_amt);
     setcgst_per(response.data.cgst_per);
     setcgst_amt(response.data.cgst_amt);
     setigst_per(response.data.igst_per);
     setigst_amt(response.data.igst_amt);
     settotal(response.data.total);
     setorder_items(response.data.order_items);
   
    }
  
    //FILTER DATA
    //Validation Errors
    if(response.data.validatorerror){
  
      seterrorvar("Validation error!");
  
    }
  
    //Data Violation
    if(response.data.error==102){ 
    
       //Setting Filter Violation Errors
       seterrorvar("Violation Error");
  
  
    }
  
  
    //Cannot Fetch Data
    else{
     seterrorvar("Cannot Fetch Data !");
    }
   
   
   });
   
   }
   //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
  
  
     window.location.replace("/");
   
    }
   
   }
    
   //******************* GET PRODUCT DATA FUNCTION END  ********************//


    return(
        <>

<html lang="en">
<head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <title>My Order</title>

    {/*} BOOTSTRAP */}
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    {/*} Bootstrap CSS */}
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />

    

</head>
<body>
 {/*}   <div class="row">
    <div class="form-box col-md-12" style="border:0.1rem solid #1c0563; width:96vw;">

    
    <div  style="order-heading">
 
    <h2 style="text-align:center;">Order Invoice</h2>

    <div class="row" style="text-align:left; border-radius:7px; box-Shadow: 1px 2px 2px 2px #1c0563;"">
        <div class="col-md-6" style="text-align:left;">
            <div style="background-color:#1c0563; color:#ffffff; padding:0.5rem; border-radius:7px; text-align:center; margin-bottom:1rem;"><h5>Customer Details</h5></div>
            <h5><b>Name: </b> <span style="color:#7c8987;">Harsh Jaiswal</span></h5>
            <h5><b>Contact: </b> <span style="color:#7c8987;">91 4587854788</span></h5>
            <h5><b>Email: </b> <span style="color:#7c8987;">harshjaiswal20@gmail.com</span></h5>
            <h5><b>Address: </b> <span style="color:#7c8987;">56/A North Block go all road, Jabalpur (Madhya Pradesh)</span></h5>
            <h5><b>Pincode: </b> <span style="color:#7c8987;">482001 (India)</span></h5>
        </div>    
        <div class="col-md-6" style="text-align:left;">
        <div style="background-color:#1c0563; color:#ffffff; padding:0.5rem; border-radius:7px; text-align:center; margin-bottom:1rem;"><h5>Order Details</h5></div>
            <h5><b>Order Number: </b> <span style="color:#7c8987;">1234567890</span></h5>
        </div>    
    </div>
    
   </div>

</div>
</div>*/}

<div class="card">
  <div class="card-body">
    <div class="container mb-5 mt-3">
      <div class="d-flex justify-content-end">   
     
         </div>
      <div class="row d-flex align-items-baseline">
        <div class="col-xl-9">
          <p style={{color: "#7e8d9f", fontSize: "20px"}}>Order Number  <strong>: #{order_number}</strong></p>
        </div>
        <div class="col-xl-3 float-end">
      {/*}  <form action="{{ route('download-html') }}" method="post" >
        @csrf
      {/*}    <a href="/download-html" data-mdb-ripple-init class="btn btn-light text-capitalize border-0" data-mdb-ripple-color="dark"><i
              class="fas fa-print text-primary"></i> Print</a>
              <input type="hidden" value="{order_number }}" name="Order_number">
        <button type="submit" class="btn btn-light text-capitalize border-0" data-mdb-ripple-color="dark">Print</button>
        </form>*/}
        <img src="./whatjhumka_logo.jpg" style={{height:"3rem", width:"25rem"}} />
              
     {/*}     <a data-mdb-ripple-init class="btn btn-light text-capitalize" data-mdb-ripple-color="dark"><i
              class="far fa-file-pdf text-danger"></i> Export</a>*/}
        </div>
        <hr />
      </div>

      <div class="container">
        <div class="col-md-12">
          <div class="text-center">
            <i class="fab fa-mdb fa-4x ms-0" style={{color:"#5d9fc5"}}></i>
            <p class="pt-0"><b>Order Summary</b></p>
          </div>

        </div>


        <div class="row">
          <div class="col-xl-8">
            <ul class="list-unstyled">
              <li class="text-muted"><b>To: <span style={{color:"#5d9fc5"}}>{first_name} {last_name}</span></b></li>
              <li class="text-muted"><b>Address: </b>{address}, {city}({state})</li>
              <li class="text-muted"><b>Pincode: </b>({pincode}), India</li>
              <li class="text-muted"><i class="fas fa-phone"></i><b>Phone: </b> {contact}</li>
              <li class="text-muted"><i class="fas fa-mail"></i><b>Email: </b> {email}</li>
            </ul>
          </div>
          <div class="col-xl-4">
            <p class="text-muted"><b>Order Details</b></p>
            <ul class="list-unstyled">
              <li class="text-muted"><i class="fas fa-circle" style={{color:"#84B0CA"}}></i> <span
                  class="fw-bold"><b>Order Id: </b></span>{order_id}</li>
              <li class="text-muted"><i class="fas fa-circle" style={{color:"#84B0CA"}}></i> <span
                  class="fw-bold"><b>Date: </b></span>{order_date} (7:45pm)</li>
              <li class="text-muted"><i class="fas fa-circle" style={{color:"#84B0CA"}}></i> <span
                  class="me-1 fw-bold"><b>Status: </b></span>{/*}<span class="badge bg-warning text-black fw-bold">
                  paid</span>*/}{order_status}</li>
              <li class="text-muted"><i class="fas fa-circle" style={{color:"#84B0CA"}}></i> <span
                  class="me-1 fw-bold"><b>Coupon Applied: </b></span> {coupon_applied}</li>
              <li class="text-muted"><i class="fas fa-circle" style={{color:"#84B0CA"}}></i> <span
                  class="me-1 fw-bold"><b>Coupon Code: </b></span> {coupon_code}</li>
            </ul>
          </div>
        </div>

        <div class="row my-2 mx-1 justify-content-center">
          <table class="table table-striped table-borderless">
            <thead style={{backgroundColor:"#84B0CA"}} class="text-white">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Description</th>
                <th scope="col">Qty</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
            {
            order_items.map((curElm,ind)=>{

            return(
            <>
              <tr>
                <th scope="row">{curElm.Sn}</th>
                <td>{curElm.Product_name}</td>
                <td>{curElm.Quantity}</td>
                <td>₹{curElm.Rate}</td>
                <td>₹{curElm.Amount}</td>
              </tr>
              </>
             );
             }
             )}
					   
	                 
       
            </tbody>

          </table>
        </div>
        <div class="row">
          <div class="col-xl-8">
            <p class="ms-3">{order_notes}</p>

          </div>
          <div class="col-xl-3">
            <ul class="list-unstyled">
              <li class="text-muted ms-3"><span class="text-black me-4"><b>SubTotal: </b></span>₹ {amount}</li>
              <li class="text-muted ms-3"><span class="text-black me-4"><b>Discount: </b></span>₹ {discount_amt}</li>
          {/*}    <li class="text-muted ms-3"><span class="text-black me-4"><b>SGST (@{sgst_per}%): </b></span>₹ {sgst_amt}</li>
              <li class="text-muted ms-3"><span class="text-black me-4"><b>CGST (@{cgst_per}%): </b></span>₹ {cgst_amt}</li>
              <li class="text-muted ms-3"><span class="text-black me-4"><b>IGST (@ {igst_per}%): </b></span>₹ {igst_amt}</li> */}
              <li class="text-muted ms-3"><span class="text-black me-4"><b>Shiping Carriages: </b></span>₹ {shipping_charges}</li>
             
            </ul>
            <p class="text-black float-start"><span class="text-black me-3"> Total Amount</span><span
                style={{fontSize: "25px"}}>₹ {total}</span></p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-xl-10">
            <p>Thank you for your purchase</p>
          </div>
          <div class="col-xl-2">
            <button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary text-capitalize"
              style={{backgroundColor:"#60bdf3"}} onClick={()=>{
                //Back Function
                sessionStorage.removeItem("orderId00");
                window.location.replace("/admin-orders");
              }}>Back</button>
          </div> 
        </div> 

      </div>
    </div>
  </div>
</div>


    
      {/*} Optional JavaScript */}
    {/*} jQuery first, then Popper.js, then Bootstrap JS */}
    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.14.7/dist/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>

</body>
</html>



</>
);

}

export default Admin_view_order;

