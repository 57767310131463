import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");





function Admin_waaliyan(){

  
 const[data,setData] = useState([]);//SetData Variable

 //Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

  //const imgpath = 'http://127.0.0.1:8000/storage/';
  const imgpath = 'https://api.whatjhumka.in/storage/';

  //image upload
  //File Upload
  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };

  //Update Product in Shop
  const [display_product_id, setdisplay_product_id] = useState(); const updatedisplay_product_id = (event) => { setdisplay_product_id(event.target.value);}; const[display_product_id_er,setdisplay_product_id_er]=useState();
  //const [display_shop_id, setdisplay_shop_id] = useState(1); //const updatedisplay_shop_id = (event) => { setdisplay_shop_id(event.target.value);}; const[display_shop_id_er,setdisplay_shop_id_er]=useState();
  const [error_msg, seterror_msg] = useState();
  const [shop_action, setshop_action] = useState();

  
  

 useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET DATA FUNCTION START  ********************//
function getData(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("get-shop-products", {
   Log_id:logid,
   Token:token,
   Shop_id:3,
   Filter_val:0

   })
 .then((response) => {

  //Success Data Fetched
  if(response.data.successvar==1){

    //Setting Data
    setData(response.data.data.data);

  }
  //Cannot Fetch Data
 else{
  alert("Cannot Fetch Data");
 }
 

 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");


   window.location.replace("/");
 
  }
}
 
 //******************* GET DATA FUNCTION END  ********************//


/***************** UPDATE SHOP PRODUCT FUNCTION START  ******************/
function updateshopproduct(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setdisplay_product_id_er(" "); seterror_msg(" "); //setdisplay_rack_id_er(" ");
    
    //Fetching Form Data
    let noerror = 0;
    const $display_product_id = document.getElementById("display_product_id").value; 
   // const $display_shop_id = display_shop_id; //document.getElementById("display_rack_id").value; 


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("manage-shop-product", { 
  Log_id:logid,
  Token:token,
  Product_id:$display_product_id,
  Shop_id:3,
  Action: shop_action
  
  }
    )
  
  .then((response) => {
  
     //Validation Errors
  if(response.data.validatorerror){
  setdisplay_product_id_er(response.data.validatorerror.Product_id);
  //setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
  seterror_msg("Cannot Update Product !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  
  setdisplay_product_id_er(response.data.product_id_er);
  //setdisplay_rack_id_er(response.data.shop_id_er);
  seterror_msg("Cannot Update Product !");
  
  
  }

  //Product Not Found
  if(response.data.error==103){
  
  seterror_msg("Product Not Found for Product Code! ");

  
  }
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card").style.display="block";

  
  }
  //failure
  else{
  seterror_msg("Product Not Updated !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}
/***************** UPDATE SHOP PRODUCT FUNCTION END  ******************/

    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">

               {/*  SUCCESS CARD START */}
               <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-waaliyan"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Waaliyan</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{
                  setshop_action("add");
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}>
                  <i class="mdi mdi-plus-circle"></i> Add Product </button> 
              </div>
            </div>

                   {/* EDIT PRODUCT SECTION START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                                             }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">EDIT PRODUCT</h4>
                    <div class="form-sample">
                      <p class="card-description">update product</p>
                      <code>{error_msg}</code>
                      <div class="row mt-2" >
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Id<code>*</code></label>
                            <div class="col-sm-9">
                             <input type="text" placeholder="123" class="form-control" id="display_product_id" onChange={updatedisplay_product_id} />      
                              <code>{display_product_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-lg btn-primary" onClick={updateshopproduct}>Update Product</button>
                          </div>
                          </div>
      
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* EDIT PRODUCT SECTION END */}
                </div>

          
            {/* image card row starts here */}
            <div class="row">

            {
                       data.map((curElm,ind)=>{

                         return(
                         <>

              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+curElm.Product_image_link.slice(7)} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{curElm.Sp_id} ({curElm.Product_id})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {curElm.Product_title} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={(curElm.Product_rating)<5}></i><i class="mdi mdi-star star-color pe-1" hidden={(curElm.Product_rating)<4}></i><i class="mdi mdi-star star-color pe-1" hidden={(curElm.Product_rating)<3}></i><i class="mdi mdi-star star-color pe-1" hidden={(curElm.Product_rating)<2}></i><i class="mdi mdi-star star-color pe-1" hidden={(curElm.Product_rating)<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{curElm.Product_label}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {curElm.Product_mrp}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {curElm.Product_price}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("create-form").style.display="block";
                        document.getElementById("display_product_id").value=curElm.Product_id;
                        setshop_action("remove");
                        }} ><i class="mdi mdi-delete-forever text-danger" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>
              </>
                         )
                         }
					   
	                  		 )
                         }


           </div>
           
        
          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_waaliyan;



