import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';



function Shippingpolicy(){

  


return(
<>
<div style={{textAlign:"left", padding:"1.6rem"}} >
<p><h1>SHIPPING POLICY</h1></p>
<p><strong>Shipping Policy for WhatJhumka</strong></p>
<p>Thank you for choosing WhatJhumka for your jewelry needs. We strive to ensure that your order reaches you safely and promptly. Please review our shipping policy below:</p>
<p><strong>Processing Time:</strong></p>
<ul>
<li>Orders are typically processed and shipped within 1-3 business days after payment confirmation. Custom or made-to-order items may require additional processing time, which will be communicated upon order placement.</li>
</ul>
<p><strong>Shipping Methods:</strong></p>
<ul>
<li>We offer standard shipping and expedited shipping options for domestic and international orders.</li>
<li>Standard shipping typically takes 3-7 business days for domestic orders and 7-21 business days for international orders, depending on the destination.</li>
<li>Expedited shipping options are available for faster delivery, with delivery times varying based on the chosen shipping method and destination.</li>
</ul>
<p><strong>Shipping Costs:</strong></p>
<ul>
<li>Shipping Fee Calculation</li>
<li>Shipping fee for order value between&nbsp;<strong>INR 0 - 499 &ndash; INR 50</strong></li>
<li>Shipping fee for order value above&nbsp;<strong>INR 499 &ndash; Free</strong></li>
<li>All times and dates given for delivery of the products are given in good faith but are estimates only.</li>
</ul>
<p><strong>Order Tracking:</strong></p>
<ul>
<li>Once your order has been shipped, you will receive a confirmation email containing tracking information. You can track the status of your shipment using the provided tracking number.</li>
</ul>
<p><strong>Shipping Restrictions:</strong></p>
<ul>
<li>We currently ship to select countries worldwide. Please ensure that your shipping address is accurate and complete to avoid delays or delivery issues.</li>
<li>Please note that we are not responsible for any customs duties, taxes, or fees incurred for international shipments. These charges are the responsibility of the recipient.</li>
</ul>
<p><strong>Shipping Delays:</strong></p>
<ul>
<li>While we make every effort to ensure timely delivery, please note that shipping times may vary due to factors beyond our control, such as weather conditions, customs delays, or carrier issues. We appreciate your patience and understanding in such situations.</li>
</ul>
<p><strong>Shipping Address:</strong></p>
<ul>
<li>Please double-check your shipping address before completing your order. We are not responsible for orders shipped to incorrect addresses provided by the customer. Additional shipping charges may apply for orders that are returned to us due to incorrect address information.</li>
</ul>
<p><strong>Returns Due to Non-Delivery:</strong></p>
<ul>
<li>If a package is returned to us due to non-delivery (e.g., incorrect address provided, failure to collect from the post office), the customer will be responsible for additional shipping charges to reship the order.</li>
</ul>
<p><strong>Contact Us:</strong></p>
<ul>
<li>If you have any questions or concerns regarding our shipping policy, please feel free to contact us at 
<br/>
<br/>
<b>[Email- Whatjhumka2024@gmail.com] or [Mobile- +91 92389 40060]. Our customer service team will be happy to assist you.</b></li>
</ul>
<p><strong>Policy Updates:</strong></p>
<ul>
<li>We reserve the right to update or modify our shipping policy at any time. Any changes will be effective immediately upon posting on our website.</li>
</ul>
<p>Thank you for choosing WhatJhumka for your jewelry needs. We appreciate your business!</p>
<p>&nbsp;</p>
</div>
      {/* partial */}
</>
);


}

export default Shippingpolicy;