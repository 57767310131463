import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");



function Admin_home(){

 // const imgpath = 'http://127.0.0.1:8000/storage/';
const imgpath = 'https://api.whatjhumka.in/storage/';

  //VARIABLES

  //HEADERS
  
  //HomeHeader1
  const [hm_id1, sethm_id1] = useState();
  const [hm_code1, sethm_code1] = useState();
  const [hm_title1, sethm_title1] = useState();
  const [card_heading1, setcard_heading1] = useState();
  const [card_text1, setcard_text1] = useState();
  const [button_text1, setbutton_text1] = useState();
  const [button_link1, setbutton_link1] = useState(); 
  const [image_title1, setimage_title1] = useState();
  const [image_link1, setimage_link1] = useState();
  
  //HomeHeader2
  const [hm_id2, sethm_id2] = useState();
  const [hm_code2, sethm_code2] = useState();
  const [hm_title2, sethm_title2] = useState();
  const [card_heading2, setcard_heading2] = useState();
  const [card_text2, setcard_text2] = useState();
  const [button_text2, setbutton_text2] = useState();
  const [button_link2, setbutton_link2] = useState(); 
  const [image_title2, setimage_title2] = useState();
  const [image_link2, setimage_link2] = useState();
  
  //HomeHeader3
  const [hm_id3, sethm_id3] = useState();
  const [hm_code3, sethm_code3] = useState();
  const [hm_title3, sethm_title3] = useState();
  const [card_heading3, setcard_heading3] = useState();
  const [card_text3, setcard_text3] = useState();
  const [button_text3, setbutton_text3] = useState();
  const [button_link3, setbutton_link3] = useState(); 
  const [image_title3, setimage_title3] = useState();
  const [image_link3, setimage_link3] = useState();
  
  //HomeHeader4
  const [hm_id4, sethm_id4] = useState();
  const [hm_code4, sethm_code4] = useState();
  const [hm_title4, sethm_title4] = useState();
  const [card_heading4, setcard_heading4] = useState();
  const [card_text4, setcard_text4] = useState();
  const [button_text4, setbutton_text4] = useState();
  const [button_link4, setbutton_link4] = useState(); 
  const [image_title4, setimage_title4] = useState();
  const [image_link4, setimage_link4] = useState();
  
  //HomeHeader5
  const [hm_id5, sethm_id5] = useState();
  const [hm_code5, sethm_code5] = useState();
  const [hm_title5, sethm_title5] = useState();
  const [card_heading5, setcard_heading5] = useState();
  const [card_text5, setcard_text5] = useState();
  const [button_text5, setbutton_text5] = useState();
  const [button_link5, setbutton_link5] = useState(); 
  const [image_title5, setimage_title5] = useState();
  const [image_link5, setimage_link5] = useState();

  //Edit Homeheader variables
  const [edithm_id, setedithm_id] = useState();const updateedithm_id = (event) => { setedithm_id(event.target.value);}; const[edithm_id_er,setedithm_id_er]=useState();
  const [edithm_code, setedithm_code] = useState();const updateedithm_code = (event) => { setedithm_code(event.target.value);}; const[edithm_code_er,setedithm_code_er]=useState();
  const [edithm_title, setedithm_title] = useState();const updateedithm_title = (event) => { setedithm_title(event.target.value);}; const[edithm_title_er,setedithm_title_er]=useState();
  const [editcard_heading, seteditcard_heading] = useState();const updateeditcard_heading = (event) => { seteditcard_heading(event.target.value);}; const[editcard_heading_er,seteditcard_heading_er]=useState();
  const [editcard_text, seteditcard_text] = useState();const updateeditcard_text = (event) => { seteditcard_text(event.target.value);}; const[editcard_text_er,seteditcard_text_er]=useState();
  const [editbutton_text, seteditbutton_text] = useState();const updateeditbutton_text = (event) => { seteditbutton_text(event.target.value);}; const[editbutton_text_er,seteditbutton_text_er]=useState();
  const [editbutton_link, seteditbutton_link] = useState();const updateeditbutton_link = (event) => { seteditbutton_link(event.target.value);}; const[editbutton_link_er,seteditbutton_link_er]=useState();
  const [editimage_title, seteditimage_title] = useState();const updateeditimage_title = (event) => { seteditimage_title(event.target.value);}; const[editimage_title_er,seteditimage_title_er]=useState();
  const [editimage_link, seteditimage_link] = useState();const updateeditimage_link = (event) => { seteditimage_link(event.target.value);}; const[editimage_link_er,seteditimage_link_er]=useState();
  //logo_link
  const[logo_link,setlogo_link]=useState();const updatelogo_link = (event) => { setlogo_link(event.target.value[0]);};  const[logo_link_er,setlogo_link_er]=useState();
  const [error_msg, seterror_msg] = useState();


  //image upload
  //File Upload
  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };


  
  //PRODUCTS
  
  //HomePoductCard1
  const [hp_id1, sethp_id1] = useState();
  const [hproduct_id1, sethproduct_id1] = useState();
  const [hproduct_title1, sethproduct_title1] = useState();
  const [hproduct_code1, sethproduct_code1] = useState();
  const [hproduct_rating1, sethproduct_rating1] = useState();
  const [hproduct_mrp1, sethproduct_mrp1] = useState();
  const [hproduct_price1, sethproduct_price1] = useState(); 
  const [hproduct_label1, sethproduct_label1] = useState();
  const [hproduct_image_link1, sethproduct_image_link1] = useState();
  //HomePoductCard2
  const [hp_id2, sethp_id2] = useState();
  const [hproduct_id2, sethproduct_id2] = useState();
  const [hproduct_title2, sethproduct_title2] = useState();
  const [hproduct_code2, sethproduct_code2] = useState();
  const [hproduct_rating2, sethproduct_rating2] = useState();
  const [hproduct_mrp2, sethproduct_mrp2] = useState();
  const [hproduct_price2, sethproduct_price2] = useState(); 
  const [hproduct_label2, sethproduct_label2] = useState();
  const [hproduct_image_link2, sethproduct_image_link2] = useState();
  //HomePoductCard3
  const [hp_id3, sethp_id3] = useState();
  const [hproduct_id3, sethproduct_id3] = useState();
  const [hproduct_title3, sethproduct_title3] = useState();
  const [hproduct_code3, sethproduct_code3] = useState();
  const [hproduct_rating3, sethproduct_rating3] = useState();
  const [hproduct_mrp3, sethproduct_mrp3] = useState();
  const [hproduct_price3, sethproduct_price3] = useState(); 
  const [hproduct_label3, sethproduct_label3] = useState();
  const [hproduct_image_link3, sethproduct_image_link3] = useState();
  //HomePoductCard4
  const [hp_id4, sethp_id4] = useState();
  const [hproduct_id4, sethproduct_id4] = useState();
  const [hproduct_title4, sethproduct_title4] = useState();
  const [hproduct_code4, sethproduct_code4] = useState();
  const [hproduct_rating4, sethproduct_rating4] = useState();
  const [hproduct_mrp4, sethproduct_mrp4] = useState();
  const [hproduct_price4, sethproduct_price4] = useState(); 
  const [hproduct_label4, sethproduct_label4] = useState();
  const [hproduct_image_link4, sethproduct_image_link4] = useState();
  //HomePoductCard5
  const [hp_id5, sethp_id5] = useState();
  const [hproduct_id5, sethproduct_id5] = useState();
  const [hproduct_title5, sethproduct_title5] = useState();
  const [hproduct_code5, sethproduct_code5] = useState();
  const [hproduct_rating5, sethproduct_rating5] = useState();
  const [hproduct_mrp5, sethproduct_mrp5] = useState();
  const [hproduct_price5, sethproduct_price5] = useState(); 
  const [hproduct_label5, sethproduct_label5] = useState();
  const [hproduct_image_link5, sethproduct_image_link5] = useState();
  //HomePoductCard6
  const [hp_id6, sethp_id6] = useState();
  const [hproduct_id6, sethproduct_id6] = useState();
  const [hproduct_title6, sethproduct_title6] = useState();
  const [hproduct_code6, sethproduct_code6] = useState();
  const [hproduct_rating6, sethproduct_rating6] = useState();
  const [hproduct_mrp6, sethproduct_mrp6] = useState();
  const [hproduct_price6, sethproduct_price6] = useState(); 
  const [hproduct_label6, sethproduct_label6] = useState();
  const [hproduct_image_link6, sethproduct_image_link6] = useState();
  //HomePoductCard7
  const [hp_id7, sethp_id7] = useState();
  const [hproduct_id7, sethproduct_id7] = useState();
  const [hproduct_title7, sethproduct_title7] = useState();
  const [hproduct_code7, sethproduct_code7] = useState();
  const [hproduct_rating7, sethproduct_rating7] = useState();
  const [hproduct_mrp7, sethproduct_mrp7] = useState();
  const [hproduct_price7, sethproduct_price7] = useState(); 
  const [hproduct_label7, sethproduct_label7] = useState();
  const [hproduct_image_link7, sethproduct_image_link7] = useState();
  //HomePoductCard8
  const [hp_id8, sethp_id8] = useState();
  const [hproduct_id8, sethproduct_id8] = useState();
  const [hproduct_title8, sethproduct_title8] = useState();
  const [hproduct_code8, sethproduct_code8] = useState();
  const [hproduct_rating8, sethproduct_rating8] = useState();
  const [hproduct_mrp8, sethproduct_mrp8] = useState();
  const [hproduct_price8, sethproduct_price8] = useState(); 
  const [hproduct_label8, sethproduct_label8] = useState();
  const [hproduct_image_link8, sethproduct_image_link8] = useState();

   
    //HOME BANNER SECTION
    const [bhm_id, setbhm_id] = useState();
    const [bhm_code, setbhm_code] = useState();
    const [bhm_title, setbhm_title] = useState();
    const [bcard_heading, setbcard_heading] = useState();
    const [bcard_text, setbcard_text] = useState();
    const [bbutton_text, setbbutton_text] = useState();
    const [bbutton_link, setbbutton_link] = useState(); 
    const [bimage_title, setbimage_title] = useState();
    const [bimage_link, setbimage_link] = useState();


  //PRODUCT FILTER
  const [product_filterval, setproduct_filterval] = useState(0);
  const [filter_product_name, setfilter_product_name] = useState();const updatefilter_product_name = (event) => { setfilter_product_name(event.target.value);}; const[filter_product_name_er,setfilter_product_name_er]=useState();
  const [filter_product_id, setfilter_product_id] = useState(); const updatefilter_product_id = (event) => { setfilter_product_id(event.target.value);}; const[filter_product_id_er,setfilter_product_id_er]=useState();


  //Update Product in Display
  const [display_product_id, setdisplay_product_id] = useState(); const updatedisplay_product_id = (event) => { setdisplay_product_id(event.target.value);}; const[display_product_id_er,setdisplay_product_id_er]=useState();
  const [display_rack_id, setdisplay_rack_id] = useState(); const updatedisplay_rack_id = (event) => { setdisplay_rack_id(event.target.value);}; const[display_rack_id_er,setdisplay_rack_id_er]=useState();
  const [error_msg1, seterror_msg1] = useState();


  
  //TREND PRODUCTS
  
  //Trend1Product1
  const [tp_id1, settp_id1] = useState();
  const [trend_id1, settrend_id1] = useState();
  const [trend_title1, settrend_title1] = useState();
  const [tp_product_id1, settp_product_id1] = useState();
  const [tp_product_title1, settp_product_title1] = useState();
  const [tp_product_code1, settp_product_code1] = useState();
  const [tp_product_rating1, settp_product_rating1] = useState();
  const [tp_product_mrp1, settp_product_mrp1] = useState();
  const [tp_product_price1, settp_product_price1] = useState(); 
  const [tp_product_label1, settp_product_label1] = useState();
  const [tp_product_image_link1, settp_product_image_link1] = useState(); 
  //Trend1Product2
  const [tp_id2, settp_id2] = useState();
  const [trend_id2, settrend_id2] = useState();
  const [trend_title2, settrend_title2] = useState();
  const [tp_product_id2, settp_product_id2] = useState();
  const [tp_product_title2, settp_product_title2] = useState();
  const [tp_product_code2, settp_product_code2] = useState();
  const [tp_product_rating2, settp_product_rating2] = useState();
  const [tp_product_mrp2, settp_product_mrp2] = useState();
  const [tp_product_price2, settp_product_price2] = useState(); 
  const [tp_product_label2, settp_product_label2] = useState();
  const [tp_product_image_link2, settp_product_image_link2] = useState();  
  //Trend1Product3
  const [tp_id3, settp_id3] = useState();
  const [trend_id3, settrend_id3] = useState();
  const [trend_title3, settrend_title3] = useState();
  const [tp_product_id3, settp_product_id3] = useState();
  const [tp_product_title3, settp_product_title3] = useState();
  const [tp_product_code3, settp_product_code3] = useState();
  const [tp_product_rating3, settp_product_rating3] = useState();
  const [tp_product_mrp3, settp_product_mrp3] = useState();
  const [tp_product_price3, settp_product_price3] = useState(); 
  const [tp_product_label3, settp_product_label3] = useState();
  const [tp_product_image_link3, settp_product_image_link3] = useState();
  //Trend2Product1
  const [tp_id4, settp_id4] = useState();
  const [trend_id4, settrend_id4] = useState();
  const [trend_title4, settrend_title4] = useState();
  const [tp_product_id4, settp_product_id4] = useState();
  const [tp_product_title4, settp_product_title4] = useState();
  const [tp_product_code4, settp_product_code4] = useState();
  const [tp_product_rating4, settp_product_rating4] = useState();
  const [tp_product_mrp4, settp_product_mrp4] = useState();
  const [tp_product_price4, settp_product_price4] = useState(); 
  const [tp_product_label4, settp_product_label4] = useState();
  const [tp_product_image_link4, settp_product_image_link4] = useState();
  //Trend2Product2
  const [tp_id5, settp_id5] = useState();
  const [trend_id5, settrend_id5] = useState();
  const [trend_title5, settrend_title5] = useState();
  const [tp_product_id5, settp_product_id5] = useState();
  const [tp_product_title5, settp_product_title5] = useState();
  const [tp_product_code5, settp_product_code5] = useState();
  const [tp_product_rating5, settp_product_rating5] = useState();
  const [tp_product_mrp5, settp_product_mrp5] = useState();
  const [tp_product_price5, settp_product_price5] = useState(); 
  const [tp_product_label5, settp_product_label5] = useState();
  const [tp_product_image_link5, settp_product_image_link5] = useState();
  //Trend2Product3
  const [tp_id6, settp_id6] = useState();
  const [trend_id6, settrend_id6] = useState();
  const [trend_title6, settrend_title6] = useState();
  const [tp_product_id6, settp_product_id6] = useState();
  const [tp_product_title6, settp_product_title6] = useState();
  const [tp_product_code6, settp_product_code6] = useState();
  const [tp_product_rating6, settp_product_rating6] = useState();
  const [tp_product_mrp6, settp_product_mrp6] = useState();
  const [tp_product_price6, settp_product_price6] = useState(); 
  const [tp_product_label6, settp_product_label6] = useState();
  const [tp_product_image_link6, settp_product_image_link6] = useState();
  //Trend3Product1
  const [tp_id7, settp_id7] = useState();
  const [trend_id7, settrend_id7] = useState();
  const [trend_title7, settrend_title7] = useState();
  const [tp_product_id7, settp_product_id7] = useState();
  const [tp_product_title7, settp_product_title7] = useState();
  const [tp_product_code7, settp_product_code7] = useState();
  const [tp_product_rating7, settp_product_rating7] = useState();
  const [tp_product_mrp7, settp_product_mrp7] = useState();
  const [tp_product_price7, settp_product_price7] = useState(); 
  const [tp_product_label7, settp_product_label7] = useState();
  const [tp_product_image_link7, settp_product_image_link7] = useState();
  //Trend3Product2
  const [tp_id8, settp_id8] = useState();
  const [trend_id8, settrend_id8] = useState();
  const [trend_title8, settrend_title8] = useState();
  const [tp_product_id8, settp_product_id8] = useState();
  const [tp_product_title8, settp_product_title8] = useState();
  const [tp_product_code8, settp_product_code8] = useState();
  const [tp_product_rating8, settp_product_rating8] = useState();
  const [tp_product_mrp8, settp_product_mrp8] = useState();
  const [tp_product_price8, settp_product_price8] = useState(); 
  const [tp_product_label8, settp_product_label8] = useState();
  const [tp_product_image_link8, settp_product_image_link8] = useState();
  //Trend3Product3
  const [tp_id9, settp_id9] = useState();
  const [trend_id9, settrend_id9] = useState();
  const [trend_title9, settrend_title9] = useState();
  const [tp_product_id9, settp_product_id9] = useState();
  const [tp_product_title9, settp_product_title9] = useState();
  const [tp_product_code9, settp_product_code9] = useState();
  const [tp_product_rating9, settp_product_rating9] = useState();
  const [tp_product_mrp9, settp_product_mrp9] = useState();
  const [tp_product_price9, settp_product_price9] = useState(); 
  const [tp_product_label9, settp_product_label9] = useState();
  const [tp_product_image_link9, settp_product_image_link9] = useState();

  
  //Update Product in Trends
  const [trend_product_id, settrend_product_id] = useState(); const updatetrend_product_id = (event) => { settrend_product_id(event.target.value);}; const[trend_product_id_er,settrend_product_id_er]=useState();
  const [trend_rack_id, settrend_rack_id] = useState(); const updatetrend_rack_id = (event) => { settrend_rack_id(event.target.value);}; const[trend_rack_id_er,settrend_rack_id_er]=useState();
  const [error_msg2, seterror_msg2] = useState();


 useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("manage-home-default-load", {
   Log_id:logid,
   Token:token,

   })
 .then((response) => {

  //Success Data Fetched
  if(response.data.successvar==1){

    //Setting Data
    
    //Homeheader1
    sethm_id1(response.data.hm_id1);
    sethm_code1(response.data.hm_code1);
    sethm_title1(response.data.hm_title1);
    setcard_heading1(response.data.card_heading1);
    setcard_text1(response.data.card_text1);
    setbutton_text1(response.data.button_text1);
    setbutton_link1(response.data.button_link1);
    setimage_title1(response.data.image_title1);
    setimage_link1((response.data.image_link1).slice(7));

    //Homeheader2
    sethm_id2(response.data.hm_id2);
    sethm_code2(response.data.hm_code2);
    sethm_title2(response.data.hm_title2);
    setcard_heading2(response.data.card_heading2);
    setcard_text2(response.data.card_text2);
    setbutton_text2(response.data.button_text2);
    setbutton_link2(response.data.button_link2);
    setimage_title2(response.data.image_title2);
    setimage_link2((response.data.image_link2).slice(7));

    //Homeheader3
    sethm_id3(response.data.hm_id3);
    sethm_code3(response.data.hm_code3);
    sethm_title3(response.data.hm_title3);
    setcard_heading3(response.data.card_heading3);
    setcard_text3(response.data.card_text3);
    setbutton_text3(response.data.button_text3);
    setbutton_link3(response.data.button_link3);
    setimage_title3(response.data.image_title3);
    setimage_link3((response.data.image_link3).slice(7));

    
    //Homeheader4
    sethm_id4(response.data.hm_id4);
    sethm_code4(response.data.hm_code4);
    sethm_title4(response.data.hm_title4);
    setcard_heading4(response.data.card_heading4);
    setcard_text4(response.data.card_text4);
    setbutton_text4(response.data.button_text4);
    setbutton_link4(response.data.button_link4);
    setimage_title4(response.data.image_title4);
    setimage_link4((response.data.image_link4).slice(7));

    
    //Homeheader5
    sethm_id5(response.data.hm_id5);
    sethm_code5(response.data.hm_code5);
    sethm_title5(response.data.hm_title5);
    setcard_heading5(response.data.card_heading5);
    setcard_text5(response.data.card_text5);
    setbutton_text5(response.data.button_text5);
    setbutton_link5(response.data.button_link5);
    setimage_title5(response.data.image_title5);
    setimage_link5((response.data.image_link5).slice(7));


    //SETTING HOME PRODUCT CARDS DATA
    //HomeProductCard1
    sethp_id1(response.data.hp_id1);
    sethproduct_id1(response.data.hproduct_id1);
    sethproduct_code1(response.data.hproduct_code1);
    sethproduct_title1(response.data.hproduct_title1);
    sethproduct_rating1(response.data.hproduct_rating1);
    sethproduct_label1(response.data.hproduct_label1);
    sethproduct_price1(response.data.hproduct_price1);
    sethproduct_mrp1(response.data.hproduct_mrp1);
    sethproduct_image_link1((response.data.hproduct_image_link1).slice(7));
    //HomeProductCard2
    sethp_id2(response.data.hp_id2);
    sethproduct_id2(response.data.hproduct_id2);
    sethproduct_code2(response.data.hproduct_code2);
    sethproduct_title2(response.data.hproduct_title2);
    sethproduct_rating2(response.data.hproduct_rating2);
    sethproduct_label2(response.data.hproduct_label2);
    sethproduct_price2(response.data.hproduct_price2);
    sethproduct_mrp2(response.data.hproduct_mrp2);
    sethproduct_image_link2((response.data.hproduct_image_link2).slice(7));
   //HomeProductCard3
   sethp_id3(response.data.hp_id3);
   sethproduct_id3(response.data.hproduct_id3);
   sethproduct_code3(response.data.hproduct_code3);
   sethproduct_title3(response.data.hproduct_title3);
   sethproduct_rating3(response.data.hproduct_rating3);
   sethproduct_label3(response.data.hproduct_label3);
   sethproduct_price3(response.data.hproduct_price3);
   sethproduct_mrp3(response.data.hproduct_mrp3);
   sethproduct_image_link3((response.data.hproduct_image_link3).slice(7));
   //HomeProductCard4
   sethp_id4(response.data.hp_id4);
   sethproduct_id4(response.data.hproduct_id4);
   sethproduct_code4(response.data.hproduct_code4);
   sethproduct_title4(response.data.hproduct_title4);
   sethproduct_rating4(response.data.hproduct_rating4);
   sethproduct_label4(response.data.hproduct_label4);
   sethproduct_price4(response.data.hproduct_price4);
   sethproduct_mrp4(response.data.hproduct_mrp4);
   sethproduct_image_link4((response.data.hproduct_image_link4).slice(7));
   //HomeProductCard5
   sethp_id5(response.data.hp_id5);
   sethproduct_id5(response.data.hproduct_id5);
   sethproduct_code5(response.data.hproduct_code5);
   sethproduct_title5(response.data.hproduct_title5);
   sethproduct_rating5(response.data.hproduct_rating5);
   sethproduct_label5(response.data.hproduct_label5);
   sethproduct_price5(response.data.hproduct_price5);
   sethproduct_mrp5(response.data.hproduct_mrp5);
   sethproduct_image_link5((response.data.hproduct_image_link5).slice(7));
   //HomeProductCard6
   sethp_id6(response.data.hp_id6);
   sethproduct_id6(response.data.hproduct_id6);
   sethproduct_code6(response.data.hproduct_code6);
   sethproduct_title6(response.data.hproduct_title6);
   sethproduct_rating6(response.data.hproduct_rating6);
   sethproduct_label6(response.data.hproduct_label6);
   sethproduct_price6(response.data.hproduct_price6);
   sethproduct_mrp6(response.data.hproduct_mrp6);
   sethproduct_image_link6((response.data.hproduct_image_link6).slice(7));
   //HomeProductCard7
   sethp_id7(response.data.hp_id7);
   sethproduct_id7(response.data.hproduct_id7);
   sethproduct_code7(response.data.hproduct_code7);
   sethproduct_title7(response.data.hproduct_title7);
   sethproduct_rating7(response.data.hproduct_rating7);
   sethproduct_label7(response.data.hproduct_label7);
   sethproduct_price7(response.data.hproduct_price7);
   sethproduct_mrp7(response.data.hproduct_mrp7);
   sethproduct_image_link7((response.data.hproduct_image_link7).slice(7));
   //HomeProductCard8
   sethp_id8(response.data.hp_id8);
   sethproduct_id8(response.data.hproduct_id8);
   sethproduct_code8(response.data.hproduct_code8);
   sethproduct_title8(response.data.hproduct_title8);
   sethproduct_rating8(response.data.hproduct_rating8);
   sethproduct_label8(response.data.hproduct_label8);
   sethproduct_price8(response.data.hproduct_price8);
   sethproduct_mrp8(response.data.hproduct_mrp8);
   sethproduct_image_link8((response.data.hproduct_image_link8).slice(7));

   
    //Home Banner
    setbhm_id(response.data.bhm_id);
    setbhm_code(response.data.bhm_code);
    setbhm_title(response.data.bhm_title);
    setbcard_heading(response.data.bcard_heading);
    setbcard_text(response.data.bcard_text);
    setbbutton_text(response.data.bbutton_text);
    setbbutton_link(response.data.bbutton_link);
    setbimage_title(response.data.bimage_title);
    setbimage_link((response.data.bimage_link).slice(7));

     //TRENDS PRODUCT
     //Trend1Product1
     settp_id1(response.data.tp_id1);
     settrend_id1(response.data.trend_id1);
     settrend_title1(response.data.trend_title1);
     settp_product_id1(response.data.tp_product_id1);
     settp_product_code1(response.data.tp_product_code1);
     settp_product_title1(response.data.tp_product_title1);
     settp_product_rating1(response.data.tp_product_rating1);
     settp_product_label1(response.data.tp_product_label1);
     settp_product_price1(response.data.tp_product_price1);
     settp_product_mrp1(response.data.tp_product_mrp1);
     settp_product_image_link1((response.data.tp_product_image_link1).slice(7));
     //Trend1Product2
     settp_id2(response.data.tp_id2);
     settrend_id2(response.data.trend_id2);
     settrend_title2(response.data.trend_title2);
     settp_product_id2(response.data.tp_product_id2);
     settp_product_code2(response.data.tp_product_code2);
     settp_product_title2(response.data.tp_product_title2);
     settp_product_rating2(response.data.tp_product_rating2);
     settp_product_label2(response.data.tp_product_label2);
     settp_product_price2(response.data.tp_product_price2);
     settp_product_mrp2(response.data.tp_product_mrp2);
     settp_product_image_link2((response.data.tp_product_image_link2).slice(7));
     //Trend1Product3
     settp_id3(response.data.tp_id3);
     settrend_id3(response.data.trend_id3);
     settrend_title3(response.data.trend_title3);
     settp_product_id3(response.data.tp_product_id3);
     settp_product_code3(response.data.tp_product_code3);
     settp_product_title3(response.data.tp_product_title3);
     settp_product_rating3(response.data.tp_product_rating3);
     settp_product_label3(response.data.tp_product_label3);
     settp_product_price3(response.data.tp_product_price3);
     settp_product_mrp3(response.data.tp_product_mrp3);
     settp_product_image_link3((response.data.tp_product_image_link3).slice(7));
     //Trend2Product1
     settp_id4(response.data.tp_id4);
     settrend_id4(response.data.trend_id4);
     settrend_title4(response.data.trend_title4);
     settp_product_id4(response.data.tp_product_id4);
     settp_product_code4(response.data.tp_product_code4);
     settp_product_title4(response.data.tp_product_title4);
     settp_product_rating4(response.data.tp_product_rating4);
     settp_product_label4(response.data.tp_product_label4);
     settp_product_price4(response.data.tp_product_price4);
     settp_product_mrp4(response.data.tp_product_mrp4);
     settp_product_image_link4((response.data.tp_product_image_link4).slice(7));
     //Trend2Product2
     settp_id5(response.data.tp_id5);
     settrend_id5(response.data.trend_id5);
     settrend_title5(response.data.trend_title5);
     settp_product_id5(response.data.tp_product_id5);
     settp_product_code5(response.data.tp_product_code5);
     settp_product_title5(response.data.tp_product_title5);
     settp_product_rating5(response.data.tp_product_rating5);
     settp_product_label5(response.data.tp_product_label5);
     settp_product_price5(response.data.tp_product_price5);
     settp_product_mrp5(response.data.tp_product_mrp5);
     settp_product_image_link5((response.data.tp_product_image_link5).slice(7));
     //Trend2Product3
     settp_id6(response.data.tp_id6);
     settrend_id6(response.data.trend_id6);
     settrend_title6(response.data.trend_title6);
     settp_product_id6(response.data.tp_product_id6);
     settp_product_code6(response.data.tp_product_code6);
     settp_product_title6(response.data.tp_product_title6);
     settp_product_rating6(response.data.tp_product_rating6);
     settp_product_label6(response.data.tp_product_label6);
     settp_product_price6(response.data.tp_product_price6);
     settp_product_mrp6(response.data.tp_product_mrp6);
     settp_product_image_link6((response.data.tp_product_image_link6).slice(7));
     //Trend3Product1
     settp_id7(response.data.tp_id7);
     settrend_id7(response.data.trend_id7);
     settrend_title7(response.data.trend_title7);
     settp_product_id7(response.data.tp_product_id7);
     settp_product_code7(response.data.tp_product_code7);
     settp_product_title7(response.data.tp_product_title7);
     settp_product_rating7(response.data.tp_product_rating7);
     settp_product_label7(response.data.tp_product_label7);
     settp_product_price7(response.data.tp_product_price7);
     settp_product_mrp7(response.data.tp_product_mrp7);
     settp_product_image_link7((response.data.tp_product_image_link7).slice(7));
     //Trend3Product2
     settp_id8(response.data.tp_id8);
     settrend_id8(response.data.trend_id8);
     settrend_title8(response.data.trend_title8);
     settp_product_id8(response.data.tp_product_id8);
     settp_product_code8(response.data.tp_product_code8);
     settp_product_title8(response.data.tp_product_title8);
     settp_product_rating8(response.data.tp_product_rating8);
     settp_product_label8(response.data.tp_product_label8);
     settp_product_price8(response.data.tp_product_price8);
     settp_product_mrp8(response.data.tp_product_mrp8);
     settp_product_image_link8((response.data.tp_product_image_link8).slice(7));
     //Trend3Product3
     settp_id9(response.data.tp_id9);
     settrend_id9(response.data.trend_id9);
     settrend_title9(response.data.trend_title9);
     settp_product_id9(response.data.tp_product_id9);
     settp_product_code9(response.data.tp_product_code9);
     settp_product_title9(response.data.tp_product_title9);
     settp_product_rating9(response.data.tp_product_rating9);
     settp_product_label9(response.data.tp_product_label9);
     settp_product_price9(response.data.tp_product_price9);
     settp_product_mrp9(response.data.tp_product_mrp9);
     settp_product_image_link9((response.data.tp_product_image_link9).slice(7));

  }
  //Cannot Fetch Data
 else{
  alert("Cannot Fetch Data");
 }
 

 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");


   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET DATA FUNCTION END  ********************//

 
/***************** EDIT HOME HEADER FUNCTION START ******************/
function edithomeheader(){

  seteditcard_heading_er(" ");seteditcard_text_er(" ");seteditbutton_text_er(" ");seteditbutton_link_er(" ");seteditimage_link_er(" ");seterror_msg(" ");
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(edithm_id>0)){


    
//Fetching Form Data
let noerror = 0;
//const $hm_id = document.getElementById("edithm_id").value; 
//const $hm_code = document.getElementById("edithm_code").value; 
//const $hm_title = document.getElementById("edithm_title").value; 
const $card_heading = document.getElementById("editcard_heading").value; 
const $card_text = document.getElementById("editcard_text").value; 
const $button_text = document.getElementById("editbutton_text").value; 
const $button_link = document.getElementById("editbutton_link").value; 


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("edit-home-section", { 
  Log_id:logid,
  Token:token,
  Hm_id:edithm_id,
  //Hm_code:$hm_code,
  //Hm_title:$hm_title,
  Card_heading:$card_heading,
  Card_text:$card_text,
  Button_text:$button_text,
  Button_link:$button_link,
  Image_link:selectedFile
  },{
  headers: { "Content-Type": "multipart/form-data" },
  }
    )
  
  .then((response) => {

  
  //Validation Errors
  if(response.data.validatorerror){
  setedithm_id_er(response.data.validatorerror.Hm_id);
  seteditcard_heading_er(response.data.validatorerror.Card_heading);
  seteditcard_text_er(response.data.validatorerror.Card_text);
  seteditbutton_text_er(response.data.validatorerror.Button_text);
  seteditbutton_link_er(response.data.validatorerror.Button_link);
  seteditimage_link_er(response.data.validatorerror.Image_link);
   seterror_msg("Cannot Update Header Section Card !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  
  setedithm_id_er(response.data.hm_id_er);
  seteditcard_heading_er(response.data.card_heading_er);
  seteditcard_text_er(response.data.card_text_er);
  seteditbutton_text_er(response.data.button_text_er);
  seteditbutton_link_er(response.data.button_link_er);
  seteditimage_link_er(response.data.image_link_er);
  seterror_msg("Cannot Update Header Section Card !");
  
  
  }
  
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card").style.display="block";

  
  }
  else{
  seterror_msg("Cannot Update Header Section Card  !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}
/***************** EDIT HOME HEADER FUNCTION END ******************/



/***************** UPDATE HOME PRODUCT FUNCTION START  ******************/
function updatehomeproduct(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setdisplay_product_id_er(" "); setdisplay_rack_id_er(" ");seterror_msg1(" ");
    
    //Fetching Form Data
    let noerror = 0;
    const $display_product_id = document.getElementById("display_product_id").value; 
    const $display_rack_id = display_rack_id; //document.getElementById("display_rack_id").value; 


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("manage-home-product", { 
  Log_id:logid,
  Token:token,
  Product_id:$display_product_id,
  Shop_id:$display_rack_id,
  
  }
    )
  
  .then((response) => {
  
     //Validation Errors
  if(response.data.validatorerror){
  setdisplay_product_id_er(response.data.validatorerror.Product_id);
  setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
  seterror_msg1("Cannot Update Product !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  
  setdisplay_product_id_er(response.data.product_id_er);
  setdisplay_rack_id_er(response.data.shop_id_er);
  seterror_msg1("Cannot Update Product !");
  
  
  }

  //Product Not Found
  if(response.data.error==103){
  
  seterror_msg1("Product Not Found for Product Code! ");

  
  }
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card1").style.display="block";

  
  }
  //failure
  else{
  seterror_msg("Product Not Updated !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}
/***************** UPDATE HOME PRODUCT FUNCTION END  ******************/

/***************** UPDATE TREND PRODUCT FUNCTION START  ******************/
function updatetrendproduct(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    settrend_product_id_er(" "); settrend_rack_id_er(" ");seterror_msg2(" ");
    
    //Fetching Form Data
    let noerror = 0;
    const $trend_product_id = document.getElementById("trend_product_id").value; 
    const $trend_rack_id = trend_rack_id; //document.getElementById("display_rack_id").value; 


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("manage-home-trend-product", { 
  Log_id:logid,
  Token:token,
  Product_id:$trend_product_id,
  Shop_id:$trend_rack_id,
  
  }
    )
  
  .then((response) => {
  
    console.log(response);


  //Validation Errors
  if(response.data.validatorerror){
  settrend_product_id_er(response.data.validatorerror.Product_id);
  settrend_rack_id_er(response.data.validatorerror.Shop_id);
  seterror_msg2("Cannot Update Product !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  
  settrend_product_id_er(response.data.product_id_er);
  settrend_rack_id_er(response.data.shop_id_er);
  seterror_msg2("Cannot Update Product !");
  
  
  }

  //Product Not Found
  if(response.data.error==103){
  
  seterror_msg2("Product Not Found for Product Code! ");

  
  }
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card2").style.display="block";

  
  }
  //failure
  else{
  seterror_msg("Product Not Updated !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}
/***************** UPDATE TREND PRODUCT FUNCTION END  ******************/

    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">

                {/*  SUCCESS CARD START */}
                <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Header Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-home"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

                {/*  SUCCESS CARD 1 START */}
                <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-home"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD 1 END */}

              {/*  SUCCESS CARD 2 START */}
               <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card2">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Trend Product Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-home"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD 2 END */}


            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Home</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                {/*<button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                  <i class="mdi mdi-plus-circle"></i> Add Prodcut </button> */}
              </div>
            </div>

            {/* HEADER SECTION START */}
           <h2>Top Header Section</h2>


           {/* EDIT HEADER SECTION FORM START */}
           <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                        //Setting Variables to empty
                      setedithm_id(" ");seteditcard_heading(" ");seteditcard_text(" ");seteditbutton_text(" ");seteditbutton_link(" ");   
                      seterror_msg(" ");seteditcard_heading_er(" ");seteditcard_text_er(" ");seteditbutton_text_er(" ");seteditbutton_link_er(" ");   
                        }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">EDIT HEADER</h4>
                    <div class="form-sample">
                      <p class="card-description">update header details from here</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Card Heading<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Modern White Jhumka" class="form-control" id="editcard_heading" onChange={updateeditcard_heading} />
                              <code>{editcard_heading_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Card Text<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="JH123" class="form-control" id="editcard_text" onChange={updateeditcard_text} />
                              <code>{editcard_text_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Button Text<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="452145" class="form-control" id="editbutton_text" onChange={updateeditbutton_text} />
                              <code>{editbutton_text_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Button Link<code></code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="1000.00" class="form-control" id="editbutton_link" onChange={updateeditbutton_link} />
                              <code>{editbutton_link_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                         
                        
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Product Image</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" onChange={handleFileChange}  />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{editimage_link_er}</code>
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png and .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>

                          <button class="btn btn-lg btn-primary" onClick={edithomeheader}>Update Header</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* EDIT HEADER SECTION FORM END */}

                </div>



            {/* image card row starts here */}
            <div class="row d-flex justify-content-center">
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+image_link1}  style={{height:"20rem"}} alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">{hm_title1}</p>
                      <button class="btn" onClick={()=>{

                      //Setting Variables to Display in Edit Form
                      document.getElementById("create-form").style.display="block";
                      setedithm_id(hm_id1);
                      document.getElementById("editcard_heading").value=card_heading1;
                      document.getElementById("editcard_text").value=card_text1;
                      document.getElementById("editbutton_text").value=button_text1;
                      document.getElementById("editbutton_link").value=button_link1;

                      }}><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                    </div>
                    <h5 class="font-weight-semibold"><b>{card_heading1}</b></h5>
                    <p>{card_text1}</p>
                    <label class="badge badge-success">{button_text1}</label>
                    <p class="text-primary mt-2" style={{fontSize:".8rem"}}>{button_link1}</p>
                  </div>           
                </div>
              </div>

              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+image_link2}  style={{height:"20rem"}} alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">{hm_title2}</p>
                      <button class="btn" onClick={()=>{

                      //Setting Variables to Display in Edit Form
                      document.getElementById("create-form").style.display="block";
                      setedithm_id(hm_id2);
                      document.getElementById("editcard_heading").value=card_heading2;
                      document.getElementById("editcard_text").value=card_text2;
                      document.getElementById("editbutton_text").value=button_text2;
                      document.getElementById("editbutton_link").value=button_link2;

                      }}><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                    </div>
                    <h5 class="font-weight-semibold"><b>{card_heading2}</b></h5>
                    <p>{card_text2}</p>
                    <label class="badge badge-success">{button_text2}</label>
                    <p class="text-primary mt-2" style={{fontSize:".8rem"}}>{button_link2}</p>
                  </div>           
                </div>
              </div>

              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+image_link3}  style={{height:"20rem"}} alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">{hm_title3}</p>
                      <button class="btn" onClick={()=>{

                    //Setting Variables to Display in Edit Form
                    document.getElementById("create-form").style.display="block";
                    setedithm_id(hm_id3);
                    document.getElementById("editcard_heading").value=card_heading3;
                    document.getElementById("editcard_text").value=card_text3;
                    document.getElementById("editbutton_text").value=button_text3;
                    document.getElementById("editbutton_link").value=button_link3;

                    }}><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                    </div>
                    <h5 class="font-weight-semibold"><b>{card_heading3}</b></h5>
                    <p>{card_text3}</p>
                    <label class="badge badge-success">{button_text3}</label>
                    <p class="text-primary mt-2" style={{fontSize:".8rem"}}>{button_link3}</p>
                  </div>           
                </div>
              </div>

              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+image_link4}  style={{height:"20rem"}} alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">{hm_title4}</p>
                      <button class="btn" onClick={()=>{

                      //Setting Variables to Display in Edit Form
                      document.getElementById("create-form").style.display="block";
                      setedithm_id(hm_id4);
                      document.getElementById("editcard_heading").value=card_heading4;
                      document.getElementById("editcard_text").value=card_text4;
                      document.getElementById("editbutton_text").value=button_text4;
                      document.getElementById("editbutton_link").value=button_link4;

                    }}><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                    </div>
                    <h5 class="font-weight-semibold"><b>{card_heading4}</b></h5>
                    <p>{card_text4}</p>
                    <label class="badge badge-success">{button_text4}</label>
                    <p class="text-primary mt-2" style={{fontSize:".8rem"}}>{button_link4}</p>
                  </div>           
                </div>
              </div>

              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+image_link5}  style={{height:"20rem"}} alt="" />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">{hm_title5}</p>
                      <button class="btn" onClick={()=>{

                      //Setting Variables to Display in Edit Form
                      document.getElementById("create-form").style.display="block";
                      setedithm_id(hm_id5);
                      document.getElementById("editcard_heading").value=card_heading5;
                      document.getElementById("editcard_text").value=card_text5;
                      document.getElementById("editbutton_text").value=button_text5;
                      document.getElementById("editbutton_link").value=button_link5;

                    }}><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                    </div>
                    <h5 class="font-weight-semibold"><b>{card_heading5}</b></h5>
                    <p>{card_text5}</p>
                    <label class="badge badge-success">{button_text5}</label>
                    <p class="text-primary mt-2" style={{fontSize:".8rem"}}>{button_link5}</p>
                  </div>           
                </div>
              </div>

            </div>
        
            {/* HEADER SCETION END */}
            <hr/>



            {/* PRODUCTS SECTION START */}
            <h2>Products Section</h2>


             {/* EDIT PRODUCT SECTION START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-product-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-product-form").style.display="none";
                         sethp_id1(" ");document.getElementById("display_product_id").value=" ";
                      
                        }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">EDIT PRODUCT</h4>
                    <div class="form-sample">
                      <p class="card-description">update product</p>
                      <code>{error_msg1}</code>
                      <div class="row mt-2" >
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Id<code>*</code></label>
                            <div class="col-sm-9">
                             <input type="text" placeholder="123" class="form-control" id="display_product_id" onChange={updatedisplay_product_id} />      
                              <code>{display_product_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-lg btn-primary" onClick={updatehomeproduct}>Update Product</button>
                          </div>
                          </div>
      
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* EDIT PRODUCT SECTION END */}

                </div>
            <div class="row">

              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link1} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id1} ({hproduct_id1})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title1}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating1<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating1<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating1<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating1<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating1<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label1}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp1}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price1}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id1;
                        setdisplay_rack_id(hp_id1);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


                <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link2} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id2} ({hproduct_id2})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title2}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating2<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating2<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating2<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating2<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating2<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label2}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp2}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price2}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id2;
                        setdisplay_rack_id(hp_id2);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link3} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id3} ({hproduct_id3})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title3}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating3<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating3<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating3<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating3<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating3<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label3}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp3}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price3}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id3;
                        setdisplay_rack_id(hp_id3);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>

              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link4} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id4} ({hproduct_id4})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title4}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating4<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating4<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating4<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating4<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating4<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label4}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp4}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price4}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id4;
                        setdisplay_rack_id(hp_id4);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>

              <div class="row">
              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link5} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id5} ({hproduct_id5})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title5}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating5<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating5<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating5<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating5<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating5<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label5}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp5}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price5}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id5;
                        setdisplay_rack_id(hp_id5);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link6} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id6} ({hproduct_id6})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title6}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating6<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating6<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating6<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating6<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating6<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label6}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp6}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price6}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id6;
                        setdisplay_rack_id(hp_id6);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link7} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id1} ({hproduct_id7})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title7}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating7<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating7<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating7<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating7<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating7<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label7}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp7}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price7}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id7;
                        setdisplay_rack_id(hp_id7);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


              <div class="col-sm-3 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+hproduct_image_link8} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{hp_id8} ({hproduct_id8})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {hproduct_title8}</h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating8<5}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating8<4}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating8<3}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating8<2}></i><i class="mdi mdi-star star-color pe-1" hidden={hproduct_rating8<1}></i>
                      </p>
                      <p class="badge badge-success mt-2">{hproduct_label8}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {hproduct_mrp8}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {hproduct_price8}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form").style.display="block";
                        document.getElementById("display_product_id").value=hproduct_id8;
                        setdisplay_rack_id(hp_id8);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>

            </div>
            </div>
        
        {/* PRODUCTS SECTION END */}


        {/* BANNER SECTION START */}
        <div class="row">
              <div class="col-sm-12 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src={imgpath+bimage_link} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark" style={{textAlign:"left"}}>
                    <h5 class="text-muted font-13 mb-0">{bcard_text}</h5>
                    <h3 class="font-weight-semibold"><b>{bcard_heading}</b></h3>
                    {/*<h5 class="text-muted font-13 mb-0">Sub Heading 2</h5>*/}
                    <p class="badge badge-success mt-2">{bbutton_text}</p>
                    <p class="text-primary">{bbutton_link}</p>
                    <button class="btn" onClick={()=>{

                    //Setting Variables to Display in Edit Form
                    document.getElementById("create-form").style.display="block";
                    setedithm_id(bhm_id);
                    document.getElementById("editcard_heading").value=bcard_heading;
                    document.getElementById("editcard_text").value=bcard_text;
                    document.getElementById("editbutton_text").value=bbutton_text;
                    document.getElementById("editbutton_link").value=bbutton_link;

                    }}><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                    </div>
                </div>
              </div>
        </div>

       {/* BANNER SECTION END */}


       {/* TRENDS SECTION START */}
       <h2>TRENDS SECTION</h2>

             {/* EDIT TREND PRODUCT SECTION START */}
             <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-product-form1" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-product-form1").style.display="none";
                         sethp_id1(" ");document.getElementById("trend_product_id").value=" ";
                      
                        }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">EDIT PRODUCT</h4>
                    <div class="form-sample">
                      <p class="card-description">update product</p>
                      <code>{error_msg2}</code>
                      <div class="row mt-2" >
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Id<code>*</code></label>
                            <div class="col-sm-9">
                             <input type="text" placeholder="123" class="form-control" id="trend_product_id" onChange={updatetrend_product_id} />      
                              <code>{trend_product_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                        <button class="btn btn-lg btn-primary" onClick={updatetrendproduct}>Update Product</button>
                          </div>
                          </div>
      
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* EDIT TREND PRODUCT SECTION END */}
                   </div>

       {/* TREND SECTION 1 START */}
       <div class="card">
       <div class="d-flex justify-content-between font-weight-semibold">
            <div>
                <h4 style={{padding:"1rem"}}>#{trend_id1} {trend_title1}</h4>
            </div>
            <div>
               {/*} <button class="btn"><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button> */}
            </div>
        </div>
        </div>

             {/* image card row starts here */}
             <div class="row">
              <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link1} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id1} ({tp_product_id1})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title1} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating1<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating1<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating1<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating1<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating1<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label1}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp1}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price1}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id1;
                        settrend_rack_id(tp_id1);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


              <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link2} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id2} ({tp_product_id2})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title2} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating2<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating2<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating2<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating2<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating2<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label2}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp2}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price2}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id2;
                        settrend_rack_id(tp_id2);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


              <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link3} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id3} ({tp_product_id3})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title3} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating3<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating3<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating3<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating3<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating3<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label3}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp3}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price3}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id3;
                        settrend_rack_id(tp_id3);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>


              
            </div>
       {/* TREND SECTION 1 END */}

       {/* TREND SECTION 2 START */}
       <div class="card">
       <div class="d-flex justify-content-between font-weight-semibold">
            <div>
                <h4 style={{padding:"1rem"}}>#{trend_id4} {trend_title4}</h4>
            </div>
            <div>
                <button class="btn"><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
            </div>
        </div>
        </div>

             {/* image card row starts here */}
             <div class="row">
             



                  <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link4} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id4} ({tp_product_id4})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title4} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating4<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating4<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating4<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating4<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating4<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label4}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp4}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price4}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id4;
                        settrend_rack_id(tp_id4);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>



              <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link5} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id1} ({tp_product_id5})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title5} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating5<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating5<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating5<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating5<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating5<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label5}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp5}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price5}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id5;
                        settrend_rack_id(tp_id5);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>

              <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link6} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id6} ({tp_product_id6})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title6} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating6<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating6<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating6<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating6<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating6<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label6}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp6}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price6}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id6;
                        settrend_rack_id(tp_id6);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>

              
            </div>
       {/* TREND SECTION 2 END */}

       {/* TREND SECTION 3 START */}
       <div class="card">
       <div class="d-flex justify-content-between font-weight-semibold">
            <div>
                <h4 style={{padding:"1rem"}}>${trend_id7} {trend_title7}</h4>
            </div>
            <div>
                <button class="btn"><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
            </div>
        </div>
        </div>

             {/* image card row starts here */}
             <div class="row">
            


             <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link7} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id7} ({tp_product_id7})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title7} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating7<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating7<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating7<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating7<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating7<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label7}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp7}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price7}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id7;
                        settrend_rack_id(tp_id7);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>

              <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link8} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id8} ({tp_product_id8})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title8} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating8<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating8<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating8<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating8<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating8<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label8}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp8}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price8}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id8;
                        settrend_rack_id(tp_id8);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>

              <div class="col-sm-4 stretch-card grid-margin" id="resp-3">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100"  src={imgpath+tp_product_image_link9} alt="" style={{height:"20rem"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0">#{tp_id9} ({tp_product_id9})</p>
                      <div>
                     
                      </div>
                    </div>
                    <h5 class="font-weight-semibold"> {tp_product_title9} </h5>
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <div><p class="mb-0">
                      <i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating9<5}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating9<4}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating9<3}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating9<2}></i><i class="mdi mdi-star star-color pe-1" hidden={tp_product_rating9<1}></i>
                       </p>
                      <p class="badge badge-success mt-2">{tp_product_label9}</p>
                      </div>

                        <div><p class="mb-0"><b>MRP: </b>₹ {tp_product_mrp9}/-</p>
                        <p class="mb-0 mt-2"><b>Price: </b>₹ {tp_product_price9}/-</p>
                        </div>
                        </div>
                   

                        <div class="d-flex justify-content-end font-weight-semibold">
                        <div></div>
                        <div>
                        <button class="btn" onClick={()=>{
                        //Show Update Product Dialog
                        document.getElementById("edit-product-form1").style.display="block";
                        document.getElementById("trend_product_id").value=tp_product_id9;
                        settrend_rack_id(tp_id9);

                        }}                      
                        ><i class="mdi mdi-pencil-box text-warning text-warning" style={{fontSize:"1.6rem"}}></i></button>
                        </div>
                        </div>
                  </div>
                  
                </div>
              </div>
              
            </div>
       {/* TREND SECTION 3 END */}

       {/* TRENDS SECTION END */}


          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_home;



