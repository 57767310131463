import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';





/* Import home from File */




import Home from './home';
import Home1 from './Template/home1';
import Shop1 from './Template/shop';
import Cart1 from './Template/cart1';
import Checkout1 from './Template/checkout1';
import Contact1 from './Template/contact1';
import Productdetails1 from './Template/productdetails1';
import Blog1 from './Template/blog1';
import Blogdetails1 from './Template/blogdetails';
import Giftboxes_shop from './Shops/giftboxes_shop';
import Waaliyan_shop from './Shops/waaliyan_shop';
import Rings_shop from './Shops/rings_shop';
import Bracelets_shop from './Shops/bracelets_shop';
import Necklace_shop from './Shops/necklace_shop';
import Home_shop from './Shops/home_shop';
import Contact from './Supportingfiles/contact';
import Productdetails from './Shops/productdetails';
import Shopping_cart from './Supportingfiles/shopping_cart';
import Wishlist from './Supportingfiles/wishlist';
import Checkout from './Supportingfiles/checkout';
import Register from './Supportingfiles/register';
import Login from './Supportingfiles/login';
import Adminpanel from './Template/adminpanel';
import Admin_panel from './Admin/admin_panel';
import Admin_registrations from './Admin/Mainpages/registrations';
import Admin_logins from './Admin/Mainpages/logins';
import Admin_orders from './Admin/Mainpages/orders';
import Admin_payments from './Admin/Mainpages/payments';
import Admin_products from './Admin/Mainpages/products';
import Admin_inventory from './Admin/Mainpages/inventory';
import Admin_contact from './Admin/Mainpages/contact';
import Admin_reviews from './Admin/Mainpages/reviews';
import Admin_offers from './Admin/Mainpages/offers';
import Admin_cart from './Admin/Mainpages/cart';
import Admin_wishlist from './Admin/Mainpages/wishlist';
import Admin_shop from './Admin/Pages/shop';
import Admin_giftboxes from './Admin/Pages/giftboxes';
import Admin_waaliyan from './Admin/Pages/waaliyan';
import Admin_rings from './Admin/Pages/rings';
import Admin_bracelets from './Admin/Pages/bracelets';
import Admin_necklace from './Admin/Pages/necklace';
import Admin_home from './Admin/Pages/home';
import Admin_login from './Admin/Mainpages/login';
import Admin_edit_product from './Admin/Mainpages/editproduct';
import Admin_view_wishlist from './Admin/Mainpages/wishlist_view';
import Admin_view_cart from './Admin/Mainpages/cart_view';
import Privacypolicy from './Supportingfiles/privacypolicy';
import Termsandconditions from './Supportingfiles/termsandconditions';
import Refundpolicy from './Supportingfiles/refundpolicy';
import Shippingpolicy from './Supportingfiles/shippingpolicy';
import Admin_view_order from './Admin/Mainpages/view_order';
import Admin_filters from './Admin/Mainpages/filters';
import Homeheadertest from './Template/homeheadertest';




function Routestosite(){

    return(
<>
<Router>
   <Routes>

    /* WEBSITE WHATJHUMKA.IN */
   <Route exact path='/' element={<Home />} />
   <Route exact path='/home' element={<Home />} />
   <Route exact path='/home1' element={<Home1 />} />
   <Route exact path='/giftboxes' element={<Giftboxes_shop />} />
   <Route exact path='/waaliyan' element={<Waaliyan_shop />} />
   <Route exact path='/rings' element={<Rings_shop />} />
   <Route exact path='/bracelets' element={<Bracelets_shop />} />
   <Route exact path='/necklace' element={<Necklace_shop />} />
   <Route exact path='/shop' element={<Home_shop />} />
   <Route exact path='/contact' element={<Contact />} />
   <Route exact path='/product-details' element={<Productdetails />} />
   <Route exact path='/shopping-cart' element={<Shopping_cart />} />
   <Route exact path='/wishlist' element={<Wishlist />} />
   <Route exact path='/checkout' element={<Checkout />} />
   <Route exact path='/register' element={<Register />} />
   <Route exact path='/login' element={<Login />} />

   /* ADMIN PANEL WHATJHUMKA.IN */
   <Route exact path='/admin-login' element={<Admin_login />} />
   <Route exact path='/adminpanel' element={<Admin_panel />} />
   <Route exact path='/admin-registrations' element={<Admin_registrations />} />
   <Route exact path='/admin-logins' element={<Admin_logins />} />
   <Route exact path='/admin-orders' element={<Admin_orders />} />
   <Route exact path='/admin-payments' element={<Admin_payments />} />
   <Route exact path='/admin-products' element={<Admin_products />} />
   <Route exact path='/admin-edit-product' element={<Admin_edit_product />} />
   <Route exact path='/admin-inventory' element={<Admin_inventory />} />
   <Route exact path='/admin-contact' element={<Admin_contact />} />
   <Route exact path='/admin-reviews' element={<Admin_reviews />} />
   <Route exact path='/admin-offers' element={<Admin_offers />} />
   <Route exact path='/admin-cart' element={<Admin_cart />} />
   <Route exact path='/admin-wishlist' element={<Admin_wishlist />} />
   <Route exact path='/admin-view-wishlist' element={<Admin_view_wishlist />} />
   <Route exact path='/admin-view-cart' element={<Admin_view_cart />} />
   <Route exact path='/admin-view-order' element={<Admin_view_order />} />
   <Route exact path='/admin-filters' element={<Admin_filters />} />


   /* SHOP MANAGEMENT */
   <Route exact path='/admin-home' element={<Admin_home />} />
   <Route exact path='/admin-shop' element={<Admin_shop />} />
   <Route exact path='/admin-giftboxes' element={<Admin_giftboxes />} />
   <Route exact path='/admin-waaliyan' element={<Admin_waaliyan />} />
   <Route exact path='/admin-rings' element={<Admin_rings />} />
   <Route exact path='/admin-bracelets' element={<Admin_bracelets />} />
   <Route exact path='/admin-necklace' element={<Admin_necklace />} />

   /* TERMS AND POLICIES */
   <Route exact path='/privacy-policy' element={<Privacypolicy />} />
   <Route exact path='/termsandconditions' element={<Termsandconditions />} />
   <Route exact path='/exchange-policy' element={<Refundpolicy />} />
   <Route exact path='/shipping-policy' element={<Shippingpolicy />} />

   /* TEMPLATES */
   <Route exact path='/home1' element={<Home1 />} />
   <Route exact path='/shop1' element={<Shop1 />} />
   <Route exact path='/cart1' element={<Cart1 />} />
   <Route exact path='/checkout1' element={<Checkout1 />} />
   <Route exact path='/contact1' element={<Contact1 />} />
   <Route exact path='/productdetails1' element={<Productdetails1 />} />
   <Route exact path='/blog1' element={<Blog1 />} />
   <Route exact path='/blogdetails1' element={<Blogdetails1 />} />
   <Route exact path='/adminpanel1' element={<Adminpanel />} />



   <Route exact path='/home-header-test' element={<Homeheadertest />} />

   </Routes>
   </Router>
</>
    );
    


}

export default Routestosite;