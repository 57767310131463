import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';



function Refundpolicy(){

  


return(
<>
<div style={{textAlign:"left", padding:"1.6rem"}} >
<p><h1>EXCHANGE POLICY</h1></p>
<p>Last Updated: May 16, 2024</p>
<p>Thank you for shopping at&nbsp;<strong>whatjhumka.in</strong></p>
<p>If, for any reason, You are not completely satisfied with a purchase, we invite You to review our Policy.</p>
<p>The following terms are applicable for any products that you purchased with us.</p>
<p>At WhatJhumka, we strive to ensure your satisfaction with every purchase. In the event that you are not completely satisfied with your jewellery item, we offer a hassle-free exchange policy. Please read the following guidelines carefully:</p>
<ol>
<li><strong>Eligibility for Exchange:</strong>
<ul>
<li>Items eligible for exchange must be in their original condition, unworn, and undamaged.</li>
<li>Exchange requests must be initiated within 14 days from the date of delivery.</li>
</ul>
</li>
<li><strong>Exclusions:</strong>
<ul>
<li>Customized or personalized jewellery items cannot be exchanged, unless there is a manufacturing defect or an error on our part.</li>
<li>Items purchased during special promotions or clearance sales may not be eligible for exchange.</li>
</ul>
</li>
<li><strong>Exchange Process:</strong>
<ul>
<li>To initiate an exchange, please contact our customer service team at (Email- Whatjhumka2024@gmail.com , Mobile- +91 92389 40060) within the stipulated timeframe.</li>
<li>Provide your order number and details of the item(s) you wish to exchange.</li>
<li>Our customer service team will guide you through the exchange process and provide you with a return authorization (RA) number, along with instructions for returning the item(s).</li>
</ul>
</li>
<li><strong>Return Shipping:</strong>
<ul>
<li>Customers are responsible for the shipping costs associated with returning the item(s) for exchange.</li>
<li>We recommend using a trackable shipping service and retaining proof of postage for your records.</li>
</ul>
</li>
<li><strong>Inspection and Approval:</strong>
<ul>
<li>Upon receiving the returned item(s), our quality assurance team will inspect them to ensure they meet the eligibility criteria for exchange.</li>
<li>If the item(s) pass inspection, we will process the exchange and dispatch the replacement item(s) as soon as possible.</li>
</ul>
</li>
<li><strong>Exchange Options:</strong>
<ul>
<li>Customers can choose to exchange the item(s) for another product of equal or lesser value. If the replacement item is of lesser value, the price difference will be refunded via the original payment method within 3 to 4 days.</li>
<li>If the replacement item is of greater value, customers are required to pay the price difference before the exchange is processed.</li>
</ul>
</li>
<li><strong>Refunds:</strong>
<ul>
<li>We do not offer refunds for exchanged items. However, if you are dissatisfied with the replacement item(s), please refer to our Returns Policy for further assistance.</li>
</ul>
</li>
<li><strong>Contact Us:</strong>
<ul>
<li>If you have any questions or concerns regarding our exchange policy, please feel free to contact our customer service team at
<br/>
<br/>
<b> [customer service Email- Whatjhumka2024@gmail.com , Mobile- +91 92389 40060].</b></li>
</ul>
</li>
</ol>
<p>&nbsp;</p>

</div>
      {/* partial */}
</>
);


}

export default Refundpolicy;