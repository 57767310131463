import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");



function Admin_offers(){

  
 
  const[data,setData] = useState([]);//SetData Variable

  //Variables for pagination
 const [currentPage, setCurrentPage] = useState(1);
 const [pageNumber, setpageNumber] = useState(1);
 
 //Error Variable
 const[errorvar,seterrorvar] = useState();
 


 useEffect(()=>{
   
  getData();
  
  },[])

  

  
  //******************* GET DATA FUNCTION START  ********************//
  function getData(){
  
    //Checking if user loggedin
   
   axios.post("get-coupon-codes", {
    Log_id:logid,
    Token:token,
  
     //Code Edits for pagination
     'page' : pageNumber
  
     })
   .then((response) => {

///    console.log(response.data);

  
    //Success Data Fetched
    if(response.data.successvar==1){
  
      //Setting Data
      setData(response.data.data.data);
  
    }
    //Cannot Fetch Data
   else{
    alert("Cannot Fetch Data");
   }
   
  
   
   });
   
   
  }

 
//FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);


//offer_name
const[offer_name,setoffer_name]=useState();const updateoffer_name = (event) => { setoffer_name(event.target.value);}; const[offer_name_er,setoffer_name_er]=useState();  
//coupon_code
const[coupon_code,setcoupon_code]=useState();const updatecoupon_code = (event) => { setcoupon_code(event.target.value);}; const[coupon_code_er,setcoupon_code_er]=useState();  
//start_date
const[start_date,setstart_date]=useState();const updatestart_date = (event) => { setstart_date(event.target.value);}; const[start_date_er,setstart_date_er]=useState();  
//end_date
const[end_date,setend_date]=useState();const updateend_date = (event) => { setend_date(event.target.value);}; const[end_date_er,setend_date_er]=useState();  
//discount_per
const[discount_per,setdiscount_per]=useState();const updatediscount_per = (event) => { setdiscount_per(event.target.value);}; const[discount_per_er,setdiscount_per_er]=useState();  
//discount_amt
const[discount_amt,setdiscount_amt]=useState();const updatediscount_amt = (event) => { setdiscount_amt(event.target.value);}; const[discount_amt_er,setdiscount_amt_er]=useState();  
//coupon_limit
const[coupon_limit,setcoupon_limit]=useState();const updatecoupon_limit = (event) => { setcoupon_limit(event.target.value);}; const[coupon_limit_er,setcoupon_limit_er]=useState();  
//coupon_details
const[coupon_details,setcoupon_details]=useState();const updatecoupon_details = (event) => { setcoupon_details(event.target.value);}; const[coupon_details_er,setcoupon_details_er]=useState();  



//eoffer_name
const[eoffer_name,seteoffer_name]=useState();const updateeoffer_name = (event) => { seteoffer_name(event.target.value);}; const[eoffer_name_er,seteoffer_name_er]=useState();  
//ecoupon_code
const[ecoupon_code,setecoupon_code]=useState();const updateecoupon_code = (event) => { setecoupon_code(event.target.value);}; const[ecoupon_code_er,setecoupon_code_er]=useState();  
//estart_date
const[estart_date,setestart_date]=useState();const updateestart_date = (event) => { setestart_date(event.target.value);}; const[estart_date_er,setestart_date_er]=useState();  
//eend_date
const[eend_date,seteend_date]=useState();const updateeend_date = (event) => { seteend_date(event.target.value);}; const[eend_date_er,seteend_date_er]=useState();  
//ediscount_per
const[ediscount_per,setediscount_per]=useState();const updateediscount_per = (event) => { setediscount_per(event.target.value);}; const[ediscount_per_er,setediscount_per_er]=useState();  
//ediscount_amt
const[ediscount_amt,setediscount_amt]=useState();const updateediscount_amt = (event) => { setediscount_amt(event.target.value);}; const[ediscount_amt_er,setediscount_amt_er]=useState();  
//ecoupon_limit
const[ecoupon_limit,setecoupon_limit]=useState();const updateecoupon_limit = (event) => { setecoupon_limit(event.target.value);}; const[ecoupon_limit_er,setecoupon_limit_er]=useState();  
//ecoupon_details
const[ecoupon_details,setecoupon_details]=useState();const updateecoupon_details = (event) => { setecoupon_details(event.target.value);}; const[ecoupon_details_er,setecoupon_details_er]=useState();  
//estatus
const[estatus,setestatus]=useState();const updateestatus = (event) => { setestatus(event.target.value);}; const[estatus_er,setestatus_er]=useState();  
//eoffer_id
const[eoffer_id,seteoffer_id]=useState();  //const updateeoffer_id = (event) => { seteoffer_id(event.target.value);}; const[eoffer_id_er,seteoffer_id_er]=useState();  


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
const[eerror_msg,seteerror_msg]=useState();


/***************** CREATE COUPON CODE FUNCTION START  ******************/
function createcouponcode(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


  //Setting Error Variables
  setoffer_name_er(" ");setcoupon_code_er(" ");setstart_date_er(" ");setend_date_er(" ");setdiscount_per_er(" ");setdiscount_amt_er(" ");setcoupon_limit_er(" ");setcoupon_details_er(" ");seterror_msg(" ");
    
//Fetching Form Data
let noerror = 0;
const $offer_name = document.getElementById("offer_name").value; 
const $coupon_code = document.getElementById("coupon_code").value; 
const $start_date = document.getElementById("start_date").value; 
const $end_date = document.getElementById("end_date").value; 
const $discount_per = document.getElementById("discount_per").value; 
const $discount_amt = document.getElementById("discount_amt").value; 
const $coupon_limit = document.getElementById("coupon_limit").value; 
const $coupon_details = document.getElementById("coupon_details").value; 

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("create-coupon-code", { 
  Log_id:logid,
  Token:token,
  Offer_name:$offer_name,
  Coupon_code:$coupon_code,
  Start_date:moment($start_date).format('YYYY-MM-DD'),
  End_date:moment($end_date).format('YYYY-MM-DD'),
  Discount_per:$discount_per,
  Discount_amt:$discount_amt,
  Coupon_limit:$coupon_limit,
  Coupon_details:$coupon_details,

  }
    )
  
  .then((response) => {

  
  //Validation Errors
  if(response.data.validatorerror){
  setoffer_name_er(response.data.validatorerror.Offer_name);
  setcoupon_code_er(response.data.validatorerror.Coupon_code);
  setstart_date_er(response.data.validatorerror.Start_date);
  setend_date_er(response.data.validatorerror.End_date);
  setdiscount_per_er(response.data.validatorerror.Discount_per);
  setdiscount_amt_er(response.data.validatorerror.Discount_amt);
  setcoupon_limit_er(response.data.validatorerror.Coupon_limit);
  setcoupon_details_er(response.data.validatorerror.Coupon_details);
  seterror_msg("Cannot Create Coupon Code !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  setoffer_name_er(response.data.offer_name_er);
  setcoupon_code_er(response.data.coupon_code_er);
  setstart_date_er(response.data.start_date_er);
  setend_date_er(response.data.end_date_er);
  setdiscount_per_er(response.data.discount_per_er);
  setdiscount_amt_er(response.data.discount_amt_er);
  setcoupon_limit_er(response.data.coupon_limit_er);
  setcoupon_details_er(response.data.product_details_er);
  seterror_msg("Cannot Create Coupon Code !");
  
  
  }
  
  //Mentor Already Exists
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  
  
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card").style.display="block";

  
  }
  //failure
  if(response.data.successvar==6){
  seterror_msg("Cannot Create Coupon Code !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** CREATE COUPON CODE FUNCTION END  ******************/




/***************** EDIT COUPON CODE FUNCTION START  ******************/
function editcouponcode(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(eoffer_id>0)){


  //Setting Error Variables
  seteoffer_name_er(" ");setecoupon_code_er(" ");setestart_date_er(" ");seteend_date_er(" ");setediscount_per_er(" ");setediscount_amt_er(" ");setecoupon_limit_er(" ");setecoupon_details_er(" ");seteerror_msg(" ");setestatus(" ");
    
//Fetching Form Data
let noerror = 0;
const $offer_name = document.getElementById("eoffer_name").value; 
const $coupon_code = document.getElementById("ecoupon_code").value; 
const $start_date = document.getElementById("estart_date").value; 
const $end_date = document.getElementById("eend_date").value; 
const $discount_per = document.getElementById("ediscount_per").value; 
const $discount_amt = document.getElementById("ediscount_amt").value; 
const $coupon_limit = document.getElementById("ecoupon_limit").value; 
const $coupon_details = document.getElementById("ecoupon_details").value; 
const $status = document.getElementById("estatus").value; 
//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("edit-coupon-code", { 
  Log_id:logid,
  Token:token,
  Offer_name:$offer_name,
  Coupon_code:$coupon_code,
  Start_date:moment($start_date).format('YYYY-MM-DD'),
  End_date:moment($end_date).format('YYYY-MM-DD'),
  Discount_per:$discount_per,
  Discount_amt:$discount_amt,
  Coupon_limit:$coupon_limit,
  Coupon_details:$coupon_details,
  Status:$status,
  Offer_id:eoffer_id

  }
    )
  
  .then((response) => {

    
    console.log(response);
  
  //Validation Errors
  if(response.data.validatorerror){
  seteoffer_name_er(response.data.validatorerror.Offer_name);
  setecoupon_code_er(response.data.validatorerror.Coupon_code);
  setestart_date_er(response.data.validatorerror.Start_date);
  seteend_date_er(response.data.validatorerror.End_date);
  setediscount_per_er(response.data.validatorerror.Discount_per);
  setediscount_amt_er(response.data.validatorerror.Discount_amt);
  setecoupon_limit_er(response.data.validatorerror.Coupon_limit);
  setecoupon_details_er(response.data.validatorerror.Coupon_details);
  setestatus_er(response.data.validatorerror.Status);
  seteerror_msg("Cannot Create Coupon Code !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  seteoffer_name_er(response.data.offer_name_er);
  setecoupon_code_er(response.data.coupon_code_er);
  setestart_date_er(response.data.start_date_er);
  seteend_date_er(response.data.end_date_er);
  setediscount_per_er(response.data.discount_per_er);
  setediscount_amt_er(response.data.discount_amt_er);
  setcoupon_limit_er(response.data.coupon_limit_er);
  setcoupon_details_er(response.data.product_details_er);
  setestatus_er(response.data.status_er);
  seterror_msg("Cannot Create Coupon Code !");
  
  
  }
  
  //Mentor Already Exists
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  
  
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card1").style.display="block";

  
  }
  //failure
  if(response.data.successvar==6){
  seterror_msg("Cannot Update Coupon Code !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** EDIT COUPON CODE FUNCTION END  ******************/







    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">

          
             {/*  SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Coupon Code Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-offers"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

              {/*  SUCCESS CARD 1 START */}
             <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Coupon Code Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-offers"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD 1 END */}


            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{
                      const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}>
                  <i class="mdi mdi-plus-circle"></i> New Coupon Code</button> 
              </div>
            </div>


                {/* CREATE COUPON CODE START */}
                <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">CREATE COUPON CODE</h4>
                    <div class="form-sample">
                      <p class="card-description">create new coupon code here</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Offer Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="My Offer" id="offer_name" onChange={updateoffer_name} />
                              <code>{offer_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="coupon_code" placeholder="MYCODE00" onChange={updatecoupon_code} style={{padding:"1rem"}} />
                              <code>{coupon_code_er}</code>
                            </div>
                          </div>
                        </div>
                          </div>
                      <div class="row">            
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Start Date<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" id="start_date" onChange={updatestart_date} />
                              <code>{start_date_er}</code>
                            </div>
                          </div>
                          </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">End Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="end_date" onChange={updateend_date} />
                              <code>{end_date_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="10.00" class="form-control" id="discount_per" onChange={updatediscount_per} />
                              <code>{discount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount Amount<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="1000.00" class="form-control" id="discount_amt" onChange={updatediscount_amt} />
                              <code>{discount_amt_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Limit<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="1250" class="form-control" id="coupon_limit" onChange={updatecoupon_limit} />
                              <code>{coupon_limit_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Details<code>*</code></label>
                            <div class="col-sm-9">
                         {/*    */}
                            <input type="text" placeholder="add coupon details here" class="form-control" id="coupon_details" onChange={updatecoupon_details} />

                              <code>{coupon_details_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                      
                   
                          <button class="btn btn-lg btn-primary" onClick={createcouponcode}>Create New Coupon Code</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* CREATE COUPON CODE END */}
                </div>

               {/* EDIT COUPON CODE START */}
               <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">EDIT COUPON CODE</h4>
                    <div class="form-sample">
                      <p class="card-description">edit coupon code here</p>
                      <code>{eerror_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Offer Id<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control"  value={eoffer_id} />                            
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control" id="estatus" onChange={updateestatus} style={{padding:"1rem"}} >
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{estatus_er}</code>
                            </div>
                          </div>
                        </div>
                          </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Offer Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="My Offer" id="eoffer_name" onChange={updateeoffer_name} />
                              <code>{eoffer_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="ecoupon_code" placeholder="MYCODE00" onChange={updateecoupon_code} style={{padding:"1rem"}} />
                              <code>{ecoupon_code_er}</code>
                            </div>
                          </div>
                        </div>
                          </div>
                      <div class="row">            
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Start Date<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" id="estart_date" onChange={updateestart_date} />
                              <code>{estart_date_er}</code>
                            </div>
                          </div>
                          </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">End Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="eend_date" onChange={updateeend_date} />
                              <code>{eend_date_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="10.00" class="form-control" id="ediscount_per" onChange={updateediscount_per} />
                              <code>{ediscount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount Amount<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="1000.00" class="form-control" id="ediscount_amt" onChange={updateediscount_amt} />
                              <code>{ediscount_amt_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Limit<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="1250" class="form-control" id="ecoupon_limit" onChange={updateecoupon_limit} />
                              <code>{ecoupon_limit_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Details<code>*</code></label>
                            <div class="col-sm-9">
                         {/*    */}
                            <input type="text" placeholder="add coupon details here" class="form-control" id="ecoupon_details" onChange={updateecoupon_details} />

                              <code>{ecoupon_details_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                      
                   
                         <button class="btn btn-lg btn-primary" onClick={editcouponcode}>Edit Coupon Code</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* EDIT COUPON CODE END */}
                </div>
        
         
        
            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Manage Offers/ Coupon Codes</h4>
                    <p class="card-description">manage your coupon codes here
                    </p>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th><b>Offer Id</b></th>
                            <th><b>Coupon Code</b></th>
                            <th><b>Coupon Name</b></th>
                            <th><b>Start Date</b></th>
                            <th><b>Valid Till</b></th>
                            <th><b>Limit</b></th>
                            <th><b>Details</b></th>
                            <th><b>Dis %</b></th>
                            <th><b>Dis Amount</b></th>
                            <th><b>Date_created</b></th>
                            <th><b>Time_created</b></th>
                            <th><b>Status</b></th>
                       {/*}     <th><b>Remarks</b></th>*/}
                            <th><b>Manage</b></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr>
                            <td>{curElm.Offer_id}</td>
                            <td>{curElm.Coupon_code}</td>
                            <td>{curElm.Offer_name}</td>
                            <td>{curElm.Start_date}</td>
                            <td>{curElm.Valid_till}</td>
                            <td>{curElm.Coupon_limit}</td>
                            <td>{curElm.Coupon_details}</td>
                            <td>{curElm.Discount_per}</td>
                            <td>{curElm.Discount_amt}</td>
                            <td>{curElm.Date_created}</td>
                            <td>{curElm.Time_created}</td>
                           <td>
                              <label class="badge badge-success" hidden={curElm.Status=="inactive"}>{curElm.Status}</label>
                              <label class="badge badge-danger" hidden={curElm.Status=="active"}>{curElm.Status}</label>
                            </td>
                       {/*}       <td>no remarks</td> */}
                           
                            <td><button class="btn" onClick={()=>{ 
                              //Setting Values for Edit
                              seteoffer_id(curElm.Offer_id); 
                              document.getElementById("estatus").value=(curElm.Status);
                              document.getElementById("eoffer_name").value=(curElm.Offer_name);
                              document.getElementById("ecoupon_code").value=(curElm.Coupon_code);
                              document.getElementById("estart_date").value=(curElm.Start_date);
                              document.getElementById("eend_date").value=(curElm.Valid_till);
                              document.getElementById("ediscount_per").value=(curElm.Discount_per);
                              document.getElementById("ediscount_amt").value=(curElm.Discount_amt);
                              document.getElementById("ecoupon_limit").value=(curElm.Coupon_limit);
                              document.getElementById("ecoupon_details").value=(curElm.Coupon_details);

                              document.getElementById("edit-form").style.display="block";
                               }}><i class="mdi mdi-pencil-box text-warning text-warning"></i></button></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                 
                        </tbody>
                      </table>

                         {/* PAGINATION SECTION START */}
                         <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690080.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690084.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                    
                    </div>
                  </div>
                </div>
              </div>

          </div>

          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_offers;



