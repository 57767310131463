import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import axios from '../axios.js';
import './responsivecss.css';

  //Checking Sessions
  // Access value associated with the login status
 /* const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00"); */

// Access value associated with the login status
let logstatus=sessionStorage.getItem("logStatus00");if((logstatus==null)||(logstatus==" ")){ logstatus=true; }
let logid=sessionStorage.getItem("logId00");if((logid==null)||(logid==" ")){ logid=0; }
let token=sessionStorage.getItem("logToken00");if((token==null)||(token==" ")){ token="0000000000000000000000000"; }
let logname=sessionStorage.getItem("logName00");if((logname==null)||(logname==" ")){ logname="000"; }
let logtype=sessionStorage.getItem("logType00");if((logtype==null)||(logtype==" ")){ logtype="user"; }




function Navbar(){


  const logostyle1 = {
  fontFamily : "Sansita, sans-serif",
  fontWeight : "400",
  fontStyle: "normal",
  };

  const logostyle2 = {
    fontFamily: "Satisfy, cursive",
    fontWeight: "700",
    fontStyle: "bold",
    fontSize:"1.6rem"
  };

  const logostyle3 = {
    fontFamily: "Dancing Script, cursive",
    fontWeight: "700",
    fontStyle: "bold",
    color:"#cc018b",
    fontSize:"1.6rem"
  };
  const logostyle4 = {
    fontFamily: "Dancing Script, cursive",
    fontWeight: "700",
    fontStyle: "bold",
    color:"#000000",
    fontSize:"1.6rem"
  };


  //Setting Default Variables for Page Load

  const [wishlist_count, setwishlist_count] = useState(0);
  const [cart_count, setcart_count] = useState(0);
  

  
 useEffect(()=>{
   
    getData();
    
    },[])

  //******************* GET DATA FUNCTION START  ********************//
  function getData(){
  
    //Checking if user loggedin
   
   axios.post("navbar-default-load", {
    Log_id: logid,
    Token:token
     })
   .then((response) => {

    //Success Data Fetched
    if(response.data.successvar==1){
  
      //Setting Data
      setwishlist_count(response.data.wishlist_count);
      setcart_count(response.data.cart_count);
  
    }
    //Cannot Fetch Data
   else{
    setwishlist_count(0);
    setcart_count(0);
   }
   
  
   
   });
   
   
  }
   
   //******************* GET DATA FUNCTION END  ********************//


return(
<>
   

    {/* Offcanvas Menu Begin */}
    <div class="offcanvas-menu-overlay"></div>
    <div class="offcanvas-menu-wrapper" id="navbar-display1">
        <div class="offcanvas__close">+</div>
        <ul class="offcanvas__widget">
            <li><span class="icon_search search-switch"></span></li>
            <li><a href="#"><span class="icon_heart_alt"></span>
                <div class="tip">2</div>
            </a></li>
            <li><a href="#"><span class="icon_bag_alt"></span>
                <div class="tip">2</div>
            </a></li>
        </ul>
        <div class="offcanvas__logo">
            <a href="/"><img src="img/logo.png" alt="" /></a>
        </div>
        <div id="mobile-menu-wrap"></div>
        <div class="offcanvas__auth">
            <a href="#">Login</a>
            <a href="#">Register</a>
        </div>
    </div>
    {/* Offcanvas Menu End */}

    <div class="row" style={{padding:"0", marginTop:"0", marginBotton:"0", backgroundColor:"#c9d0ce", paddingHorizontal:"1rem"}}>
    <div class="col-lg-1"></div>
                <div class="col-lg-5" style={{padding:"0", mrginTop:"0", marginBotton:"0"}}>
                <div class="row d-flex" style={{padding:"0.5rem", marginTop:"0", marginBotton:"0", flexDirection:"right", marginLeft:"0.5rem"}}>
               <div><h6 style={{padding:"0.5rem", fontSize:"0.8rem"}}><img src="https://cdn-icons-png.flaticon.com/128/13148/13148489.png" style={{width:"1.6rem", height:"1.6rem"}} /><b style={{marginLeft:"0.2rem"}}>+91 923894 0060</b></h6></div>
               <div><h6 style={{padding:"0.5rem", fontSize:"0.8rem"}}><img src="https://cdn-icons-png.flaticon.com/128/3178/3178158.png" style={{width:"1.6rem", height:"1.6rem"}} /><b style={{marginLeft:"0.2rem"}}>Whatjhumka2024@gmail.com</b></h6></div>
               </div>
                </div>
           
                <div class="col-lg-5" style={{padding:"0", marginTop:"0", marginBotton:"0"}}>
                    <div class="header__right" style={{padding:"0.5rem", mrginTop:"0", marginBotton:"0"}}>  
                        <a href="/login"><button class="btn btn-primary" style={{backgroundColor:"#004761", border:"none", borderRadius:"15px", paddingHorizontal:"3rem"}} >Login</button></a>
                        <a href="/register"><button class="btn btn-primary" style={{backgroundColor:"#029e62", border:"none", borderRadius:"15px", marginLeft:"1rem"}}>Register</button></a>         
                    </div>
                </div>
                <div class="col-lg-1"></div>
            </div>
    {/* Header Section Begin */}
    <header class="header" id="navbar-display2">
        <div class="container-fluid">


   





            <div class="row"  style={{padding:"0", marginTop:"0", marginBotton:"0"}}>
                <div class="col-xl-3 col-lg-2"  style={{padding:"0", mrginTop:"0", marginBotton:"0"}}>
                    <div class="header__logo"  style={{padding:"0.5rem", mrginTop:"0", marginBotton:"0"}}>
                        <a href="/"><img src="whatjhumka_logo.jpg" alt="logo" style={{height:"3rem", width:"10rem"}} /></a>
                     {/*} <span style={logostyle4}><b>What<span style={logostyle3}>Jhumka</span><span style={logostyle3}> .in</span></b></span> */}
                    </div>
                </div>
                <div class="col-xl-6 col-lg-7"  style={{padding:"0", marginTop:"0", marginBotton:"0"}}>
                    <nav class="header__menu"  style={{padding:"0.5rem", mrginTop:"0", marginBotton:"0"}}>
                        <ul class="d-flex justify-content-center mt-2">
                            <li class="active" style={{textDecorationColor:"#002017"}}><a href="/" style={{textDecorationColor:"#002017"}}>Home</a></li>
                         {/*}   <li><a href="./shop.html">Shop</a></li> */}
                            <li style={{textDecorationColor:"#002017"}}><a href="/giftboxes" style={{textDecorationColor:"#002017"}}>Earings Combo</a></li>
                            <li style={{textDecorationColor:"#002017"}}><a href="/waaliyan" style={{textDecorationColor:"#002017"}}>Earings</a></li>
                         {/*}   <li><a href="/rings">Rings</a></li>
                         {/*}   <li><a href="#">Pages</a>
                                <ul class="dropdown">
                                    <li><a href="./product-details.html">Product Details</a></li>
                                    <li><a href="./shop-cart.html">Shop Cart</a></li>
                                    <li><a href="./checkout.html">Checkout</a></li>
                                    <li><a href="./blog-details.html">Blog Details</a></li>
                                </ul>
                            </li> *}
                         {/*}   <li><a href="/bracelets">Bracelets</a></li>
                            <li><a href="/necklace">Necklace</a></li>
                            <li><a href="/contact">Contact</a></li>
                        {/*}    <li><a href="./contact.html">Trending</a></li> */}
                        </ul>
                    </nav>
                </div>
                <div class="col-xl-3 col-lg-3"  style={{padding:"0", mrginTop:"0", marginBotton:"0"}}>
                    <div class="header__right"  style={{padding:"0.7rem", mrginTop:"0", marginBotton:"0"}}>
                      {/*}  <div class="header__right__auth">
                            <a href="/login">Login</a>
                            <a href="/register">Register</a>
                        </div> */}
                        <ul class="header__right__widget">
                          {/*}  <li><span class="icon_search search-switch"></span></li> */}
                            <li><a href="/wishlist">{/*<span class="icon_heart_alt"></span>*/} <img src="https://cdn-icons-png.flaticon.com/128/6839/6839987.png" style={{height:"1.6rem", width:"1.6rem"}} />
                                <div class="tip">{wishlist_count}</div>
                            </a></li>
                            <li><a href="/shopping-cart">{/*<span class="icon_bag_alt"></span>*/}<img src="https://cdn-icons-png.flaticon.com/128/3514/3514491.png" style={{height:"1.6rem", width:"1.6rem"}} />
                                <div class="tip">{cart_count}</div>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </div>





          {/* DISPLAY HERE */}

        {/*}  <div class="row d-flex justify-content-end" style={{marginRight:"1rem"}}> 
            <ul class="header__right__widget">
                          {/*}  <li><span class="icon_search search-switch"></span></li> */}
                     {/*}       <li><a href="/wishlist">{/*<span class="icon_heart_alt"></span> <img src="https://cdn-icons-png.flaticon.com/128/6839/6839987.png" style={{height:"1.6rem", width:"1.6rem"}} />
                           {/*}     <div class="tip">{wishlist_count}</div>
                            </a></li>
                            <li><a href="/shopping-cart">{/*<span class="icon_bag_alt"></span>*/}{/*</div><img src="https://cdn-icons-png.flaticon.com/128/3514/3514491.png" style={{height:"1.6rem", width:"1.6rem"}} />
                                <div class="tip">{cart_count}</div>
                            </a></li>
            </ul>
             </div> */}
         
            <div class="canvas__open" style={{border:"none", marginTop:"3rem", marginRight:"1rem"}} >
            <ul class="header__right__widget d-flex justify-content-left" style={{alignItems:"row"}}>
            <li><a href="/wishlist"><span class="icon_heart_alt"></span> {/*<img src="https://cdn-icons-png.flaticon.com/128/6839/6839987.png" style={{height:"1.6rem", width:"1.6rem"}} />*/}
            <div class="tip">{wishlist_count}</div>
            </a></li>
            <li><a href="/shopping-cart"><span class="icon_bag_alt"></span> {/*<img src="https://cdn-icons-png.flaticon.com/128/6839/6839987.png" style={{height:"1.6rem", width:"1.6rem"}} />*/}
            <div class="tip">{cart_count}</div>
            </a></li>
            <li> <span class="fa fa-bars" onClick={()=>{
                      const createForm = document.getElementById('resp-navbar');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}></span> </li>
            </ul>



            {/*}   <i class="fa fa-bars" onClick={()=>{
                      const createForm = document.getElementById('resp-navbar');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}></i> */}
              {/*} <img src="https://cdn-icons-png.flaticon.com/128/10486/10486773.png" />*/}
            </div>
            <div class="sub-header" style={{border:"none", marginTop:"0rem", marginRight:"1rem"}} >
            <ul class="header__right__widget d-flex" style={{alignItems:"row"}}>
            <li><a href="/shop"><p><b>Shop |</b></p> {/*<img src="https://cdn-icons-png.flaticon.com/128/6839/6839987.png" style={{height:"1.6rem", width:"1.6rem"}} />*/}
           
           </a></li>
            <li><a href="/giftboxes"><p><b>Earings Combo |</b></p> {/*<img src="https://cdn-icons-png.flaticon.com/128/6839/6839987.png" style={{height:"1.6rem", width:"1.6rem"}} />*/}
           
            </a></li>
            <li><a href="/waaliyan"><p><b>Earings</b></p>{/*<img src="https://cdn-icons-png.flaticon.com/128/6839/6839987.png" style={{height:"1.6rem", width:"1.6rem"}} />*/}
           
            </a></li>
        
            </ul>



            {/*}   <i class="fa fa-bars" onClick={()=>{
                      const createForm = document.getElementById('resp-navbar');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}></i> */}
              {/*} <img src="https://cdn-icons-png.flaticon.com/128/10486/10486773.png" />*/}
            </div>
        </div>
    </header>


    <div class="resp-navbar" id="resp-navbar" style={{backgroundColor:"white", borderBottom:"0.2rem solid #727271", display:"none"}}>

        <ul style={{textAlign:"left", padding:"2rem", listStyleType:"none"}}>
            <a href="/"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/25/25694.png" style={{height:"1.6rem", width:"1.6rem"}} /> HOME</li></a>
             <a href="/giftboxes"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/324/324687.png" style={{height:"1.6rem", width:"1.6rem"}} /> EARINGS COMBO</li></a>
             <a href="/waaliyan"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/3985/3985821.png" style={{height:"1.6rem", width:"1.6rem"}} />EARINGS</li></a>
           {/*}  <a href="/rings"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/706/706482.png" style={{height:"1.6rem", width:"1.6rem"}} /> RINGS</li></a>
             <a href="/bracelets"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/1823/1823457.png" style={{height:"1.6rem", width:"1.6rem"}} /> BRACELETS</li></a>
             <a href="/necklace"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/775/775385.png" style={{height:"1.6rem", width:"1.6rem"}} /> NECKLACE</li></a>       
            */} <a href="/contact"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/13148/13148489.png" style={{height:"1.6rem", width:"1.6rem"}} /> CONTACT</li></a>
             <a href="/login"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/1828/1828391.png" style={{height:"1.6rem", width:"1.6rem"}} /> LOGIN</li></a>
             <a href="/register"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/6998/6998768.png" style={{height:"1.6rem", width:"1.6rem"}} /> REGISTER</li></a>
             <a href="/wishlist"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/3132/3132924.png" style={{height:"1.6rem", width:"1.6rem"}} /> WISHLIST  <sup style={{backgroundColor:"black",padding:"0.16rem", borderRadius:"50%",color:"#ffffff"}}><b>{wishlist_count}</b></sup></li></a>
             <a href="/shopping-cart"><li style={{marginTop:"1rem",color:"#000000"}}><img src="https://cdn-icons-png.flaticon.com/128/3144/3144456.png" style={{height:"1.6rem", width:"1.6rem"}} /> CART <sup style={{backgroundColor:"black",padding:"0.16rem", borderRadius:"50%",color:"#ffffff"}}><b>{cart_count}</b></sup></li></a>
        </ul>
    </div>
    {/* Header Section End */}

</>
);


}

export default Navbar;