import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';


  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");

function Admin_products(){

 const[data,setData] = useState([]);//SetData Variable

 //Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

//const imgpath = 'http://127.0.0.1:8000/storage/';
const imgpath = 'https://api.whatjhumka.in/storage/';

//Error Variable
const[errorvar,seterrorvar] = useState();



useEffect(()=>{
   
  getData();
  
  },[])


//FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);

  
//product_name
const[product_name,setproduct_name]=useState();const updateproduct_name = (event) => { setproduct_name(event.target.value);}; const[product_name_er,setproduct_name_er]=useState();
//product_code
const[product_code,setproduct_code]=useState();const updateproduct_code = (event) => { setproduct_code(event.target.value);}; const[product_code_er,setproduct_code_er]=useState();
//product_hsn
const[product_hsn,setproduct_hsn]=useState();const updateproduct_hsn = (event) => { setproduct_hsn(event.target.value);}; const[product_hsn_er,setproduct_hsn_er]=useState();
//product_price
const[product_price,setproduct_price]=useState();const updateproduct_price = (event) => { setproduct_price(event.target.value);}; const[product_price_er,setproduct_price_er]=useState();
//product_mrp
const[product_mrp,setproduct_mrp]=useState();const updateproduct_mrp = (event) => { setproduct_mrp(event.target.value);}; const[product_mrp_er,setproduct_mrp_er]=useState();
//product_details
const[product_details,setproduct_details]=useState();const updateproduct_details = (event) => { setproduct_details(event.target.value);}; const[product_details_er,setproduct_details_er]=useState();
//product_discount_per
const[product_discount_per,setproduct_discount_per]=useState();const updateproduct_discount_per = (event) => { setproduct_discount_per(event.target.value);}; const[product_discount_per_er,setproduct_discount_per_er]=useState();
//product_discount_amt
const[product_discount_amt,setproduct_discount_amt]=useState();const updateproduct_discount_amt = (event) => { setproduct_discount_amt(event.target.value);}; const[product_discount_amt_er,setproduct_discount_amt_er]=useState();
//product_label
const[product_label,setproduct_label]=useState();const updateproduct_label = (event) => { setproduct_label(event.target.value);}; const[product_label_er,setproduct_label_er]=useState();
//product_rating
const[product_rating,setproduct_rating]=useState();const updateproduct_rating = (event) => { setproduct_rating(event.target.value);}; const[product_rating_er,setproduct_rating_er]=useState();
//sales_rate
const[sales_rate,setsales_rate]=useState();const updatesales_rate = (event) => { setsales_rate(event.target.value);}; const[sales_rate_er,setsales_rate_er]=useState();
//purchase_rate
const[purchase_rate,setpurchase_rate]=useState();const updatepurchase_rate = (event) => { setpurchase_rate(event.target.value);}; const[purchase_rate_er,setpurchase_rate_er]=useState();
//product_text
const[product_text,setproduct_text]=useState();const updateproduct_text = (event) => { setproduct_text(event.target.value);}; const[product_text_er,setproduct_text_er]=useState();
//product_description
const[product_description,setproduct_description]=useState();const updateproduct_description = (event) => { setproduct_description(event.target.value);}; const[product_description_er,setproduct_description_er]=useState();
//logo_link
const[logo_link,setlogo_link]=useState();const updatelogo_link = (event) => { setlogo_link(event.target.value[0]);};  const[logo_link_er,setlogo_link_er]=useState();
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();

//image upload
//File Upload
const [selectedFile, setSelectedFile] = React.useState(null);
const handleFileChange = (event) => {  const file = event.target.files[0];  setSelectedFile(file); };



//******************* GET PRODUCT DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("admin-get-products", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,
  // 'Product_id' :filter_product_id,
  // 'Product_name' :filter_product_name

   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");


   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET PRODUCT DATA FUNCTION END  ********************//


/***************** ADD NEW PRODUCT FUNCTION START  ******************/
function addproduct(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setproduct_name_er(" "); setproduct_code_er(" ");setproduct_hsn_er(" ");setproduct_price_er(" ");setproduct_mrp_er(" ");setproduct_details_er(" ");setproduct_discount_per_er();setproduct_discount_amt_er();
    setproduct_label_er();setproduct_rating_er();setsales_rate_er();setpurchase_rate_er();setproduct_description_er();setlogo_link_er();seterror_msg(" ");

    
//Fetching Form Data
let noerror = 0;
const $product_name = document.getElementById("product_name").value; 
const $product_code = document.getElementById("product_code").value; 
const $product_hsn = document.getElementById("product_hsn").value; 
const $product_price = document.getElementById("product_price").value; 
const $product_mrp = document.getElementById("product_mrp").value; 
const $product_details = document.getElementById("product_details").value; 
const $product_discount_per = document.getElementById("product_discount_per").value; 
const $product_discount_amt = document.getElementById("product_discount_amt").value; 
const $product_label = document.getElementById("product_label").value; 
const $product_rating = document.getElementById("product_rating").value; 
const $sales_rate = document.getElementById("sales_rate").value; 
const $purchase_rate = document.getElementById("purchase_rate").value; 
const $product_description = document.getElementById("product_description").value; 
const $product_text = document.getElementById("product_text").value; 

//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("admin-add-new-product", { 
  Log_id:logid,
  Token:token,
  Product_name:$product_name,
  Product_code:$product_code,
  Product_hsn:$product_hsn,
  Product_price:$product_price,
  Product_mrp:$product_mrp,
  Details:$product_details,
  Product_dis_per:$product_discount_per,
  Product_dis_amt:$product_discount_amt,
  Product_label:$product_label,
  Product_rating:$product_rating,
  Sales_rate:$sales_rate,
  Purchases_rate:$purchase_rate,
  Product_description:$product_description,
  Product_text:$product_text,
  Product_image_link:selectedFile
  },{
  headers: { "Content-Type": "multipart/form-data" },
  }
    )
  
  .then((response) => {

  
  //Validation Errors
  if(response.data.validatorerror){
  setproduct_name_er(response.data.validatorerror.Product_name);
  setproduct_code_er(response.data.validatorerror.Product_code);
  setproduct_hsn_er(response.data.validatorerror.Product_hsn);
  setproduct_price_er(response.data.validatorerror.Product_price);
  setproduct_mrp_er(response.data.validatorerror.Product_mrp);
  setproduct_details_er(response.data.validatorerror.Details);
  setproduct_discount_per_er(response.data.validatorerror.Product_dis_amt);
  setproduct_discount_amt_er(response.data.validatorerror.Product_dis_amt);
  setproduct_label_er(response.data.validatorerror.Product_label);
  setproduct_rating_er(response.data.validatorerror.Product_rating);
  setsales_rate_er(response.data.validatorerror.Sales_rate);
  setpurchase_rate_er(response.data.validatorerror.Purchases_rate);
  setproduct_description_er(response.data.validatorerror.Product_description);
  setproduct_text_er(response.data.validatorerror.Product_text);
  setlogo_link_er(response.data.validatorerror.Product_image_link);
  seterror_msg("Cannot Add Product !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  
  setproduct_name_er(response.data.product_name_er);
  setproduct_code_er(response.data.product_code_er);
  setproduct_hsn_er(response.data.product_hsn_er);
  setproduct_price_er(response.data.product_price_er);
  setproduct_mrp_er(response.data.product_mrp_er);
  setproduct_details_er(response.data.product_details_er);
  setproduct_discount_per_er(response.data.product_dis_per_er);
  setproduct_discount_amt_er(response.data.product_dis_amt_er);
  setproduct_label_er(response.data.product_label_er);
  setproduct_rating_er(response.data.product_rating_er);
  setsales_rate_er(response.data.sales_rate_er);
  setpurchase_rate_er(response.data.purchase_rate_er);
  setproduct_description_er(response.data.product_description_er);
  setproduct_text_er(response.data.product_text_er);
  setlogo_link_er(response.data.product_image_link_er);
  seterror_msg("Cannot Add Product !");
  
  
  }
  
  //Mentor Already Exists
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  
  
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card").style.display="block";

  
  }
  //failure
  if(response.data.successvar==6){
  seterror_msg("Product Not Added !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}




    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">


              {/*  SUCCESS CARD START */}
               <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-products"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{
                      const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}>
                  <i class="mdi mdi-plus-circle"></i> Add New Product </button> 
              </div>
            </div>


        
          {/* ADD ADMIN START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">ADD NEW PRODUCT</h4>
                    <div class="form-sample">
                      <p class="card-description">add new product to product list</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Modern White Jhumka" class="form-control" id="product_name" onChange={updateproduct_name} />
                              <code>{product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="JH123" class="form-control" id="product_code" onChange={updateproduct_code} />
                              <code>{product_code_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Hsn<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="452145" class="form-control" id="product_hsn" onChange={updateproduct_hsn} />
                              <code>{product_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Price<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="1000.00" class="form-control" id="product_price" onChange={updateproduct_price} />
                              <code>{product_price_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product MRP<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="1250.00" class="form-control" id="product_mrp" onChange={updateproduct_mrp} />
                              <code>{product_mrp_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Details<code>*</code></label>
                            <div class="col-sm-9">
                         {/*    */}
                            <input type="text" placeholder="box" class="form-control" id="product_details" onChange={updateproduct_details} />

                              <code>{product_details_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Discount %<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="10.00" class="form-control" id="product_discount_per" onChange={updateproduct_discount_per} />
                              <code>{product_discount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Discount Amount<code></code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="250.00" class="form-control" id="product_discount_amt" onChange={updateproduct_discount_amt} />
                              <code>{product_discount_amt_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Label<code>*</code></label>
                            <div class="col-sm-9">
                              <select class="form-control"  style={{padding:"1rem"}} id="product_label" onChange={updateproduct_label} >
                              <option value="No Label">No Label</option>
                              <option value="Most Sold">Most Sold</option>
                              <option value="Out of Stock">Out of Stock</option>
                              <option value="New Arrival">New Arrival</option>
                              <option value="Sale">Sale</option>
                              </select>
                              <code>{product_label_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Rating<code></code></label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} id="product_rating" onChange={updateproduct_rating}>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                            </select>
                              <code>{product_rating_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sales Rate<code></code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="500.00" class="form-control" id="sales_rate" onChange={updatesales_rate} />
                              <code>{sales_rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Purchase Rate<code></code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="500.00" class="form-control" id="purchase_rate" onChange={updatepurchase_rate} />
                              <code>{purchase_rate_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Product Text</label>
                        <textarea class="form-control"  rows="4" id="product_text" onChange={updateproduct_text} ></textarea>
                        <code>{product_text_er}</code>
                      </div>
                      <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Product Description</label>
                        <textarea class="form-control"  rows="4" id="product_description" onChange={updateproduct_description} ></textarea>
                        <code>{product_description_er}</code>
                      </div>
                      <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Upload Product Image</label>
                            <div class="col-sm-3">
                              <input type="file" class="form-control"  id="logo_link" onChange={handleFileChange}  />
                              {selectedFile && <p>Selected file: {selectedFile.name}</p>}
                              <code>{logo_link_er}</code>
                            </div>
                          <div class="col-sm-5">
                          <p>Allowed File format (.png and .jpg), Max. file Size Allowed 1mb. </p>
                          </div>
                        </div>
                      </div>

                          <button class="btn btn-lg btn-primary" onClick={addproduct}>Add New Product</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* ADD ADMIN END */}

                </div>
         
        
            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Products</h4>
                    <p class="card-description">view your products here
                    </p>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th><b>Product Id</b></th>
                            <th><b>Product Code</b></th>
                            <th><b>Product HSN</b></th>
                            <th><b>Product Name</b></th>
                            <th><b>Brand</b></th>
                            <th><b>Label</b></th>
                            <th><b>Description</b></th>
                            <th><b>Category</b></th>
                            <th><b>Price</b></th>
                            <th><b>MRP</b></th>
                            <th><b>Details</b></th>
                            <th><b>Discount %</b></th>
                            <th><b>Updated On</b></th>
                            <th><b>Remarks</b></th>
                            <th><b>Manage</b></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr>
                          <td>
                             {/*} <img  src={imgpath+curElm.Product_image_link.slice(7)} class="me-2" alt="image" />  */}
                             <img  src={imgpath+curElm.Product_image_link.slice(7)}  class="me-2" alt="image" />  
                       {/*}   <img  src="http://127.0.0.1:8000/storage/Startups/startupdefault.jpg" class="me-2" alt="image" />  */}
                            </td>
                            <td>{curElm.Product_id}</td>
                            <td>{curElm.Product_code}</td>
                            <td>{curElm.Product_hsn}</td>
                            <td>{curElm.Product_name}</td>
                            <td>{curElm.Product_brand}</td>
                            <td>
                            <div class="badge" style={{backgroundColor:"blue"}} hidden={(curElm.Product_label=="New Arrival")||(curElm.Product_label=="No Label")||(curElm.Product_label=="Out of Stock")||(curElm.Product_label=="Most Sold") } >{curElm.Product_label}</div>
                            <div class="badge badge-success" hidden={(curElm.Product_label=="Sale")||(curElm.Product_label=="No Label")||(curElm.Product_label=="Out of Stock")||(curElm.Product_label=="Most Sold") } >{curElm.Product_label}</div>
                            <div class="badge" style={{backgroundColor:"#e5156d"}} hidden={(curElm.Product_label=="Sale")||(curElm.Product_label=="No Label")||(curElm.Product_label=="New Arrival")||(curElm.Product_label=="Most Sold") } >{curElm.Product_label}</div>
                            <div class="badge" style={{backgroundColor:"#e6aa15"}} hidden={(curElm.Product_label=="Sale")||(curElm.Product_label=="No Label")||(curElm.Product_label=="New Arrival")||(curElm.Product_label=="Out of Stock") } >{curElm.Product_label}</div>
                            
                            </td>
                            <td>{curElm.Product_description}</td>
                            <td>{curElm.Product_category}</td>
                            <td>{curElm.Product_price}</td>
                            <td>{curElm.Product_mrp}</td>
                            <td>{curElm.Details}</td>
                            <td>{curElm.Product_dis_per}</td>
                            <td>{curElm.Date_updated}
                            <br/>{curElm.Time_updated}</td>
                            <td>{curElm.Remarks}</td> 
                            <td><button class="btn btn-primary" onClick={()=>{
                              sessionStorage.setItem("ProdId00", curElm.Product_id);
                              window.location.replace("/admin-edit-product");
                            }}>manage</button></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }

                        </tbody>
                      </table>

                   
                    </div>
                         {/* PAGINATION SECTION START */}
                         <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690080.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690084.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>

          </div>

          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_products;



