import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");





function Admin_filters(){

  
    
 const[data,setData] = useState([]);//SetData Variable

 //Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 // const imgpath = 'http://127.0.0.1:8000/storage/';
//const imgpath = 'https://thestartupconnect.com/apidata/storage/';

//Wishlist Product Id
//const [wishlist_product_id, setwishlist_product_id] = useState(1);
const [error_msg, seterror_msg] = useState();
const [error_msg1, seterror_msg1] = useState();
const [wishlist_action, setwishlist_action] = useState();

//filter_value
const[filter_value,setfilter_value]=useState();const updatefilter_value = (event) => { setfilter_value(event.target.value);}; const[filter_value_er,setfilter_value_er]=useState();  
//filter_category
const[filter_category,setfilter_category]=useState();const updatefilter_category = (event) => { setfilter_category(event.target.value);}; const[filter_category_er,setfilter_category_er]=useState();  
//filter_type
const[filter_type,setfilter_type]=useState();const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();  



useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET DATA FUNCTION START  ********************//
function getData(){

  //Checking if user loggedin
 
 axios.post("admin-panel-get-filters", {
  Log_id:logid,
  Token:token,

   //Code Edits for pagination
   'page' : pageNumber

   })
 .then((response) => {

  //Success Data Fetched
  if(response.data.successvar==1){

    //Setting Data
    setData(response.data.data.data);

  }
  //Cannot Fetch Data
 else{
  alert("Cannot Fetch Data");
 }
 

 
 });
 
 
}




/***************** ADD NEW FILTER FUNCTION START  ******************/
function addfilter(){

  
    //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){
  
  
      //Setting Error Variables
      setfilter_value_er(" ");setfilter_category_er(" ");

  //Fetching Form Data
  let noerror = 0;
  const $filter_value = document.getElementById("filter_value").value; 
  const $filter_category = document.getElementById("filter_category").value; 
  
  //No primary errors
  if(noerror==0){
  
  
    //Sending Data through axios
    axios.post("create-new-filter", { 
    Log_id:logid,
    Token:token,
    Filter_value:$filter_value,
    Filter_category:$filter_category,
    Filter_type:'page'
  
    }
      )
    
    .then((response) => {

     
    
    //Validation Errors
    if(response.data.validatorerror){
    setfilter_value_er(response.data.validatorerror.Filter_value);
    setfilter_category_er(response.data.validatorerror.Filter_category);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    seterror_msg("Cannot Add Filter !");
    
    
    }
    //Invalid Entries
    if(response.data.error==102){ 
    setfilter_value_er(response.data.filter_value_er);
    setfilter_category_er(response.data.filter_category_er);
    setfilter_type_er(response.data.filter_type_er);
    seterror_msg("Cannot Add Filter !");
    
    
    }
    
    //Filter Already Exists
    if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
    
    
    
    //Success
    if(response.data.successvar==1){
    
    document.getElementById("success-card").style.display="block";
  
    
    }
    //failure
    if(response.data.successvar==6){
    seterror_msg("Filter Not Added !");
    }
    }    );
    
    }
    
  
    }
    //User not Logedin
   else{
   
   //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   
   window.location.replace("/");
   
   
   }    
  
  }
  
  /***************** ADD NEW FILTER FUNCTION END  ******************/
  
  


    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">

                {/*  SUCCESS CARD START */}
                <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Filter Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-filters"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

                {/*  SUCCESS CARD START */}
                <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Filter Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-filters"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

            {/* DELETE SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="error-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Filter Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-filters"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  DELETE SUCCESS CARD END */}

            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
             
                  <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{
                      const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}>
                  <i class="mdi mdi-plus-circle"></i> New Filter</button> 
              </div>
            </div>


   {/* ADD FILTER START */}
   <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">ADD NEW FILTER</h4>
                    <div class="form-sample">
                      <p class="card-description">add new filter</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Filter Value<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" id="filter_value" onChange={updatefilter_value} />
                              <code>{filter_value_er}</code>
                            </div>
                          </div>
                        </div>
                   
                       {/*}   </div>
                      <div class="row"> */}
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Filter_category<code>*</code></label>
                            <div class="col-sm-9">
                              <select type="text" class="form-control" id="filter_category" onChange={updatefilter_category} style={{padding:"1rem"}}>
                              <option value="shop">shop</option>
                              <option value="giftboxes">giftboxes</option>
                              <option value="waaliyan">waaliyan</option>
                              <option value="rings">rings</option>
                              <option value="bracelets">bracelets</option>
                              <option value="necklace">necklace</option>
                              </select>
                              <code>{filter_category_er}</code>
                            </div>
                          </div>
                        </div>
                       {/*} <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Filter_type<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="123" class="form-control" id="filter_type" onChange={updatefilter_type} />
                              <code>{filter_type_er}</code>
                            </div>
                          </div>
                          </div> */}
                          </div>
                    
                          <button class="btn btn-lg btn-primary" onClick={addfilter}>Add New Filter</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* ADD INVENTORY END */}
                </div>

            {/* chart row starts here */}
          {/*}  <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Payments <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 2,409 <span class="text-success h5">4,5%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg orders/Day</span>
                    <div class="line-chart-wrapper">
                      <canvas id="linechart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Summary <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 0.40% <span class="text-success h5">0.20%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg registrations/Day</span>
                    <div class="bar-chart-wrapper">
                      <canvas id="barchart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
         
        
            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Filters</h4>
               {/*}     <p class="card-description">view your payments here
                    </p> */}
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th><b>Filter Id</b></th>
                            <th><b>Filter Value</b></th>
                            <th><b>Category</b></th>
                            <th><b>Type</b></th>
                            <th><b>Date Created</b></th>
                            <th><b>Time Created</b></th>
                          </tr>
                        </thead>
                        <tbody>
                        
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr>
                            <td>{curElm.Filter_id}</td>
                            <td>{curElm.Filter_value}</td>
                            <td>{curElm.Filter_category}</td>
                            <td>{curElm.Filter_type}</td>                   
                            <td>{curElm.Date_created}</td>
                            <td>{curElm.Time_created}</td>
                            <td><button class="btn btn-danger" onClick={()=>{
                              

/***************** DELETE FILTER FUNCTION START  ******************/
  
//Checking if user loggedin
if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


  //Setting Error Variables
  setfilter_value_er(" ");setfilter_category_er(" ");

//Fetching Form Data
let noerror = 0;
const $filter_id = curElm.Filter_id;

//No primary errors
if(noerror==0){


//Sending Data through axios
axios.post("delete-filter", { 
Log_id:logid,
Token:token,
Filter_id:$filter_id,


}
  )

.then((response) => {

//Validation Errors
if(response.data.validatorerror){document.getElementById("error-card1").style.display="block";}
//Invalid Entries
if(response.data.error==102){ document.getElementById("error-card1").style.display="block";}

//Filter Already Exists
if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }

//Success
if(response.data.successvar==1){

document.getElementById("success-card1").style.display="block";


}
//failure
if(response.data.successvar==6){
    document.getElementById("error-card1").style.display="block";
}
}    );

}


}
//User not Logedin
else{

//Remove Session Variables and redirect to login
sessionStorage.removeItem("logStatus00");
sessionStorage.removeItem("logId00");
sessionStorage.removeItem("logToken00");
sessionStorage.removeItem("logName00");

window.location.replace("/");


}    



/***************** DELETE FILTER FUNCTION END  ******************/

                            }}>Delete</button></td>
                          
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

          </div>

          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_filters;



