import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");



function Admin_orders(){

  
    
 const[data,setData] = useState([]);//SetData Variable

 //Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

 // const imgpath = 'http://127.0.0.1:8000/storage/';
//const imgpath = 'https://thestartupconnect.com/apidata/storage/';

//Wishlist Product Id
//const [wishlist_product_id, setwishlist_product_id] = useState(1);
const [error_msg, seterror_msg] = useState();
const [error_msg1, seterror_msg1] = useState();
const [wishlist_action, setwishlist_action] = useState();

//order_id
const[order_id,setorder_id]=useState();const updateorder_id = (event) => { setorder_id(event.target.value);}; const[order_id_er,setorder_id_er]=useState();  
//order_status
const[order_status,setorder_status]=useState();const updateorder_status = (event) => { setorder_status(event.target.value);}; const[order_status_er,setorder_status_er]=useState();  
//order_remarks
const[order_remarks,setorder_remarks]=useState();const updateorder_remarks = (event) => { setorder_remarks(event.target.value);}; const[order_remarks_er,setorder_remarks_er]=useState();  


useEffect(()=>{
   
    getData();
    
    },[])
    
  //******************* GET DATA FUNCTION START  ********************//
  function getData(){
  
    //Checking if user loggedin
   
   axios.post("admin-panel-get-orders", {
    Log_id:logid,
    Token:token,
  
     //Code Edits for pagination
     'page' : pageNumber
  
     })
   .then((response) => {
  
    //Success Data Fetched
    if(response.data.successvar==1){
  
      //Setting Data
      setData(response.data.data.data);
  
    }
    //Cannot Fetch Data
   else{
    alert("Cannot Fetch Data");
   }
   
  
   
   });
   
   
  }


  

/***************** UPDATE ORDER STATUS FUNCTION START  ******************/
function update_status_function(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setorder_status_er(" ");setorder_remarks_er(" ");
  



  //Sending Data through axios
  axios.post("admin-update-order-status", { 
  Log_id:logid,
  Token:token,
  Order_id:document.getElementById('order_id').value,
  Order_status:document.getElementById("order_status").value,
  Order_remarks:document.getElementById("order_remarks").value,
  
  }
    )
  
  .then((response) => {

  //  console.log(order_id);

  //  console.log(response);
  
  //Validation Errors
  if(response.data.validatorerror){
  setorder_id_er(response.data.validatorerror.Order_id);
  setorder_remarks_er(response.data.validatorerror.Order_remarks);
  setorder_status_er(response.data.validatorerror.Order_status);
  seterror_msg("Cannot Update Order Status !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  setorder_id_er(response.data.order_id_er);
  setorder_status_er(response.data.order_status_er);
  setorder_remarks_er(response.data.order_remarks_er);
  seterror_msg("Cannot Update Order Status !");
  
  
  }
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card").style.display="block";

  
  }
  //failure
  if(response.data.successvar==6){
  seterror_msg("Cannot Update Order Status !");
  }
  }    );
  
  }
  

  
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** UPDATE ORDER STATUS FUNCTION END  ******************/




    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Order Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-orders"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                {/*<button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                  <i class="mdi mdi-plus-circle"></i> Add Prodcut </button> */}
              </div>
            </div>

            {/* chart row starts here */}
          {/*}  <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Orders <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 2,409 <span class="text-success h5">4,5%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg orders/Day</span>
                    <div class="line-chart-wrapper">
                      <canvas id="linechart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Summary <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 0.40% <span class="text-success h5">0.20%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg registrations/Day</span>
                    <div class="bar-chart-wrapper">
                      <canvas id="barchart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
         
                       {/* EDIT ORDER CARD START */}
                       <div  id="edit-status-box"  style={{display:"none", marginBottom:"1rem"}}>
                       <div class="row col-lg-12 d-flex justify-content-center">
                       <div class="card col-md-6">
                       <h3 class="mt-2">Update Order Status</h3>
                       <code>{error_msg}</code>
                       <hr/>

                       <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Order Status<code>*</code></label>
                            <div class="col-sm-9">
                            <select placeholder="123" class="form-control" style={{padding:"1rem"}} id="order_status" value={order_status} onChange={updateorder_status}>
                            <option value="delivered">delivered</option>
                            <option value="dispatched">dispatched</option>
                            <option value="in process">in process</option>
                            <option value="payment completed">payment completed</option>
                            </select>
                              <code>{order_status_er}</code>
                            </div>
                       </div>
                       <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Order Remarks<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="order remarks here" class="form-control" id="order_remarks" value={order_remarks} onChange={updateorder_remarks} />
                              <code>{order_remarks_er}</code>
                            </div>
                       </div>

                       <input type="hidden" class="form-control" id="order_id" value={order_id}/>

                       <code></code>
                       <div class="form-group row">
                      
                       <button class="btn btn-primary" onClick={update_status_function}>Update Status</button>
                      
                       <button class="btn btn-danger" onClick={()=>{
                        //resetting text fields 
                        document.getElementById("order_id").value="";
                        document.getElementById("order_status").value="";
                        document.getElementById("edit-status-box").style.display="none";
                       }}>Cancel</button>
                     
                       </div>
                       </div>
                       </div>
                       </div>
                       {/* EDIT ORDER CARD END */}
                        
            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">

                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Orders</h4>
                    <p class="card-description">view your orders here
                    </p>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th><b>Order Id</b></th>
                            <th><b>WhatsApp</b></th>
                            <th><b>Order Number</b></th>
                            <th><b>Customer Id</b></th>
                            <th><b>Customer Name</b></th>
                            <th><b>Email</b></th>
                            <th><b>Contact</b></th>
                            <th><b>Status</b></th>
                            <th><b>Order Value</b></th>
                            <th><b>View Order</b></th>
                            <th><b>Edit Status</b></th>
                          </tr>
                        </thead>
                        <tbody>

                     
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr>
                            <td>{curElm.Order_id}</td>
                            <td>
                              
                            {/* Whatsapp Chat Icon Start */}
                            <div class="wtp-chat-container" /*style={{position:"fixed",marginTop:"92vh", marginLeft:"94vw"}}*/>
                            <a aria-label="Chat on WhatsApp" onClick={()=>{
                              let contact = curElm.Contact; // Replace this with the dynamic contact number
                              let cust_name = curElm.First_name;
                              let order_no = curElm.Order_number;
                              let order_amt = curElm.Total;
                              let message = `Hey `+cust_name+`
                              Thanks for Shopping with WhatJhumka.in! 
                              We have received your order request and our team has started processing the same.

                              Your order no. is `+order_no+` and Order Amount is Rs. `+order_amt+`

                               Feel free to reach out to us in case of any queries.

                              Regards,
                              Whatjhumka Team`;

                             let encodedMessage = encodeURIComponent(message);
                             let whatsappUrl = `https://wa.me/${contact}?text=${encodedMessage}`;
                             window.open(whatsappUrl, '_blank');

                            }}>   
                            <img class="wtp-chat-icon" src="https://cdn-icons-png.flaticon.com/128/3938/3938058.png" /*style={{height:"3rem", width:"3rem"}}*/></img>
                            </a>
                            </div>
                            {/* Whatsapp Chat Icon End */}
                            </td>
                            <td>{curElm.Order_number}</td>
                            <td>{curElm.User_id}</td>
                            <td>{curElm.First_name} {curElm.Last_name}</td>
                            <td>{curElm.Email}</td>
                            <td>{curElm.Contact}</td>
                            <td>
                              <label class="badge badge-success" hidden={(curElm.Order_status=="payment initiated")||(curElm.Order_status=="payment completed")||(curElm.Order_status=="checkout")||(curElm.Order_status=="in process")||(curElm.Order_status=="dispatched")||(curElm.Order_status=="payment failed")}>{curElm.Order_status}</label>
                              <label class="badge badge-warning" hidden={(curElm.Order_status=="delivered")||(curElm.Order_status=="payment completed")||(curElm.Order_status=="checkout")||(curElm.Order_status=="in process")||(curElm.Order_status=="dispatched")||(curElm.Order_status=="payment failed")}>{curElm.Order_status}</label>
                              <label class="badge badge-primary" hidden={(curElm.Order_status=="delivered")||(curElm.Order_status=="payment initiated")||(curElm.Order_status=="checkout")||(curElm.Order_status=="in process")||(curElm.Order_status=="dispatched")||(curElm.Order_status=="payment failed")}>{curElm.Order_status}</label>
                              <label class="badge badge-secondary" hidden={(curElm.Order_status=="delivered")||(curElm.Order_status=="payment completed")||(curElm.Order_status=="payment initiated")||(curElm.Order_status=="in process")||(curElm.Order_status=="dispatched")||(curElm.Order_status=="payment failed")}>{curElm.Order_status}</label>
                              <label class="badge badge-info" hidden={(curElm.Order_status=="delivered")||(curElm.Order_status=="payment completed")||(curElm.Order_status=="checkout")||(curElm.Order_status=="payment initiated")||(curElm.Order_status=="dispatched")||(curElm.Order_status=="payment failed")}>{curElm.Order_status}</label>
                              <label class="badge badge-light" hidden={(curElm.Order_status=="delivered")||(curElm.Order_status=="payment completed")||(curElm.Order_status=="checkout")||(curElm.Order_status=="in process")||(curElm.Order_status=="payment initiated")||(curElm.Order_status=="payment failed")}>{curElm.Order_status}</label>
                              <label class="badge badge-danger" hidden={(curElm.Order_status=="delivered")||(curElm.Order_status=="payment completed")||(curElm.Order_status=="checkout")||(curElm.Order_status=="in process")||(curElm.Order_status=="payment initiated")||(curElm.Order_status=="dispatched")}>{curElm.Order_status}</label>
                            </td>
                            <td>{curElm.Total}</td>
                            <td><button class="btn btn-primary" onClick={()=>{
                              //Setting Order Id
                              sessionStorage.setItem("orderId00", curElm.Order_id);
                              window.location.replace("/admin-view-order");
                            }}>view</button></td>
                             <td><button class="btn" onClick={()=>{
                              document.getElementById("order_id").value=curElm.Order_id;
                              document.getElementById("order_status").value=curElm.Order_status;
                              document.getElementById("edit-status-box").style.display="block";
                            }}><i class="mdi mdi-pencil-box text-warning text-warning"></i></button></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                      
                        </tbody>
                      </table>

                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690080.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690084.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                    </div>
                  </div>
                </div>
              </div>

          </div>

          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_orders;



