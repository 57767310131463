import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from './Supportingfiles/navbar';
import Header from './Homesections/header';
import Products from './Homesections/products';
import Banner from './Homesections/banner';
import Trend from './Homesections/trend';
import Discount from './Homesections/discount';
import Features from './Homesections/features';
import Instagram from './Homesections/instagram';
import Footer from './Supportingfiles/footer';
import axios from './axios.js';

import './Resources/hheader.css';


/*import "/css/bootstrap.min.css";
import "/css/font-awesome.min.css";
import "/css/elegant-icons.css";
import "/css/jquery-ui.min.css";
import "/css/magnific-popup.css";
import "/css/owl.carousel.min.css";
import "/css/slicknav.min.css";
import "/css/style.css";*/


  
  //Checking Sessions
  // Access value associated with the login status
  let logstatus=sessionStorage.getItem("logStatus00");if((logstatus==null)||(logstatus==" ")||(logstatus=="undefined")){ logstatus=true; }
  let logid=sessionStorage.getItem("logId00");if((logid==null)||(logid==" ")||(logid=="undefined")){ logid=0; }
  let token=sessionStorage.getItem("logToken00");if((token==null)||(token==" ")||(token=="undefined")){ token="0000000000000000000000000"; }
  let logname=sessionStorage.getItem("logName00");if((logname==null)||(logname==" ")||(logname=="undefined")){ logname="000"; }
  let logtype=sessionStorage.getItem("logType00");if((logtype==null)||(logtype==" ")||(logtype=="undefined")){ logtype="user"; }



function Home(){


    //const imgpath = 'http://127.0.0.1:8000/storage/';
    //const imgpath = 'https://api.whatjhumka.in/storage/';
    const imgpath = 'https://whatjhumka.in/api/storage/';
  
  //VARIABLES

  const [bnb, setbnb] = useState(0);



  //Wishlist_chnage 
  const [wishlist_change, setwishlist_change] = useState();
  const [error_msg, seterror_msg] = useState();
  const [error_msg1, seterror_msg1] = useState();

  //HEADERS
  
  //HomeHeader1
  const [hm_id1, sethm_id1] = useState();
  const [hm_code1, sethm_code1] = useState();
  const [hm_title1, sethm_title1] = useState();
  const [card_heading1, setcard_heading1] = useState();
  const [card_text1, setcard_text1] = useState();
  const [button_text1, setbutton_text1] = useState();
  const [button_link1, setbutton_link1] = useState(); 
  const [image_title1, setimage_title1] = useState();
  const [image_link1, setimage_link1] = useState();
  
  //HomeHeader2
  const [hm_id2, sethm_id2] = useState();
  const [hm_code2, sethm_code2] = useState();
  const [hm_title2, sethm_title2] = useState();
  const [card_heading2, setcard_heading2] = useState();
  const [card_text2, setcard_text2] = useState();
  const [button_text2, setbutton_text2] = useState();
  const [button_link2, setbutton_link2] = useState(); 
  const [image_title2, setimage_title2] = useState();
  const [image_link2, setimage_link2] = useState();
  
  //HomeHeader3
  const [hm_id3, sethm_id3] = useState();
  const [hm_code3, sethm_code3] = useState();
  const [hm_title3, sethm_title3] = useState();
  const [card_heading3, setcard_heading3] = useState();
  const [card_text3, setcard_text3] = useState();
  const [button_text3, setbutton_text3] = useState();
  const [button_link3, setbutton_link3] = useState(); 
  const [image_title3, setimage_title3] = useState();
  const [image_link3, setimage_link3] = useState();
  
  //HomeHeader4
  const [hm_id4, sethm_id4] = useState();
  const [hm_code4, sethm_code4] = useState();
  const [hm_title4, sethm_title4] = useState();
  const [card_heading4, setcard_heading4] = useState();
  const [card_text4, setcard_text4] = useState();
  const [button_text4, setbutton_text4] = useState();
  const [button_link4, setbutton_link4] = useState(); 
  const [image_title4, setimage_title4] = useState();
  const [image_link4, setimage_link4] = useState();
  
  //HomeHeader5
  const [hm_id5, sethm_id5] = useState();
  const [hm_code5, sethm_code5] = useState();
  const [hm_title5, sethm_title5] = useState();
  const [card_heading5, setcard_heading5] = useState();
  const [card_text5, setcard_text5] = useState();
  const [button_text5, setbutton_text5] = useState();
  const [button_link5, setbutton_link5] = useState(); 
  const [image_title5, setimage_title5] = useState();
  const [image_link5, setimage_link5] = useState();
  
  //HomePoductCard1
  const [hp_id1, sethp_id1] = useState();
  const [hproduct_id1, sethproduct_id1] = useState();
  const [hproduct_title1, sethproduct_title1] = useState();
  const [hproduct_code1, sethproduct_code1] = useState();
  const [hproduct_rating1, sethproduct_rating1] = useState();
  const [hproduct_mrp1, sethproduct_mrp1] = useState();
  const [hproduct_price1, sethproduct_price1] = useState(); 
  const [hproduct_label1, sethproduct_label1] = useState();
  const [hproduct_image_link1, sethproduct_image_link1] = useState();
  //HomePoductCard2
  const [hp_id2, sethp_id2] = useState();
  const [hproduct_id2, sethproduct_id2] = useState();
  const [hproduct_title2, sethproduct_title2] = useState();
  const [hproduct_code2, sethproduct_code2] = useState();
  const [hproduct_rating2, sethproduct_rating2] = useState();
  const [hproduct_mrp2, sethproduct_mrp2] = useState();
  const [hproduct_price2, sethproduct_price2] = useState(); 
  const [hproduct_label2, sethproduct_label2] = useState();
  const [hproduct_image_link2, sethproduct_image_link2] = useState();
  //HomePoductCard3
  const [hp_id3, sethp_id3] = useState();
  const [hproduct_id3, sethproduct_id3] = useState();
  const [hproduct_title3, sethproduct_title3] = useState();
  const [hproduct_code3, sethproduct_code3] = useState();
  const [hproduct_rating3, sethproduct_rating3] = useState();
  const [hproduct_mrp3, sethproduct_mrp3] = useState();
  const [hproduct_price3, sethproduct_price3] = useState(); 
  const [hproduct_label3, sethproduct_label3] = useState();
  const [hproduct_image_link3, sethproduct_image_link3] = useState();
  //HomePoductCard4
  const [hp_id4, sethp_id4] = useState();
  const [hproduct_id4, sethproduct_id4] = useState();
  const [hproduct_title4, sethproduct_title4] = useState();
  const [hproduct_code4, sethproduct_code4] = useState();
  const [hproduct_rating4, sethproduct_rating4] = useState();
  const [hproduct_mrp4, sethproduct_mrp4] = useState();
  const [hproduct_price4, sethproduct_price4] = useState(); 
  const [hproduct_label4, sethproduct_label4] = useState();
  const [hproduct_image_link4, sethproduct_image_link4] = useState();
  //HomePoductCard5
  const [hp_id5, sethp_id5] = useState();
  const [hproduct_id5, sethproduct_id5] = useState();
  const [hproduct_title5, sethproduct_title5] = useState();
  const [hproduct_code5, sethproduct_code5] = useState();
  const [hproduct_rating5, sethproduct_rating5] = useState();
  const [hproduct_mrp5, sethproduct_mrp5] = useState();
  const [hproduct_price5, sethproduct_price5] = useState(); 
  const [hproduct_label5, sethproduct_label5] = useState();
  const [hproduct_image_link5, sethproduct_image_link5] = useState();
  //HomePoductCard6
  const [hp_id6, sethp_id6] = useState();
  const [hproduct_id6, sethproduct_id6] = useState();
  const [hproduct_title6, sethproduct_title6] = useState();
  const [hproduct_code6, sethproduct_code6] = useState();
  const [hproduct_rating6, sethproduct_rating6] = useState();
  const [hproduct_mrp6, sethproduct_mrp6] = useState();
  const [hproduct_price6, sethproduct_price6] = useState(); 
  const [hproduct_label6, sethproduct_label6] = useState();
  const [hproduct_image_link6, sethproduct_image_link6] = useState();
  //HomePoductCard7
  const [hp_id7, sethp_id7] = useState();
  const [hproduct_id7, sethproduct_id7] = useState();
  const [hproduct_title7, sethproduct_title7] = useState();
  const [hproduct_code7, sethproduct_code7] = useState();
  const [hproduct_rating7, sethproduct_rating7] = useState();
  const [hproduct_mrp7, sethproduct_mrp7] = useState();
  const [hproduct_price7, sethproduct_price7] = useState(); 
  const [hproduct_label7, sethproduct_label7] = useState();
  const [hproduct_image_link7, sethproduct_image_link7] = useState();
  //HomePoductCard8
  const [hp_id8, sethp_id8] = useState();
  const [hproduct_id8, sethproduct_id8] = useState();
  const [hproduct_title8, sethproduct_title8] = useState();
  const [hproduct_code8, sethproduct_code8] = useState();
  const [hproduct_rating8, sethproduct_rating8] = useState();
  const [hproduct_mrp8, sethproduct_mrp8] = useState();
  const [hproduct_price8, sethproduct_price8] = useState(); 
  const [hproduct_label8, sethproduct_label8] = useState();
  const [hproduct_image_link8, sethproduct_image_link8] = useState();

   
    //HOME BANNER SECTION
    const [bhm_id, setbhm_id] = useState();
    const [bhm_code, setbhm_code] = useState();
    const [bhm_title, setbhm_title] = useState();
    const [bcard_heading, setbcard_heading] = useState();
    const [bcard_text, setbcard_text] = useState();
    const [bbutton_text, setbbutton_text] = useState();
    const [bbutton_link, setbbutton_link] = useState(); 
    const [bimage_title, setbimage_title] = useState();
    const [bimage_link, setbimage_link] = useState();

  
  //TREND PRODUCTS
  
  //Trend1Product1
  const [tp_id1, settp_id1] = useState();
  const [trend_id1, settrend_id1] = useState();
  const [trend_title1, settrend_title1] = useState();
  const [tp_product_id1, settp_product_id1] = useState();
  const [tp_product_title1, settp_product_title1] = useState();
  const [tp_product_code1, settp_product_code1] = useState();
  const [tp_product_rating1, settp_product_rating1] = useState();
  const [tp_product_mrp1, settp_product_mrp1] = useState();
  const [tp_product_price1, settp_product_price1] = useState(); 
  const [tp_product_label1, settp_product_label1] = useState();
  const [tp_product_image_link1, settp_product_image_link1] = useState(); 
  //Trend1Product2
  const [tp_id2, settp_id2] = useState();
  const [trend_id2, settrend_id2] = useState();
  const [trend_title2, settrend_title2] = useState();
  const [tp_product_id2, settp_product_id2] = useState();
  const [tp_product_title2, settp_product_title2] = useState();
  const [tp_product_code2, settp_product_code2] = useState();
  const [tp_product_rating2, settp_product_rating2] = useState();
  const [tp_product_mrp2, settp_product_mrp2] = useState();
  const [tp_product_price2, settp_product_price2] = useState(); 
  const [tp_product_label2, settp_product_label2] = useState();
  const [tp_product_image_link2, settp_product_image_link2] = useState();  
  //Trend1Product3
  const [tp_id3, settp_id3] = useState();
  const [trend_id3, settrend_id3] = useState();
  const [trend_title3, settrend_title3] = useState();
  const [tp_product_id3, settp_product_id3] = useState();
  const [tp_product_title3, settp_product_title3] = useState();
  const [tp_product_code3, settp_product_code3] = useState();
  const [tp_product_rating3, settp_product_rating3] = useState();
  const [tp_product_mrp3, settp_product_mrp3] = useState();
  const [tp_product_price3, settp_product_price3] = useState(); 
  const [tp_product_label3, settp_product_label3] = useState();
  const [tp_product_image_link3, settp_product_image_link3] = useState();
  //Trend2Product1
  const [tp_id4, settp_id4] = useState();
  const [trend_id4, settrend_id4] = useState();
  const [trend_title4, settrend_title4] = useState();
  const [tp_product_id4, settp_product_id4] = useState();
  const [tp_product_title4, settp_product_title4] = useState();
  const [tp_product_code4, settp_product_code4] = useState();
  const [tp_product_rating4, settp_product_rating4] = useState();
  const [tp_product_mrp4, settp_product_mrp4] = useState();
  const [tp_product_price4, settp_product_price4] = useState(); 
  const [tp_product_label4, settp_product_label4] = useState();
  const [tp_product_image_link4, settp_product_image_link4] = useState();
  //Trend2Product2
  const [tp_id5, settp_id5] = useState();
  const [trend_id5, settrend_id5] = useState();
  const [trend_title5, settrend_title5] = useState();
  const [tp_product_id5, settp_product_id5] = useState();
  const [tp_product_title5, settp_product_title5] = useState();
  const [tp_product_code5, settp_product_code5] = useState();
  const [tp_product_rating5, settp_product_rating5] = useState();
  const [tp_product_mrp5, settp_product_mrp5] = useState();
  const [tp_product_price5, settp_product_price5] = useState(); 
  const [tp_product_label5, settp_product_label5] = useState();
  const [tp_product_image_link5, settp_product_image_link5] = useState();
  //Trend2Product3
  const [tp_id6, settp_id6] = useState();
  const [trend_id6, settrend_id6] = useState();
  const [trend_title6, settrend_title6] = useState();
  const [tp_product_id6, settp_product_id6] = useState();
  const [tp_product_title6, settp_product_title6] = useState();
  const [tp_product_code6, settp_product_code6] = useState();
  const [tp_product_rating6, settp_product_rating6] = useState();
  const [tp_product_mrp6, settp_product_mrp6] = useState();
  const [tp_product_price6, settp_product_price6] = useState(); 
  const [tp_product_label6, settp_product_label6] = useState();
  const [tp_product_image_link6, settp_product_image_link6] = useState();
  //Trend3Product1
  const [tp_id7, settp_id7] = useState();
  const [trend_id7, settrend_id7] = useState();
  const [trend_title7, settrend_title7] = useState();
  const [tp_product_id7, settp_product_id7] = useState();
  const [tp_product_title7, settp_product_title7] = useState();
  const [tp_product_code7, settp_product_code7] = useState();
  const [tp_product_rating7, settp_product_rating7] = useState();
  const [tp_product_mrp7, settp_product_mrp7] = useState();
  const [tp_product_price7, settp_product_price7] = useState(); 
  const [tp_product_label7, settp_product_label7] = useState();
  const [tp_product_image_link7, settp_product_image_link7] = useState();
  //Trend3Product2
  const [tp_id8, settp_id8] = useState();
  const [trend_id8, settrend_id8] = useState();
  const [trend_title8, settrend_title8] = useState();
  const [tp_product_id8, settp_product_id8] = useState();
  const [tp_product_title8, settp_product_title8] = useState();
  const [tp_product_code8, settp_product_code8] = useState();
  const [tp_product_rating8, settp_product_rating8] = useState();
  const [tp_product_mrp8, settp_product_mrp8] = useState();
  const [tp_product_price8, settp_product_price8] = useState(); 
  const [tp_product_label8, settp_product_label8] = useState();
  const [tp_product_image_link8, settp_product_image_link8] = useState();
  //Trend3Product3
  const [tp_id9, settp_id9] = useState();
  const [trend_id9, settrend_id9] = useState();
  const [trend_title9, settrend_title9] = useState();
  const [tp_product_id9, settp_product_id9] = useState();
  const [tp_product_title9, settp_product_title9] = useState();
  const [tp_product_code9, settp_product_code9] = useState();
  const [tp_product_rating9, settp_product_rating9] = useState();
  const [tp_product_mrp9, settp_product_mrp9] = useState();
  const [tp_product_price9, settp_product_price9] = useState(); 
  const [tp_product_label9, settp_product_label9] = useState();
  const [tp_product_image_link9, settp_product_image_link9] = useState();

  

 useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET DATA FUNCTION START  ********************//
function getData(){
 

 
 axios.post("home-default-load", {
   Token:"LoadMyDefaultPage001"

   })
 .then((response) => {

  //Success Data Fetched
  if(response.data.successvar==1){

    //Setting Data
    
    //Homeheader1
    sethm_id1(response.data.hm_id1);
    sethm_code1(response.data.hm_code1);
    sethm_title1(response.data.hm_title1);
    setcard_heading1(response.data.card_heading1);
    setcard_text1(response.data.card_text1);
    setbutton_text1(response.data.button_text1);
    setbutton_link1(response.data.button_link1);
    setimage_title1(response.data.image_title1);
    setimage_link1((response.data.image_link1).slice(7));

    //Homeheader2
    sethm_id2(response.data.hm_id2);
    sethm_code2(response.data.hm_code2);
    sethm_title2(response.data.hm_title2);
    setcard_heading2(response.data.card_heading2);
    setcard_text2(response.data.card_text2);
    setbutton_text2(response.data.button_text2);
    setbutton_link2(response.data.button_link2);
    setimage_title2(response.data.image_title2);
    setimage_link2((response.data.image_link2).slice(7));

    //Homeheader3
    sethm_id3(response.data.hm_id3);
    sethm_code3(response.data.hm_code3);
    sethm_title3(response.data.hm_title3);
    setcard_heading3(response.data.card_heading3);
    setcard_text3(response.data.card_text3);
    setbutton_text3(response.data.button_text3);
    setbutton_link3(response.data.button_link3);
    setimage_title3(response.data.image_title3);
    setimage_link3((response.data.image_link3).slice(7));

    
    //Homeheader4
    sethm_id4(response.data.hm_id4);
    sethm_code4(response.data.hm_code4);
    sethm_title4(response.data.hm_title4);
    setcard_heading4(response.data.card_heading4);
    setcard_text4(response.data.card_text4);
    setbutton_text4(response.data.button_text4);
    setbutton_link4(response.data.button_link4);
    setimage_title4(response.data.image_title4);
    setimage_link4((response.data.image_link4).slice(7));

    
    //Homeheader5
    sethm_id5(response.data.hm_id5);
    sethm_code5(response.data.hm_code5);
    sethm_title5(response.data.hm_title5);
    setcard_heading5(response.data.card_heading5);
    setcard_text5(response.data.card_text5);
    setbutton_text5(response.data.button_text5);
    setbutton_link5(response.data.button_link5);
    setimage_title5(response.data.image_title5);
    setimage_link5((response.data.image_link5).slice(7));


    //SETTING HOME PRODUCT CARDS DATA
    //HomeProductCard1
    sethp_id1(response.data.hp_id1);
    sethproduct_id1(response.data.hproduct_id1);
    sethproduct_code1(response.data.hproduct_code1);
    sethproduct_title1(response.data.hproduct_title1);
    sethproduct_rating1(response.data.hproduct_rating1);
    sethproduct_label1(response.data.hproduct_label1);
    sethproduct_price1(response.data.hproduct_price1);
    sethproduct_mrp1(response.data.hproduct_mrp1);
    sethproduct_image_link1((response.data.hproduct_image_link1).slice(7));
    //HomeProductCard2
    sethp_id2(response.data.hp_id2);
    sethproduct_id2(response.data.hproduct_id2);
    sethproduct_code2(response.data.hproduct_code2);
    sethproduct_title2(response.data.hproduct_title2);
    sethproduct_rating2(response.data.hproduct_rating2);
    sethproduct_label2(response.data.hproduct_label2);
    sethproduct_price2(response.data.hproduct_price2);
    sethproduct_mrp2(response.data.hproduct_mrp2);
    sethproduct_image_link2((response.data.hproduct_image_link2).slice(7));
   //HomeProductCard3
   sethp_id3(response.data.hp_id3);
   sethproduct_id3(response.data.hproduct_id3);
   sethproduct_code3(response.data.hproduct_code3);
   sethproduct_title3(response.data.hproduct_title3);
   sethproduct_rating3(response.data.hproduct_rating3);
   sethproduct_label3(response.data.hproduct_label3);
   sethproduct_price3(response.data.hproduct_price3);
   sethproduct_mrp3(response.data.hproduct_mrp3);
   sethproduct_image_link3((response.data.hproduct_image_link3).slice(7));
   //HomeProductCard4
   sethp_id4(response.data.hp_id4);
   sethproduct_id4(response.data.hproduct_id4);
   sethproduct_code4(response.data.hproduct_code4);
   sethproduct_title4(response.data.hproduct_title4);
   sethproduct_rating4(response.data.hproduct_rating4);
   sethproduct_label4(response.data.hproduct_label4);
   sethproduct_price4(response.data.hproduct_price4);
   sethproduct_mrp4(response.data.hproduct_mrp4);
   sethproduct_image_link4((response.data.hproduct_image_link4).slice(7));
   //HomeProductCard5
   sethp_id5(response.data.hp_id5);
   sethproduct_id5(response.data.hproduct_id5);
   sethproduct_code5(response.data.hproduct_code5);
   sethproduct_title5(response.data.hproduct_title5);
   sethproduct_rating5(response.data.hproduct_rating5);
   sethproduct_label5(response.data.hproduct_label5);
   sethproduct_price5(response.data.hproduct_price5);
   sethproduct_mrp5(response.data.hproduct_mrp5);
   sethproduct_image_link5((response.data.hproduct_image_link5).slice(7));
   //HomeProductCard6
   sethp_id6(response.data.hp_id6);
   sethproduct_id6(response.data.hproduct_id6);
   sethproduct_code6(response.data.hproduct_code6);
   sethproduct_title6(response.data.hproduct_title6);
   sethproduct_rating6(response.data.hproduct_rating6);
   sethproduct_label6(response.data.hproduct_label6);
   sethproduct_price6(response.data.hproduct_price6);
   sethproduct_mrp6(response.data.hproduct_mrp6);
   sethproduct_image_link6((response.data.hproduct_image_link6).slice(7));
   //HomeProductCard7
   sethp_id7(response.data.hp_id7);
   sethproduct_id7(response.data.hproduct_id7);
   sethproduct_code7(response.data.hproduct_code7);
   sethproduct_title7(response.data.hproduct_title7);
   sethproduct_rating7(response.data.hproduct_rating7);
   sethproduct_label7(response.data.hproduct_label7);
   sethproduct_price7(response.data.hproduct_price7);
   sethproduct_mrp7(response.data.hproduct_mrp7);
   sethproduct_image_link7((response.data.hproduct_image_link7).slice(7));
   //HomeProductCard8
   sethp_id8(response.data.hp_id8);
   sethproduct_id8(response.data.hproduct_id8);
   sethproduct_code8(response.data.hproduct_code8);
   sethproduct_title8(response.data.hproduct_title8);
   sethproduct_rating8(response.data.hproduct_rating8);
   sethproduct_label8(response.data.hproduct_label8);
   sethproduct_price8(response.data.hproduct_price8);
   sethproduct_mrp8(response.data.hproduct_mrp8);
   sethproduct_image_link8((response.data.hproduct_image_link8).slice(7));

   
    //Home Banner
    setbhm_id(response.data.bhm_id);
    setbhm_code(response.data.bhm_code);
    setbhm_title(response.data.bhm_title);
    setbcard_heading(response.data.bcard_heading);
    setbcard_text(response.data.bcard_text);
    setbbutton_text(response.data.bbutton_text);
    setbbutton_link(response.data.bbutton_link);
    setbimage_title(response.data.bimage_title);
    setbimage_link((response.data.bimage_link).slice(7));

     //TRENDS PRODUCT
     //Trend1Product1
     settp_id1(response.data.tp_id1);
     settrend_id1(response.data.trend_id1);
     settrend_title1(response.data.trend_title1);
     settp_product_id1(response.data.tp_product_id1);
     settp_product_code1(response.data.tp_product_code1);
     settp_product_title1(response.data.tp_product_title1);
     settp_product_rating1(response.data.tp_product_rating1);
     settp_product_label1(response.data.tp_product_label1);
     settp_product_price1(response.data.tp_product_price1);
     settp_product_mrp1(response.data.tp_product_mrp1);
     settp_product_image_link1((response.data.tp_product_image_link1).slice(7));
     //Trend1Product2
     settp_id2(response.data.tp_id2);
     settrend_id2(response.data.trend_id2);
     settrend_title2(response.data.trend_title2);
     settp_product_id2(response.data.tp_product_id2);
     settp_product_code2(response.data.tp_product_code2);
     settp_product_title2(response.data.tp_product_title2);
     settp_product_rating2(response.data.tp_product_rating2);
     settp_product_label2(response.data.tp_product_label2);
     settp_product_price2(response.data.tp_product_price2);
     settp_product_mrp2(response.data.tp_product_mrp2);
     settp_product_image_link2((response.data.tp_product_image_link2).slice(7));
     //Trend1Product3
     settp_id3(response.data.tp_id3);
     settrend_id3(response.data.trend_id3);
     settrend_title3(response.data.trend_title3);
     settp_product_id3(response.data.tp_product_id3);
     settp_product_code3(response.data.tp_product_code3);
     settp_product_title3(response.data.tp_product_title3);
     settp_product_rating3(response.data.tp_product_rating3);
     settp_product_label3(response.data.tp_product_label3);
     settp_product_price3(response.data.tp_product_price3);
     settp_product_mrp3(response.data.tp_product_mrp3);
     settp_product_image_link3((response.data.tp_product_image_link3).slice(7));
     //Trend2Product1
     settp_id4(response.data.tp_id4);
     settrend_id4(response.data.trend_id4);
     settrend_title4(response.data.trend_title4);
     settp_product_id4(response.data.tp_product_id4);
     settp_product_code4(response.data.tp_product_code4);
     settp_product_title4(response.data.tp_product_title4);
     settp_product_rating4(response.data.tp_product_rating4);
     settp_product_label4(response.data.tp_product_label4);
     settp_product_price4(response.data.tp_product_price4);
     settp_product_mrp4(response.data.tp_product_mrp4);
     settp_product_image_link4((response.data.tp_product_image_link4).slice(7));
     //Trend2Product2
     settp_id5(response.data.tp_id5);
     settrend_id5(response.data.trend_id5);
     settrend_title5(response.data.trend_title5);
     settp_product_id5(response.data.tp_product_id5);
     settp_product_code5(response.data.tp_product_code5);
     settp_product_title5(response.data.tp_product_title5);
     settp_product_rating5(response.data.tp_product_rating5);
     settp_product_label5(response.data.tp_product_label5);
     settp_product_price5(response.data.tp_product_price5);
     settp_product_mrp5(response.data.tp_product_mrp5);
     settp_product_image_link5((response.data.tp_product_image_link5).slice(7));
     //Trend2Product3
     settp_id6(response.data.tp_id6);
     settrend_id6(response.data.trend_id6);
     settrend_title6(response.data.trend_title6);
     settp_product_id6(response.data.tp_product_id6);
     settp_product_code6(response.data.tp_product_code6);
     settp_product_title6(response.data.tp_product_title6);
     settp_product_rating6(response.data.tp_product_rating6);
     settp_product_label6(response.data.tp_product_label6);
     settp_product_price6(response.data.tp_product_price6);
     settp_product_mrp6(response.data.tp_product_mrp6);
     settp_product_image_link6((response.data.tp_product_image_link6).slice(7));
     //Trend3Product1
     settp_id7(response.data.tp_id7);
     settrend_id7(response.data.trend_id7);
     settrend_title7(response.data.trend_title7);
     settp_product_id7(response.data.tp_product_id7);
     settp_product_code7(response.data.tp_product_code7);
     settp_product_title7(response.data.tp_product_title7);
     settp_product_rating7(response.data.tp_product_rating7);
     settp_product_label7(response.data.tp_product_label7);
     settp_product_price7(response.data.tp_product_price7);
     settp_product_mrp7(response.data.tp_product_mrp7);
     settp_product_image_link7((response.data.tp_product_image_link7).slice(7));
     //Trend3Product2
     settp_id8(response.data.tp_id8);
     settrend_id8(response.data.trend_id8);
     settrend_title8(response.data.trend_title8);
     settp_product_id8(response.data.tp_product_id8);
     settp_product_code8(response.data.tp_product_code8);
     settp_product_title8(response.data.tp_product_title8);
     settp_product_rating8(response.data.tp_product_rating8);
     settp_product_label8(response.data.tp_product_label8);
     settp_product_price8(response.data.tp_product_price8);
     settp_product_mrp8(response.data.tp_product_mrp8);
     settp_product_image_link8((response.data.tp_product_image_link8).slice(7));
     //Trend3Product3
     settp_id9(response.data.tp_id9);
     settrend_id9(response.data.trend_id9);
     settrend_title9(response.data.trend_title9);
     settp_product_id9(response.data.tp_product_id9);
     settp_product_code9(response.data.tp_product_code9);
     settp_product_title9(response.data.tp_product_title9);
     settp_product_rating9(response.data.tp_product_rating9);
     settp_product_label9(response.data.tp_product_label9);
     settp_product_price9(response.data.tp_product_price9);
     settp_product_mrp9(response.data.tp_product_mrp9);
     settp_product_image_link9((response.data.tp_product_image_link9).slice(7));
  

  }
  //Redirect to Dummy Home
 /* else{
    window.location.replace("/home1");
  }*/

 
 });
 
 
 }
  
 //******************* GET DATA FUNCTION END  ********************//


/***************ADD PRODUCT TO WISHLIST FUNCTION START ******************/ 
function addtowishlist(){


    const wishlist_product_id = sessionStorage.getItem("productId00");

     //Checking if user loggedin
    if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(wishlist_product_id>0)){
    
    
    //Setting Error Variables
   // seterror_msg(" "); //setdisplay_rack_id_er(" ");
    
    //Fetching Form Data
    
    //No primary errors
    
    
    //Sending Data through axios
    axios.post("manage-wishlist-product", { 
    Log_id:logid,
    Token:token,
    Product_id:wishlist_product_id,
    Quantity:1,
    // Action: wishlist_action
    Action:"add"
    
    }
    )
    
    .then((response) => {

          //Session Expired
          if(response.data.error==100){
           //Set Sessions
           sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
           sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
           sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
           sessionStorage.setItem("logType00", "user"); //Assign value to logtype
           //  sessionStorage.setItem("logName00", response.data.name); //Assign value to token
           window.location.replace("/");
           return;
    
    
           }
    
    
    //Success
    if(response.data.successvar==1){
    
    document.getElementById("success-card").style.display="block";
    
    
    }
    else{
    //Validation Errors
    if(response.data.validatorerror){
    //  setdisplay_product_id_er(response.data.validatorerror.Product_id);
    //setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
   // seterror_msg("Cannot Add Product to Wishlist !");
    //document.getElementById("error-card").style.display="block";
    
    
    }
    //Invalid Entries
    else if(response.data.error==102){ 
    
    // setdisplay_product_id_er(response.data.product_id_er);
    //setdisplay_rack_id_er(response.data.shop_id_er);
   // seterror_msg("Cannot Add Product to Wishlist !");
   //document.getElementById("error-card").style.display="block";
    
    }
    
    //Product Not Found
    else if(response.data.error==103){
    
  //  seterror_msg(response.data.error_msg);
//document.getElementById("error-card").style.display="block";
    
    }
    
    
    //failure
    else{
    //seterror_msg(response.data.error_msg);
    //document.getElementById("error-card").style.display="block";
    
    }
    }
    }    );
    
    
    
    
    }
    //User not Logedin
    else{
    
    //Remove Session Variables and redirect to login
    sessionStorage.removeItem("logStatus00");
    sessionStorage.removeItem("logId00");
    sessionStorage.removeItem("logToken00");
    sessionStorage.removeItem("logName00");
    
    window.location.replace("/");
    
    
    }    
}
    
    /***************** ADD PRODUCT TO WISHLIST FUNCTION END  ******************/
    



   
  /************************ADD TO CART FUNCTION START **********************/
  function addtocart(){

      
    let cart_quantity =1;
    cart_quantity = document.getElementById("crt-quantity").value;
    const cart_product_id = sessionStorage.getItem("productId00");
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(cart_product_id>0)){
  
  
  //Setting Error Variables
  seterror_msg(" "); //setdisplay_rack_id_er(" ");
  
  //Fetching Form Data
  
  //No primary errors
  
  
  //Sending Data through axios
  axios.post("manage-cart-product", { 
  Log_id:logid,
  Token:token,
  Product_id:cart_product_id,
  Quantity:cart_quantity,
  // Action: wishlist_action
  Action:"add"
  
  }
  )
  
  .then((response) => {
  
  
  
  //Session Expired
  if(response.data.error==100){
  //Set Sessions
  sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
  sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
  sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
  sessionStorage.setItem("logType00", "user"); //Assign value to logtype
  //  sessionStorage.setItem("logName00", response.data.name); //Assign value to token
  window.location.replace("/shop");
  return;
  
  
  }
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("crt-hidden").style.display="none";
  document.getElementById("success-card1").style.display="block";
  
  
  }
  else{
  //Validation Errors
  if(response.data.validatorerror){
  //  setdisplay_product_id_er(response.data.validatorerror.Product_id);
  //setdisplay_rack_id_er(response.data.validatorerror.Shop_id);
  //seterror_msg1("Cannot Add Product to Cart !");
  document.getElementById("crt-hidden").style.display="none";
  //document.getElementById("error-card1").style.display="block";
  
  
  }
  //Invalid Entries
  else if(response.data.error==102){ 
  
  // setdisplay_product_id_er(response.data.product_id_er);
  //setdisplay_rack_id_er(response.data.shop_id_er);
  //seterror_msg1("Cannot Add Product to Cart !");
  document.getElementById("crt-hidden").style.display="none";
  //document.getElementById("error-card1").style.display="block";
  
  }
  
  //Product Not Found
  else if(response.data.error==103){
  
  //seterror_msg1(response.data.error_msg);
  document.getElementById("crt-hidden").style.display="none";
  //document.getElementById("error-card1").style.display="block";
  
  }
  
  
  //failure
  else{
  //seterror_msg1(response.data.error_msg);
  document.getElementById("crt-hidden").style.display="none";
  //document.getElementById("error-card1").style.display="block";
  
  }
  }
  }    );
  
  
  
  
  }
  //User not Logedin
  else{
  
  //Remove Session Variables and redirect to login
  sessionStorage.removeItem("logStatus00");
  sessionStorage.removeItem("logId00");
  sessionStorage.removeItem("logToken00");
  sessionStorage.removeItem("logName00");
  
  window.location.replace("/");
  
  
  }    
  }
  /************************ADD TO CART FUNCTION END **********************/

return(
<>
   

<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="description" content="What Jhumka | Buy Modern Jhumkas and Earings at Best Prices" />
    <meta name="keywords" content="Jhumkas, Earings, Bracelets, Necklace Giftboxes" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="icon" type="image/x-icon" href="/whatjhumka_logo1.ico" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />


   {/* CUSTOM GOOGLE FONTS IMPORTED */}
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />
  
    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" /> 
    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/mypagecss.css" type="text/css" /> 

</head>

<body>
    {/* Page Preloder */}
  {/*}  <div id="preloder">
        <div class="loader"></div>
    </div> */}

   <Navbar/>
  {/* HEADER SECTION START */}

    {/* Categories Section Begin 
    <section class="categories">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 p-0">
                    <div class="categories__item categories__large__item set-bg"
                    data-setbg={imgpath+image_link1} style={{backgroundImage:`url(${imgpath+image_link1})`}}>
                    <div class="categories__text">
                        <h1>{card_heading1}</h1>
                        <p>{card_text1}</p>
                        <a href={button_link1}><button class="btn btn-danger">{button_text1}</button></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                        <div class="categories__item set-bg" data-setbg={imgpath+image_link2} style={{backgroundImage:`url(${imgpath+image_link2})`}}>
                        
                            <div class="categories__text">
                                <h4>{card_heading2}</h4>
                                <p>{card_text2}</p>
                                <a href={button_link2}><button class="btn btn-danger">{button_text2}</button></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                        <div class="categories__item set-bg" data-setbg={imgpath+image_link3} style={{backgroundImage:`url(${imgpath+image_link3})`}}>
                            <div class="categories__text">
                                <h4>{card_heading3}</h4>
                                <p>{card_text3}</p>
                                <a href={button_link3}><button class="btn btn-danger">{button_text3}</button></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                        <div class="categories__item set-bg" data-setbg={imgpath+image_link4} style={{backgroundImage:`url(${imgpath+image_link4})`}}>
                            <div class="categories__text">
                                <h4>{card_heading4}</h4>
                                <p>{card_text4}</p>
                                <a href={button_link4}><button class="btn btn-danger">{button_text4}</button></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                        <div class="categories__item set-bg" data-setbg={imgpath+image_link5} style={{backgroundImage:`url(${imgpath+image_link5})`}}>
                            <div class="categories__text">
                                <h4>{card_heading5}</h4>
                                <p>{card_text5}</p>
                                <a href={button_link5}><button class="btn btn-danger">{button_text5}</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* Categories Section End */}

{/* CUSTOM HEADER START */}

{/* HEADER 1 
<div id="h-home-header1">
<div class="custom-home-header container-fluid">
<div class="row">
<div class="col-lg-12 col-md-12 col-sm-12 p-0">
<div class="set-bg" data-setbg={imgpath+image_link1} style={{backgroundImage:`url(${imgpath+image_link1})`, height:"84vh"

  ,backgroundSize:"cover",
  backgroundPosition:"center",
   display:"flex",
  alignItems:"center",
  justifyContent:"center",
  objectFit: "cover"





}}>
    <div>
     {/*}   <h4 style={{textAlign:"center"}}>{card_heading5}</h4>
        <p>{card_text5}</p> */}
     {/*}   <div class="d-flex justify-content-center" >
        <div style={{marginTop:"65vh"}}><a href={button_link1}  ><button class="btn btn-danger"><b>{button_text1}</b></button></a>
        </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>

{/* HEADER 2 
<div id="h-home-header2">
<div class="custom-home-header container-fluid">
<div class="row">
<div class="col-lg-12 col-md-12 col-sm-12 p-0">
<div class="set-bg" data-setbg={imgpath+image_link2} style={{backgroundImage:`url(${imgpath+image_link2})`, height:"80vh"}}>
    <div>
     {/*}   <h4 style={{textAlign:"center"}}>{card_heading5}</h4>
        <p>{card_text5}</p> */}
     {/*}   <div class="d-flex justify-content-center" >
        <div style={{marginTop:"65vh"}}><a href={button_link2}  ><button class="btn btn-danger"><b>{button_text2}</b></button></a>
        </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>


{/* HEADER 3 
<div id="h-home-header3">
<div class="custom-home-header container-fluid">
<div class="row">
<div class="col-lg-12 col-md-12 col-sm-12 p-0">
<div class="set-bg" data-setbg={imgpath+image_link3} style={{backgroundImage:`url(${imgpath+image_link3})`, height:"80vh"}}>
    <div>
     {/*}   <h4 style={{textAlign:"center"}}>{card_heading5}</h4>
        <p>{card_text5}</p> */}
     {/*}   <div class="d-flex justify-content-center" >
        <div style={{marginTop:"65vh"}}><a href={button_link3}  ><button class="btn btn-danger"><b>{button_text3}</b></button></a>
        </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>



{/* HEADER 4 
<div id="h-home-header4">
<div class="custom-home-header container-fluid">
<div class="row">
<div class="col-lg-12 col-md-12 col-sm-12 p-0">
<div class="set-bg" data-setbg={imgpath+image_link4} style={{backgroundImage:`url(${imgpath+image_link4})`, height:"80vh"}}>
    <div>
     {/*}   <h4 style={{textAlign:"center"}}>{card_heading5}</h4>
        <p>{card_text5}</p> */}
   {/*}     <div class="d-flex justify-content-center" >
        <div style={{marginTop:"65vh"}}><a href={button_link4}  ><button class="btn btn-danger"><b>{button_text4}</b></button></a>
        </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>

{/* CUSTOM HEADER END */}

{/* HEADER SECTION END */}

    {/* WISHLIST SUCCESS CARD START */}
    <div class="alert alert-success" role="alert" id="success-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Product Successfully Added to Wishlist !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/"); }}>Continue to shopping</button>
                </div>
               {/* WISHLIST SUCCESS CARD END */}

                {/* WISHLIST ERROR CARD START */}
                <div class="alert alert-danger" role="alert" id="error-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#cf6969"}}><b>{error_msg}</b></h5>
                <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/"); }}>Continue to shopping</button>
                </div>
               {/* WISHLIST ERROR CARD END */}

               {/* CART SUCCESS CARD START */}
                <div class="alert alert-success" role="alert" id="success-card1" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Product Successfully Added to Cart !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/"); }} hidden={bnb==1}>Continue to shopping</button>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/shopping-cart"); }} hidden={bnb==0}>Continue to shopping</button>

                </div>
               {/* CART SUCCESS CARD END */}

                {/* CART ERROR CARD START */}
                <div class="alert alert-danger" role="alert" id="error-card1" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#cf6969"}}><b>{error_msg1}</b></h5>
                <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/"); }}>Continue to shopping</button>
                </div>
               {/* CART ERROR CARD END */}

{/* PRODUCTS SECTION START */}


           {/* ADD QUANTITIY BOX START */}
           <div class="row d-flex justify-content-center">
                <div class="col-md-6" id="crt-hidden" style={{display:"none", zIndex:"3", border:"0.2rem solid #00141a", position:"fixed", backgroundColor:"#ffffff", borderRadius:"7px", boxShadow:"2px 1px 2px 1px #bcbcbc"}}>
                <div style={{padding:"1rem"}}>
                <h5 style={{textAlign:"center", padding:"1rem"}}><b>Add Product to Cart</b></h5>
                <hr/>
                <div class="row">
                <div class="col-md-6">
                <label>Quantity</label>
                </div>
                <div class="col-md-6">
                <select class="form-control" id="crt-quantity" style={{width:"12rem", padding:"0.1rem", border:"0.1rem solid #00141a", borDderRadius:"10px"}} >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                </select>
                </div>
                </div> 
                <div class="row d-flex justify-content-center mt-3"><button class="btn btn-danger" style={{backgroundColor:"#00141a", border:"0.1rem solid #ffffff"}} onClick={addtocart}>Add to Cart</button></div>
                            
                </div>
                </div>
                </div>
                 {/* ADD QUANTITIY BOX END */}


{/* Product Section Begin */}
<section class="product spad" id="product-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="section-title">
                    <h4>New products</h4>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
               {/*} <ul class="filter__controls">
                    <li class="active" data-filter="*">All</li>
                    <li data-filter=".women">Women’s</li>
                    <li data-filter=".men">Men’s</li>
                    <li data-filter=".kid">Kid’s</li>
                    <li data-filter=".accessories">Accessories</li>
                    <li data-filter=".cosmetic">Cosmetics</li>
                </ul> */}
            </div>
        </div>
        <div class="row property__gallery">
            <div class="col-lg-3 col-md-4 col-sm-6 mix women">
                <div class="product__item" style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link1} style={{backgroundImage:`url(${imgpath+hproduct_image_link1})`, backgroundSize: "contain"}} >
                      
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label1=="New Arrival")||(hproduct_label1=="No Label")||(hproduct_label1=="Out of Stock")||(hproduct_label1=="Most Sold") } >{hproduct_label1}</div>
                        <div class="label new" hidden={(hproduct_label1=="Sale")||(hproduct_label1=="No Label")||(hproduct_label1=="Out of Stock")||(hproduct_label1=="Most Sold") } >{hproduct_label1}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label1=="Sale")||(hproduct_label1=="No Label")||(hproduct_label1=="New Arrival")||(hproduct_label1=="Most Sold") } >{hproduct_label1}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label1=="Sale")||(hproduct_label1=="No Label")||(hproduct_label1=="New Arrival")||(hproduct_label1=="Out of Stock") } >{hproduct_label1}</div>

                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link1} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id1); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#" onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id1);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>
                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}> <h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id1); window.location.replace("/product-details"); }}
                        ><b>{hproduct_title1}</b></a></h6></button>
                       {/* Rating */}
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating1<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating1<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating1<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating1<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating1<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price1}<span>₹ {hproduct_price1}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id1);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mix men">
                <div class="product__item" style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link2} style={{backgroundImage:`url(${imgpath+hproduct_image_link2})`, backgroundSize: "contain"}}>
                                   
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label2=="New Arrival")||(hproduct_label2=="No Label")||(hproduct_label2=="Out of Stock")||(hproduct_label2=="Most Sold") } >{hproduct_label2}</div>
                        <div class="label new" hidden={(hproduct_label2=="Sale")||(hproduct_label2=="No Label")||(hproduct_label2=="Out of Stock")||(hproduct_label2=="Most Sold") } >{hproduct_label2}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label2=="Sale")||(hproduct_label2=="No Label")||(hproduct_label2=="New Arrival")||(hproduct_label2=="Most Sold") } >{hproduct_label2}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label2=="Sale")||(hproduct_label2=="No Label")||(hproduct_label2=="New Arrival")||(hproduct_label2=="Out of Stock") } >{hproduct_label2}</div>

                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link2} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id2); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#" onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id2);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>

                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}><h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id2); window.location.replace("/product-details"); }}><b>{hproduct_title2}</b></a></h6></button>
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating2<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating2<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating2<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating2<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating2<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price2}<span>₹ {hproduct_price2}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id2);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mix accessories">
                <div class="product__item" style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link3} style={{backgroundImage:`url(${imgpath+hproduct_image_link3})`, backgroundSize: "contain"}}>
                                    
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label3=="New Arrival")||(hproduct_label3=="No Label")||(hproduct_label3=="Out of Stock")||(hproduct_label3=="Most Sold") } >{hproduct_label1}</div>
                        <div class="label new" hidden={(hproduct_label3=="Sale")||(hproduct_label3=="No Label")||(hproduct_label3=="Out of Stock")||(hproduct_label3=="Most Sold") } >{hproduct_label3}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label3=="Sale")||(hproduct_label3=="No Label")||(hproduct_label3=="New Arrival")||(hproduct_label3=="Most Sold") } >{hproduct_label1}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label3=="Sale")||(hproduct_label3=="No Label")||(hproduct_label3=="New Arrival")||(hproduct_label3=="Out of Stock") } >{hproduct_label1}</div>

                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link3} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id3); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#" onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id3);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>

                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}><h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id3); window.location.replace("/product-details"); }}><b>{hproduct_title3}</b></a></h6></button>
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating3<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating3<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating3<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating3<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating3<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price3}<span>₹ {hproduct_price3}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id3);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mix cosmetic">
                <div class="product__item" style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link4} style={{backgroundImage:`url(${imgpath+hproduct_image_link4})`, backgroundSize: "contain"}}>
                                  
                                     
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label4=="New Arrival")||(hproduct_label4=="No Label")||(hproduct_label4=="Out of Stock")||(hproduct_label4=="Most Sold") } >{hproduct_label4}</div>
                        <div class="label new" hidden={(hproduct_label4=="Sale")||(hproduct_label4=="No Label")||(hproduct_label4=="Out of Stock")||(hproduct_label4=="Most Sold") } >{hproduct_label4}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label4=="Sale")||(hproduct_label4=="No Label")||(hproduct_label4=="New Arrival")||(hproduct_label4=="Most Sold") } >{hproduct_label4}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label4=="Sale")||(hproduct_label4=="No Label")||(hproduct_label4=="New Arrival")||(hproduct_label4=="Out of Stock") } >{hproduct_label4}</div>


                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link4} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id4); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#" onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id4);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>

                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}><h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id4); window.location.replace("/product-details"); }}><b>{hproduct_title4}</b></a></h6></button>
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating4<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating4<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating4<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating4<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating4<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price4}<span>₹ {hproduct_price4}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id4);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mix kid">
                <div class="product__item" style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link5} style={{backgroundImage:`url(${imgpath+hproduct_image_link5})`, backgroundSize: "contain"}}>
                                   
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label5=="New Arrival")||(hproduct_label5=="No Label")||(hproduct_label5=="Out of Stock")||(hproduct_label5=="Most Sold") } >{hproduct_label5}</div>
                        <div class="label new" hidden={(hproduct_label5=="Sale")||(hproduct_label5=="No Label")||(hproduct_label5=="Out of Stock")||(hproduct_label5=="Most Sold") } >{hproduct_label5}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label5=="Sale")||(hproduct_label5=="No Label")||(hproduct_label5=="New Arrival")||(hproduct_label5=="Most Sold") } >{hproduct_label5}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label5=="Sale")||(hproduct_label5=="No Label")||(hproduct_label5=="New Arrival")||(hproduct_label5=="Out of Stock") } >{hproduct_label5}</div>


                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link5} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id5); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#" onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id5);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>

                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}>   <h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id5); window.location.replace("/product-details"); }}><b>{hproduct_title5}</b></a></h6></button>
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating5<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating5<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating5<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating5<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating5<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price5}<span>₹ {hproduct_price5}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id5);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mix women men kid accessories cosmetic">
                <div class="product__item"  style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link6} style={{backgroundImage:`url(${imgpath+hproduct_image_link6})`, backgroundSize: "contain"}}>
                        <div class="label sale">{hproduct_label6}</div>
                                    
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label6=="New Arrival")||(hproduct_label6=="No Label")||(hproduct_label6=="Out of Stock")||(hproduct_label6=="Most Sold") } >{hproduct_label6}</div>
                        <div class="label new" hidden={(hproduct_label6=="Sale")||(hproduct_label6=="No Label")||(hproduct_label6=="Out of Stock")||(hproduct_label6=="Most Sold") } >{hproduct_label6}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label6=="Sale")||(hproduct_label6=="No Label")||(hproduct_label6=="New Arrival")||(hproduct_label6=="Most Sold") } >{hproduct_label6}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label6=="Sale")||(hproduct_label6=="No Label")||(hproduct_label6=="New Arrival")||(hproduct_label6=="Out of Stock") } >{hproduct_label6}</div>


                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link6} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id6); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#"onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id6);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>

                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}>  <h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id6); window.location.replace("/product-details"); }}><b>{hproduct_title6}</b></a></h6></button>
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating6<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating6<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating6<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating6<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating6<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price6} <span>₹ {hproduct_price6}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id6);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mix women men kid accessories cosmetic">
                <div class="product__item" style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link7} style={{backgroundImage:`url(${imgpath+hproduct_image_link7})`, backgroundSize: "contain"}}>
                                    
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label7=="New Arrival")||(hproduct_label7=="No Label")||(hproduct_label7=="Out of Stock")||(hproduct_label7=="Most Sold") } >{hproduct_label7}</div>
                        <div class="label new" hidden={(hproduct_label7=="Sale")||(hproduct_label7=="No Label")||(hproduct_label7=="Out of Stock")||(hproduct_label7=="Most Sold") } >{hproduct_label7}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label7=="Sale")||(hproduct_label7=="No Label")||(hproduct_label7=="New Arrival")||(hproduct_label7=="Most Sold") } >{hproduct_label7}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label7=="Sale")||(hproduct_label7=="No Label")||(hproduct_label7=="New Arrival")||(hproduct_label7=="Out of Stock") } >{hproduct_label7}</div>


                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link7} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id7); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#" onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id7);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>

                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}> <h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id7); window.location.replace("/product-details"); }}><b>{hproduct_title7}</b></a></h6></button>
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating7<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating7<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating7<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating7<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating7<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price7}<span>₹ {hproduct_price7}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id7);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 mix women men kid accessories cosmetic">
                <div class="product__item"  style={{backgroundColor:"#ececec"}}>
                    <div class="product__item__pic set-bg" data-setbg={imgpath+hproduct_image_link8} style={{backgroundImage:`url(${imgpath+hproduct_image_link8})`, backgroundSize: "contain"}}>
                                    
                        <div class="label new" style={{backgroundColor:"blue"}} hidden={(hproduct_label8=="New Arrival")||(hproduct_label8=="No Label")||(hproduct_label8=="Out of Stock")||(hproduct_label8=="Most Sold") } >{hproduct_label8}</div>
                        <div class="label new" hidden={(hproduct_label8=="Sale")||(hproduct_label8=="No Label")||(hproduct_label8=="Out of Stock")||(hproduct_label8=="Most Sold") } >{hproduct_label8}</div>
                        <div class="label new" style={{backgroundColor:"#e5156d"}} hidden={(hproduct_label8=="Sale")||(hproduct_label8=="No Label")||(hproduct_label8=="New Arrival")||(hproduct_label8=="Most Sold") } >{hproduct_label8}</div>
                        <div class="label new" style={{backgroundColor:"#e6aa15"}} hidden={(hproduct_label8=="Sale")||(hproduct_label8=="No Label")||(hproduct_label8=="New Arrival")||(hproduct_label8=="Out of Stock") } >{hproduct_label8}</div>


                        <ul class="product__hover">
                            <li><a href={imgpath+hproduct_image_link8} class="image-popup"><span class="arrow_expand"></span></a></li>
                            <li><a href="#" onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id8); addtowishlist(); }}><span class="icon_heart_alt" ></span></a></li>
                            <li><a href="#" onClick={()=>{ 
                                  //Setting Product Id Session
                                  sessionStorage.setItem("productId00", hproduct_id8);
                                  document.getElementById("crt-hidden").style.display="block";
                             }}><span class="icon_bag_alt"></span></a></li>

                        </ul>
                    </div>
                    <div class="product__item__text">
                    <button class="btn btn-light" style={{backgroundColor:"#ececec", border:"none"}}> <h6><a onClick={()=>{ sessionStorage.setItem("productId00", hproduct_id8); window.location.replace("/product-details"); }}><b>{hproduct_title8}</b></a></h6></button>
                        <div class="rating">
                            <i class="fa fa-star" hidden={hproduct_rating8<5}></i>
                            <i class="fa fa-star" hidden={hproduct_rating8<4}></i>
                            <i class="fa fa-star" hidden={hproduct_rating8<3}></i>
                            <i class="fa fa-star" hidden={hproduct_rating8<2}></i>
                            <i class="fa fa-star" hidden={hproduct_rating8<1}></i>
                        </div>
                        <div class="product__price">₹ {hproduct_price8}<span>₹ {hproduct_price8}</span></div>
                    </div>
                    <div class="d-flex justify-content-center mt-3" id="buynow-btn"><button class="btn btn-danger" style={{backgroundColor:"#002017", border:"none", marginBottom:"2rem"}} onClick={()=>{
                                    sessionStorage.setItem("productId00", hproduct_id8);
                                    setbnb(1);
                                    document.getElementById("crt-hidden").style.display="block";
                                }}>BUY NOW</button></div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* Product Section End */}

{/* PRODUCTS SECTION END */}   
<div class="d-flex justify-content-center">
<a href="/shop"><button class="btn btn-danger" style={{backgroundColor:"#009784", border:"none"}}>View in Shop</button></a>
</div>
{/* BANNER SECTION START */}
{/* Banner Section Begin */}
{/*<section class="banner set-bg mt-5" data-setbg={imgpath+bimage_link} style={{backgroundImage:`url(${imgpath+bimage_link})`}}>
    <div class="container">
        <div class="row">
            <div class="col-xl-7 col-lg-8 m-auto">
                <div class="banner__slider owl-carousel" style={{display:"block"}}>
                    <div class="banner__item">
                        <div class="banner__text">
                            <span>{bcard_text}</span>
                            <h1>{bcard_heading}</h1>
                            <a href={bbutton_link}>{bbutton_text}</a> 
                        </div>
                    </div> 
            
                </div>
            </div>
        </div>
    </div>
</section>*/}
{/* Banner Section End */}
{/* BANNER SECTION END */}   

{/* TREND SECTION START */}
   
{/* Trend Section Begin */}
<section class="trend spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="trend__content">
                    <div class="section-title">
                        <h4>{trend_title1}</h4>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link1} style={{width:"100%", height:"100%"}} alt="" />
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                          <h6 style={{textAlign:"left"}}>{tp_product_title1}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating1<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating1<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating1<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating1<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating1<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price1}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id1); window.location.replace("/product-details"); }}>View details</button>
                        </div>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link2} style={{width:"100%", height:"100%"}} alt=""  />
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title2}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating2<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating2<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating2<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating2<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating2<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price2}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id2); window.location.replace("/product-details"); }}>View details</button>
                  
                        </div>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link3} style={{width:"100%", height:"100%"}} alt=""  />
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title3}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating3<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating3<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating3<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating3<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating3<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price3}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id3); window.location.replace("/product-details"); }}>View details</button>

                   
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="trend__content">
                    <div class="section-title">
                        <h4>{trend_title4}</h4>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link4} style={{width:"100%", height:"100%"}} alt=""  /> 
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title4}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating4<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating4<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating4<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating4<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating4<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price4}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id4); window.location.replace("/product-details"); }}>View details</button>

                   
                        </div>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link5} style={{width:"100%", height:"100%"}} alt=""  /> 
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title5}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating5<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating5<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating5<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating5<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating5<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price5}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id5); window.location.replace("/product-details"); }}>View details</button>

                   
                        </div>
                    </div>
                    <div class="trend__item d-flex">
                        <div class="trend__item__pic"  style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link6} style={{width:"100%", height:"100%"}} alt=""  /> 
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title6}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating6<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating6<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating6<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating6<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating6<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price6}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id6); window.location.replace("/product-details"); }}>View details</button>

                   
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="trend__content">
                    <div class="section-title">
                        <h4>{trend_title7}</h4>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link7} style={{width:"100%", height:"100%"}} alt=""  /> 
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title7}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating7<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating7<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating7<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating7<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating7<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price7}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id7); window.location.replace("/product-details"); }}>View details</button>

                   
                        </div>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link8} style={{width:"100%", height:"100%"}} alt=""  /> 
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title8}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating8<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating8<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating8<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating8<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating8<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price8}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id8); window.location.replace("/product-details"); }}>View details</button>

                   
                        </div>
                    </div>
                    <div class="trend__item d-flex" >
                        <div class="trend__item__pic" style={{width:"50%"}}>
                            <img src={imgpath+tp_product_image_link9} style={{width:"100%", height:"100%"}} alt=""  /> 
                        </div>
                        <div class="trend__item__text" style={{width:"50%"}}>
                            <h6>{tp_product_title9}</h6>
                            <div class="rating">
                                <i class="fa fa-star" hidden={tp_product_rating9<5}></i>
                                <i class="fa fa-star" hidden={tp_product_rating9<4}></i>
                                <i class="fa fa-star" hidden={tp_product_rating9<3}></i>
                                <i class="fa fa-star" hidden={tp_product_rating9<2}></i>
                                <i class="fa fa-star" hidden={tp_product_rating9<1}></i>
                            </div>
                            <div class="product__price">₹ {tp_product_price9}</div>
                            <button class="btn btn-sm btn-outline-primary mt-2" style={{textAlign:"left", backgroundColor:"#002017", border:"none", color:"#ffffff"}} onClick={()=>{ sessionStorage.setItem("productId00", tp_product_id9); window.location.replace("/product-details"); }}>View details</button>

                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
{/* Trend Section End */}
{/* TREND SECTION END */}

{/*}   <Discount/> */}
   <Features/>
  {/*} <Instagram/> */}
  <hr/>

  <Footer/>

{/* Search Begin */}
{/* <div class="search-model">
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="search-close-switch">+</div>
        <form class="search-model-form">
            <input type="text" id="search-input" placeholder="Search here....." />
        </form>
    </div>
</div> */}
{/* Search End */}

{/* Js Plugins */}
<script src="js/jquery-3.3.1.min.js"></script>
<script src="js/bootstrap.min.js"></script>
<script src="js/jquery.magnific-popup.min.js"></script>
<script src="js/jquery-ui.min.js"></script>
<script src="js/mixitup.min.js"></script>
<script src="js/jquery.countdown.min.js"></script>
<script src="js/jquery.slicknav.js"></script>
<script src="js/owl.carousel.min.js"></script>
<script src="js/jquery.nicescroll.min.js"></script>
<script src="js/main.js"></script>
</body>

</html>

</>
);


}

export default Home;