import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");
  const wishlist_id=sessionStorage.getItem("wishlistId00");




function Admin_view_wishlist(){

  
    
 const[data,setData] = useState([]);//SetData Variable

 //Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

  //const imgpath = 'http://127.0.0.1:8000/storage/';
  const imgpath = 'https://api.whatjhumka.in/storage/';

//Wishlist Product Id
//const [wishlist_product_id, setwishlist_product_id] = useState(1);
const [error_msg, seterror_msg] = useState();
const [error_msg1, seterror_msg1] = useState();
const [wishlist_action, setwishlist_action] = useState();

useEffect(()=>{
   
    getData();
    
    },[])
    
  //******************* GET DATA FUNCTION START  ********************//
  function getData(){
  
    //Checking if user loggedin
   
    axios.post("admin-view-wishlist-products", {
        Log_id:logid,
        Token:token,
        Wishlist_id:wishlist_id,
      
         //Code Edits for pagination
         'page' : pageNumber
      
         })
       .then((response) => {
    
        //Success Data Fetched
        if(response.data.successvar==1){
      
          //Setting Data
          setData(response.data.data.data);
      
        }
        //Cannot Fetch Data
       else{
        alert("Cannot Fetch Data");
       }
       
      
       
       });
   
   
  }
 //******************* GET DATA FUNCTION END  ********************//

 
    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                {/*<button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                  <i class="mdi mdi-plus-circle"></i> Add Prodcut </button> */}
              </div>
            </div>

            {/* chart row starts here */}
          {/*}  <div class="row">
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Logins <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 2,409 <span class="text-success h5">4,5%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg orders/Day</span>
                    <div class="line-chart-wrapper">
                      <canvas id="linechart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="card-title"> Logins <small class="d-block text-muted">August 01 - August 31</small>
                      </div>
                      <div class="d-flex text-muted font-20">
                        <i class="mdi mdi-printer mouse-pointer"></i>
                        <i class="mdi mdi-help-circle-outline ms-2 mouse-pointer"></i>
                      </div>
                    </div>
                    <h3 class="font-weight-bold mb-0"> 0.40% <span class="text-success h5">0.20%<i class="mdi mdi-arrow-up"></i></span>
                    </h3>
                    <span class="text-muted font-13">Avg registrations/Day</span>
                    <div class="bar-chart-wrapper">
                      <canvas id="barchart" height="80"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
         
        
        
              {/*  SUCCESS CARD START */}
              <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Product Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-view-wishlist"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

                   
              {/*  ERROR CARD START */}
              <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Product Not Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-view-wishlist"><button class="mt-3 btn btn-outline-danger">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  ERROR CARD END */}


            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Wishlist</h4>
                    <p class="card-description">wishlist products
                    </p>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr style={{textAlign:"left"}}>
                            <th><b>Product</b></th>
                            <th><b>Price</b></th>
                            <th><b>Quantity</b></th>
                            <th><b>Total</b></th>
                            <th><b></b></th> 
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr style={{textAlign:"left"}}>
                            <td><img src={imgpath+curElm.Product_image_link.slice(7)} alt="" style={{height:"3rem",width:"3rem"}} /> {curElm.Product_title}</td>
                            <td>{curElm.Product_price}</td>
                            <td>{curElm.Quantity}</td>
                            <td>{curElm.Amount}</td>
                            <td><button class="btn" onClick={()=>{

                                const wp_id = curElm.Wp_id;
                                //Checking if user loggedin
   
                                axios.post("admin-remove-wishlist-product", {
   
                                Log_id:logid,
                                Token:token,
                                Wishlist_id:wp_id,
  
                                })
                               .then((response) => {

                                //Success Data Fetched
                                if(response.data.successvar==1){
  
                                //Setting Data
                                //  setData(response.data.data.data);
                                document.getElementById("success-card").style.display="block";
  
                                }
                               //Cannot Delete Data
                               else{
                               // alert("Cannot Fetch Data");
                               document.getElementById("error-card").style.display="block";
                               }
   
  
   
                             });
                            }}><i className="mdi mdi-delete-forever text-danger" style={{fontSize:"1.5rem"}}></i></button></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
   
                    
                        </tbody>
                      </table>
                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690080.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690084.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                    </div>
                  </div>
                </div>
              </div>

          </div>

          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_view_wishlist;



