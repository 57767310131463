import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from './navbar';
import Footer from './footer';
import axios from '../axios.js';




function Contact(){


        
//VARIABLES
//name
const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//message
const[message,setmessage]=useState();const updatemessage = (event) => { setmessage(event.target.value);}; const[message_er,setmessage_er]=useState();
//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


const inputStyle = {
    border: "0.1rem solid #969696",
    padding: "0.8rem",
    width:"100%",
    marginTop:"2rem",
    borderRadius:"5px",
    boxShadow:"0.1rem 0.1rem 0.1rem  #969696",
   // marginBottom:"2rem",
  };

    
/***************** CONTACT SUBMIT FUNCTION START  ******************/
function contactsubmit(){
   
        //Setting Error Variables
       seterror_msg(" "); setname_er(" "); setcontact_er(" "); setemail_er(" "); setmessage_er(" "); 
      
      //Fetching Form Data
      let noerror = 0;
     const  $name = document.getElementById("name").value; if($name==""){ setname_er("Name is required !");noerror = noerror+1; }//}//Fetching Email Value
     const  $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//}//Fetching Email Value
     const  $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//}//Fetching Email Value
     const  $message = document.getElementById("message").value; if($message==""){ setmessage_er("Message is required !");noerror = noerror+1; }//}//Fetching Email Value

  //No primary errors
  if(noerror==0){
  
  
    //Sending Data through axios
    axios.post("contact-us", { 
    Token:"kohY8O7R655456EYyj",
    Name:$name,
    Contact:$contact,
    Email:$email,
    Message:$message
    
    }
      )
    
    .then((response) => {
    
        console.log(response);

      //Success
    if(response.data.successvar==1){
    
        document.getElementById("success-card").style.display="block";
      
        
        }
else{
       //Validation Errors
    if(response.data.validatorerror){

        setname_er(response.data.validatorerror.Name);
        setcontact_er(response.data.validatorerror.Contact);
        setemail_er(response.data.validatorerror.Email);
        setmessage_er(response.data.validatorerror.Message);

        seterror_msg("Cannot Send Message !");
       // document.getElementById("error-card").style.display="block";
    
    
    }
    //Invalid Entries
    else if(response.data.error==102){ 
    
        setname_er(response.data.name_er);
        setcontact_er(response.data.contact_er);
        setemail_er(response.data.email_er);
        setmessage_er(response.data.message_er);
        seterror_msg("Cannot Send Message !");
       // document.getElementById("error-card").style.display="block";
    
    }
  
    //Product Not Found
    else if(response.data.error==103){
    
    seterror_msg(response.data.error_msg);
    //document.getElementById("error-card").style.display="block";
    
    }
    
   
    //failure
    else{
    seterror_msg(response.data.error_msg);
   // document.getElementById("error-card").style.display="block";

    }
}
    }    );
    
    }
    
  
    
  
  }
  /***************** CONTACT SUBMIT FUNCTION END  ******************/





return(
<>

<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="description" content="Ashion Template" />
    <meta name="keywords" content="Ashion, unica, creative, html" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />
    

   {/* CUSTOM GOOGLE FONTS IMPORTED */}
   <link rel="preconnect" href="https://fonts.googleapis.com" />
   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
   <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />


    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 
    
</head>

<body>
   <Navbar />
    {/* Breadcrumb Begin */}
    <div class="breadcrumb-option">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb__links">
                        <a href="/"><i class="fa fa-home"></i> Home</a>
                        <span>Contact</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb End */}

    {/* Contact Section Begin */}
    <section class="contact spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="contact__content">
                   
                        

                              {/* SUCCESS CARD START */}
                              <div class="alert alert-success" role="alert" id="success-card" style={{textAlign:"center", display:"none"}}>
                              <h5 style={{color:"#046b42"}}><b>Message Sent Successfully !</b></h5>
                              <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/"); }}>Continue to Home</button>
                              </div>
                              {/* SUCCESS CARD END */}

                           <div class="contact__form">
                            <h5>SEND MESSAGE</h5>
                            <code>{error_msg}</code>
                        
                                        
                                <input type="text" placeholder="Name" style={inputStyle}  id="name" onChange={updatename}  />        
                                <code>{name_er}</code>
                                <input type="text" placeholder="Email" style={inputStyle} id="email" onChange={updateemail} />
                                <code>{email_er}</code>
                                <input type="text" placeholder="Contact" style={inputStyle} id="contact" onChange={updatecontact} />
                                <code>{contact_er}</code>
                                <textarea placeholder="Message" style={inputStyle} id="message" onChange={updatemessage}></textarea>
                                <code>{message_er}</code>

                                <button type="submit" class="site-btn" style={{marginTop:"1rem"}} onClick={contactsubmit}>Send Message</button>
                               
                        </div>

                        <div class="contact__address" style={{marginTop:"3rem"}}>
                            <h5>Contact info</h5>
                            <ul>
                              {/*}  <li>
                                    <h6><i class="fa fa-map-marker"></i> Address</h6>
                                    <p>160 Pennsylvania Ave NW, Washington, Castle, PA 16101-5161</p>
                                </li> */}
                                <li>
                                    <h6><i class="fa fa-phone"></i> Phone</h6>
                                    <p><span>+91 92389 40060</span></p>
                                </li>
                                <li>
                                    <h6><i class="fa fa-headphones"></i> Support</h6>
                                    <p>Whatjhumka2024@gmail.com</p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
             {/*}   <div class="col-lg-6 col-md-6">
                  {/*}  <div class="contact__map">
                        <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48158.305462977965!2d-74.13283844036356!3d41.02757295168286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2e440473470d7%3A0xcaf503ca2ee57958!2sSaddle%20River%2C%20NJ%2007458%2C%20USA!5e0!3m2!1sen!2sbd!4v1575917275626!5m2!1sen!2sbd"
                        height="780" style={{border:"0"}} allowfullscreen="">
                    </iframe> 
                </div> 
                <img src="https://img.freepik.com/free-photo/portrait-happy-smiling-cheerful-beautiful-young-customer-support-phone-operator-headset-isolated-white-wall_231208-8610.jpg?t=st=1715758133~exp=1715761733~hmac=a2ae2ecdfd06dacf4335b45628cdb109609a38f7e2c37859388da9db91ac9ade&w=360" style={{height:"100%", width:"100%"}} />
            </div> */}
        </div>
    </div> 
</section>
{/* Contact Section End */}

{/* Instagram Begin 
<div class="instagram">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item set-bg" data-setbg="img/instagram/insta-1.jpg">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@ ashion_shop</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item set-bg" data-setbg="img/instagram/insta-2.jpg">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@ ashion_shop</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item set-bg" data-setbg="img/instagram/insta-3.jpg">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@ ashion_shop</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item set-bg" data-setbg="img/instagram/insta-4.jpg">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@ ashion_shop</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item set-bg" data-setbg="img/instagram/insta-5.jpg">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@ ashion_shop</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                <div class="instagram__item set-bg" data-setbg="img/instagram/insta-6.jpg">
                    <div class="instagram__text">
                        <i class="fa fa-instagram"></i>
                        <a href="#">@ ashion_shop</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* Instagram End */}

<Footer/>

{/* Js Plugins */}
<script src="js/jquery-3.3.1.min.js"></script>
<script src="js/bootstrap.min.js"></script>
<script src="js/jquery.magnific-popup.min.js"></script>
<script src="js/jquery-ui.min.js"></script>
<script src="js/mixitup.min.js"></script>
<script src="js/jquery.countdown.min.js"></script>
<script src="js/jquery.slicknav.js"></script>
<script src="js/owl.carousel.min.js"></script>
<script src="js/jquery.nicescroll.min.js"></script>
<script src="js/main.js"></script>
</body>

</html>
</>
);


}

export default Contact;