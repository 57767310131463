import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Navbar from './navbar';
import Footer from './footer';
import axios from '../axios.js';


function Wishlist(){


  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");


    
  const[data,setData] = useState([]);//SetData Variable

  //Variables for pagination
 const [currentPage, setCurrentPage] = useState(1);
 const [pageNumber, setpageNumber] = useState(1);
 
  //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://api.whatjhumka.in/storage/';
  const imgpath = 'https://whatjhumka.in/api/storage/';
  
  
 //Wishlist Product Id
 //const [wishlist_product_id, setwishlist_product_id] = useState(1);
 const [error_msg, seterror_msg] = useState();
 const [wishlist_action, setwishlist_action] = useState();
 
 useEffect(()=>{
    
     getData();
     
     },[])
     
   //******************* GET DATA FUNCTION START  ********************//
   function getData(){
   
     //Checking if user loggedin
    
    axios.post("get-wishlist-products", {
     Log_id:logid,
     Token:token,
   
 
      //Code Edits for pagination
      'page' : pageNumber
   
      })
    .then((response) => {

   
     //Success Data Fetched
     if(response.data.successvar==1){
   
       //Setting Data
       setData(response.data.data.data);
   
     }
     //Cannot Fetch Data
    else{
     //alert("Cannot Fetch Data");
     window.location.replace("/login");
    }
    
   
    
    });
    
    
   }
    

return(
<>
<html lang="zxx">

<head>
    <meta charset="UTF-8" />
    <meta name="description" content="Ashion Template" />
    <meta name="keywords" content="Ashion, unica, creative, html" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <title>What Jhumka | Buy Modern Jhumkas and Earings at Best Prices</title>

    {/* Google Font */}
    <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap"
    rel="stylesheet" />

   {/* CUSTOM GOOGLE FONTS IMPORTED */}
   <link rel="preconnect" href="https://fonts.googleapis.com" />
   <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
   <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Satisfy&family=Secular+One&display=swap" rel="stylesheet" />


    {/* Css Styles */}
    <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

    <link rel="stylesheet" href="https://morecss.netlify.app/css/bootstrap.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/font-awesome.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/elegant-icons.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/jquery-ui.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/magnific-popup.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/owl.carousel.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/slicknav.min.css" type="text/css" />
    <link rel="stylesheet" href="https://morecss.netlify.app/css/style.css" type="text/css" />
    <link rel="stylesheet" href="css/mypagecss.css" type="text/css" /> 

</head>

<body>
    <Navbar/>

    {/* Breadcrumb Begin */}
    <div class="breadcrumb-option">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb__links">
                        <a href="/"><i class="fa fa-home"></i> Home</a>
                        <span>Wishlist</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb End */}

    {/* Shop Cart Section Begin */}
    <section class="shop-cart spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                {/* WISHLIST SUCCESS CARD START */}
                <div class="alert alert-success" role="alert" id="success-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#046b42"}}><b>Product Successfully Removed Wishlist !</b></h5>
                <button class="btn btn-success mt-3" onClick={()=>{ window.location.replace("/wishlist"); }}>Close</button>
                </div>
               {/* WISHLIST SUCCESS CARD END */}

                {/* WISHLIST ERROR CARD START */}
                <div class="alert alert-danger" role="alert" id="error-card" style={{textAlign:"center", display:"none"}}>
                <h5 style={{color:"#cf6969"}}><b>{error_msg}</b></h5>
                <button class="btn btn-danger mt-3" onClick={()=>{ window.location.replace("/wishlist"); }}>Close</button>
                </div>
               {/* WISHLIST ERROR CARD END */}


                    <div class="shop__cart__table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                                <tr>
                                    <td class="cart__product__item">
                                        <img src={imgpath+curElm.Product_image_link.slice(7)} alt="" style={{height:"5rem",width:"5rem"}} />
                                        <div class="cart__product__item__title">
                                            <h6>{curElm.Product_title}</h6>
                                            <a style={{color:"#1d5b9b", fontSize:"0.7rem", fontWeight:"bold"}} onClick={()=>{
                                        //Setting Product Id Session
                                        sessionStorage.setItem("productId00",curElm.Product_id);
                                        window.location.replace("/product-details");
                                    }}>View Product</a>
                                        </div>
                                    </td>
                                    <td class="cart__price">₹ {curElm.Product_price}</td>
                                    <td class="cart__quantity">
                                        <div class="pro-qty">
                                            <input type="text" value={curElm.Quantity} />
                                        </div>
                                    </td>
                                    <td class="cart__total">₹ {curElm.Amount}</td>
                                    <td class="cart__close" onClick={()=>{

                                   const wp_id = curElm.Wp_id;
                                  //Checking if user loggedin
                                  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(wp_id>0)){


                                 //Setting Error Variables
                                  seterror_msg(" "); //setdisplay_rack_id_er(" ");

                                  //Fetching Form Data

                                  //No primary errors


                                  //Sending Data through axios
                                  axios.post("manage-wishlist-product", { 
                                  Log_id:logid,
                                  Token:token,
                                  Product_id:wp_id,
                                  Quantity:1,
                                  // Action: wishlist_action
                                  Action:"remove"

                                  }
                                  )

                                 .then((response) => {



                                 //Success
                                 if(response.data.successvar==1){

                                 document.getElementById("success-card").style.display="block";


                                }
                                else{
                                //Validation Errors
                                if(response.data.validatorerror){

                                seterror_msg("Cannot Remove Product From Wishlist !");
                                document.getElementById("error-card").style.display="block";


                                } 
                                //Invalid Entries
                                else if(response.data.error==102){ 


                                seterror_msg("Cannot Remove Product From Wishlist !");
                                document.getElementById("error-card").style.display="block";

                                }

                               //Product Not Found
                               else if(response.data.error==103){

                               seterror_msg(response.data.error_msg);
                               document.getElementById("error-card").style.display="block";

                               }


                              //failure
                              else{
                              seterror_msg(response.data.error_msg);
                              document.getElementById("error-card").style.display="block";

                              }
                              }
                              }    );




                             }
                             //User not Logedin
                            else{

                            //Remove Session Variables and redirect to login
                            sessionStorage.removeItem("logStatus00");
                            sessionStorage.removeItem("logId00");
                            sessionStorage.removeItem("logToken00");
                            sessionStorage.removeItem("logName00");

                            window.location.replace("/");


                            }     

                            /***************** ADD PRODUCT TO WISHLIST FUNCTION END  ******************/
                            }}><span class="icon_close"></span></td>
                                </tr>
                                </>
                         )
                         }
					   
	                  		 )
                         }
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="cart__btn">
                        <a href="/shop">Continue Shopping</a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                   {/*} <div class="cart__btn update__btn">
                        <a href="#"><span class="icon_loading"></span> Update Wishlist</a>
                    </div> */}
                    <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690080.png" style={{height:"3rem",width:"3rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690084.png" style={{height:"3rem",width:"3rem"}}/></button>
                          </div>
                          </div>
                </div>
            </div>
          {/*}  <div class="row">
                <div class="col-lg-6">
                    <div class="discount__content">
                        <h6>Discount codes</h6>
                        <form action="#">
                            <input type="text" placeholder="Enter your coupon code" />
                            <button type="submit" class="site-btn">Apply</button>
                        </form>
                    </div>
                </div>
                <div class="col-lg-4 offset-lg-2">
                    <div class="cart__total__procced">
                        <h6>Cart total</h6>
                        <ul>
                            <li>Subtotal <span>$ 750.0</span></li>
                            <li>Total <span>$ 750.0</span></li>
                        </ul>
                        <a href="#" class="primary-btn">Proceed to checkout</a>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
    {/* Shop Cart Section End */}

    {/* Instagram Begin 
    <div class="instagram">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-1.jpg">
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-2.jpg">
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-3.jpg">
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-4.jpg">
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-5.jpg">
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                    <div class="instagram__item set-bg" data-setbg="img/instagram/insta-6.jpg">
                        <div class="instagram__text">
                            <i class="fa fa-instagram"></i>
                            <a href="#">@ ashion_shop</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Instagram End */}
    
    <Footer/>

    {/* Js Plugins */}
    <script src="js/jquery-3.3.1.min.js"></script>
    <script src="js/bootstrap.min.js"></script>
    <script src="js/jquery.magnific-popup.min.js"></script>
    <script src="js/jquery-ui.min.js"></script>
    <script src="js/mixitup.min.js"></script>
    <script src="js/jquery.countdown.min.js"></script>
    <script src="js/jquery.slicknav.js"></script>
    <script src="js/owl.carousel.min.js"></script>
    <script src="js/jquery.nicescroll.min.js"></script>
    <script src="js/main.js"></script>
</body>

</html>

</>
);


}

export default Wishlist;