import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';



function Features(){


return(
<>
   
{/* Services Section Begin */}
<section class="services spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="services__item">
                    <i class="fa fa-car" style={{color:"#002017"}}></i>
                    {/* <img src="https://cdn-icons-png.flaticon.com/128/7245/7245083.png" /> */}
                    <h6 class="mt-2">Free Shipping</h6>
                    <p>For all orders over ₹ 499</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="services__item">
                   <i class="fa fa-money" style={{color:"#002017"}}></i>
                   {/*} <img src="https://cdn-icons-png.flaticon.com/128/8782/8782959.png" /> */}
                    <h6 class="mt-2">Easy Exchange</h6>
                    <p>If goods delivered have Problems</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="services__item">
                    <i class="fa fa-support" style={{color:"#002017"}}></i>
                   {/*} <img src="https://cdn-icons-png.flaticon.com/128/10015/10015677.png" /> */}
                    <h6 class="mt-2">Online Support 24/7</h6>
                    <p>Dedicated support</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="services__item">
                    <i class="fa fa-headphones" style={{color:"#002017"}}></i>
                    {/*<img src="https://cdn-icons-png.flaticon.com/128/8385/8385947.png" />*/}
                    <h6 class="mt-2">Payment Secure</h6>
                    <p>100% secure payment</p>
                </div>
            </div>
        </div>
    </div>
</section>
{/* Services Section End */}
</>
);


}

export default Features;