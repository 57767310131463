import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';
import Adminsidebar from '../Supportingfiles/sidebar';
import Adminnavbar from '../Supportingfiles/navbar';
import axios from '../../axios.js';



  //Checking Sessions
  // Access value associated with the login status
  const logstatus=sessionStorage.getItem("logStatus00");
  const logid=sessionStorage.getItem("logId00");
  const token=sessionStorage.getItem("logToken00");
  const logname=sessionStorage.getItem("logName00");
  const logtype=sessionStorage.getItem("logType00");


function Admin_inventory(){

 
  const[data,setData] = useState([]);//SetData Variable

  //Variables for pagination
 const [currentPage, setCurrentPage] = useState(1);
 const [pageNumber, setpageNumber] = useState(1);
 
 //Error Variable
 const[errorvar,seterrorvar] = useState();
 


 useEffect(()=>{
   
  getData();
  
  },[])
 
//FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);



//date
const[date,setdate]=useState();const updatedate = (event) => { setdate(event.target.value);}; const[date_er,setdate_er]=useState(); 
//product_id
const[product_id,setproduct_id]=useState();const updateproduct_id = (event) => { setproduct_id(event.target.value);}; const[product_id_er,setproduct_id_er]=useState();  
//product_name
const[product_name,setproduct_name]=useState();const updateproduct_name = (event) => { setproduct_name(event.target.value);}; const[product_name_er,setproduct_name_er]=useState();
//product_code
const[product_code,setproduct_code]=useState();const updateproduct_code = (event) => { setproduct_code(event.target.value);}; const[product_code_er,setproduct_code_er]=useState();
//product_hsn
const[product_hsn,setproduct_hsn]=useState();const updateproduct_hsn = (event) => { setproduct_hsn(event.target.value);}; const[product_hsn_er,setproduct_hsn_er]=useState();
//transaction
const[transaction,settransaction]=useState();const updatetransaction = (event) => { settransaction(event.target.value);}; const[transaction_er,settransaction_er]=useState();
//quantity
const[quantity,setquantity]=useState();const updatequantity = (event) => { setquantity(event.target.value);}; const[quantity_er,setquantity_er]=useState();
//rate
const[rate,setrate]=useState();const updaterate = (event) => { setrate(event.target.value);}; const[rate_er,setrate_er]=useState();
//discount
const[discount,setdiscount]=useState();const updatediscount = (event) => { setdiscount(event.target.value);}; const[discount_er,setdiscount_er]=useState();
//details
const[details,setdetails]=useState();const updatedetails = (event) => { setdetails(event.target.value);}; const[details_er,setdetails_er]=useState();
//particular
const[particular,setparticular]=useState();const updateparticular = (event) => { setparticular(event.target.value);}; const[particular_er,setparticular_er]=useState();

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


//EDIT VARIABLES
//trno
const[trno,settrno]=useState();
//date1
const[date1,setdate1]=useState();const updatedate1 = (event) => { setdate1(event.target.value);}; const[date1_er,setdate1_er]=useState(); 
//product_id1
const[product_id1,setproduct_id1]=useState();const updateproduct_id1 = (event) => { setproduct_id1(event.target.value);}; const[product_id1_er,setproduct_id1_er]=useState();  
//product_name1
const[product_name1,setproduct_name1]=useState();const updateproduct_name1 = (event) => { setproduct_name1(event.target.value);}; const[product_name1_er,setproduct_name1_er]=useState();
//product_code1
const[product_code1,setproduct_code1]=useState();const updateproduct_code1 = (event) => { setproduct_code1(event.target.value);}; const[product_code1_er,setproduct_code1_er]=useState();
//product_hsn1
const[product_hsn1,setproduct_hsn1]=useState();const updateproduct_hsn1 = (event) => { setproduct_hsn1(event.target.value);}; const[product_hsn1_er,setproduct_hsn1_er]=useState();
//transaction1
const[transaction1,settransaction1]=useState();const updatetransaction1 = (event) => { settransaction1(event.target.value);}; const[transaction1_er,settransaction1_er]=useState();
//quantity1
const[quantity1,setquantity1]=useState();const updatequantity1 = (event) => { setquantity1(event.target.value);}; const[quantity1_er,setquantity1_er]=useState();
//rate1
const[rate1,setrate1]=useState();const updaterate1 = (event) => { setrate1(event.target.value);}; const[rate1_er,setrate1_er]=useState();
//discount1
const[discount1,setdiscount1]=useState();const updatediscount1 = (event) => { setdiscount1(event.target.value);}; const[discount1_er,setdiscount1_er]=useState();
//details1
const[details1,setdetails1]=useState();const updatedetails1 = (event) => { setdetails1(event.target.value);}; const[details1_er,setdetails1_er]=useState();
//particular1
const[particular1,setparticular1]=useState();const updateparticular1 = (event) => { setparticular1(event.target.value);}; const[particular1_er,setparticular1_er]=useState();
//ERROR VARIABLES 
const[error_msg1,seterror_msg1]=useState();


//******************* GET INVENTORY DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("get-inventory-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,
  // 'Product_id' :filter_product_id,
  // 'Product_name' :filter_product_name

   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");


   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET INVENTORY DATA FUNCTION END  ********************//



/***************** ADD NEW TRANSACTION FUNCTION START  ******************/
function addtransaction(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))){


    //Setting Error Variables
    setdate_er(" ");setproduct_id_er(" ");setproduct_name_er(" "); setproduct_code_er(" ");setproduct_hsn_er(" ");settransaction_er(" ");setquantity_er(" ");setrate_er(" ");setdiscount_er();setdetails_er();setparticular_er();
   
    
//Fetching Form Data
let noerror = 0;
const $date = document.getElementById("date").value; 
const $product_id = document.getElementById("product_id").value; 
const $product_name = document.getElementById("product_name").value; 
const $product_code = document.getElementById("product_code").value; 
const $product_hsn = document.getElementById("product_hsn").value; 
const $transaction = document.getElementById("transaction").value; 
const $quantity = document.getElementById("quantity").value; 
const $rate = document.getElementById("rate").value; 
//const $discount = document.getElementById("discount").value; 
const $details = document.getElementById("details").value; 
const $particular = document.getElementById("particular").value; 


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("new-inventory-transaction", { 
  Log_id:logid,
  Token:token,
  Date:$date,
  Product_id:$product_id,
  Product_name:$product_name,
  Product_code:$product_code,
  Product_hsn:$product_hsn,
  Transaction:$transaction,
  Quantity:$quantity,
  Rate:$rate,
  //Discount:$discount,
  Details:$details,
  Particular:$particular,

  }
    )
  
  .then((response) => {

  
  //Validation Errors
  if(response.data.validatorerror){
  setdate_er(response.data.validatorerror.Date);
  setproduct_id_er(response.data.validatorerror.Product_id);
  setproduct_name_er(response.data.validatorerror.Product_name);
  setproduct_code_er(response.data.validatorerror.Product_code);
  setproduct_hsn_er(response.data.validatorerror.Product_hsn);
  settransaction_er(response.data.validatorerror.Transaction);
  setquantity_er(response.data.validatorerror.Quantity);
  setrate_er(response.data.validatorerror.Rate);
  setdiscount_er(response.data.validatorerror.Discount);
  setdetails_er(response.data.validatorerror.Details);
  setparticular_er(response.data.validatorerror.Particular);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  setdate_er(response.data.date_er);
  setproduct_id_er(response.data.product_id_er);
  setproduct_name_er(response.data.product_name_er);
  setproduct_code_er(response.data.product_code_er);
  setproduct_hsn_er(response.data.product_hsn_er);
  settransaction_er(response.data.transaction_er);
  setquantity_er(response.data.quantity_er);
  setrate_er(response.data.product_details_er);
  setdiscount_er(response.data.discount_er);
  setdetails_er(response.data.details_er);
  setparticular_er(response.data.particular_er);
  seterror_msg("Cannot Add Transaction !");
  
  
  }
  
  //Mentor Already Exists
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  
  
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card").style.display="block";

  
  }
  //failure
  if(response.data.successvar==6){
  seterror_msg("Transaction Not Added !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** ADD NEW TRANSACTION FUNCTION END  ******************/






/***************** EDIT TRANSACTION FUNCTION START  ******************/
function edittransaction(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(trno>0)){


    //Setting Error Variables
    setdate_er(" ");setproduct_id_er(" ");setproduct_name_er(" "); setproduct_code_er(" ");setproduct_hsn_er(" ");settransaction_er(" ");setquantity_er(" ");setrate_er(" ");setdiscount_er();setdetails_er();setparticular_er();
   
    
//Fetching Form Data
let noerror = 0;
const $date = document.getElementById("date1").value; 
const $product_id = document.getElementById("product_id1").value; 
const $product_name = document.getElementById("product_name1").value; 
const $product_code = document.getElementById("product_code1").value; 
const $product_hsn = document.getElementById("product_hsn1").value; 
const $transaction = document.getElementById("transaction1").value; 
const $quantity = document.getElementById("quantity1").value; 
const $rate = document.getElementById("rate1").value; 
//const $discount = document.getElementById("discount").value; 
const $details = document.getElementById("details1").value; 
const $particular = document.getElementById("particular1").value; 


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("edit-inventory-transaction", { 
  Log_id:logid,
  Token:token,
  Date:$date,
  Product_id:$product_id,
  Product_name:$product_name,
  Product_code:$product_code,
  Product_hsn:$product_hsn,
  Transaction: $transaction,
  Quantity:$quantity,
  Rate:$rate,
  //Discount:$discount,
  Details:$details,
  Particular:$particular,
  Transaction_no:trno,
  Action:"update"

  }
    )
  
  .then((response) => {

  //Validation Errors
  if(response.data.validatorerror){
  setdate1_er(response.data.validatorerror.Date);
  setproduct_id1_er(response.data.validatorerror.Product_id);
  setproduct_name1_er(response.data.validatorerror.Product_name);
  setproduct_code1_er(response.data.validatorerror.Product_code);
  setproduct_hsn1_er(response.data.validatorerror.Product_hsn);
  settransaction1_er(response.data.validatorerror.Transaction);
  setquantity1_er(response.data.validatorerror.Quantity);
  setrate1_er(response.data.validatorerror.Rate);
  setdiscount1_er(response.data.validatorerror.Discount);
  setdetails1_er(response.data.validatorerror.Details);
  setparticular1_er(response.data.validatorerror.Particular);
  seterror_msg1("Cannot Update Transaction !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  setdate1_er(response.data.date_er);
  setproduct_id1_er(response.data.product_id_er);
  setproduct_name1_er(response.data.product_name_er);
  setproduct_code1_er(response.data.product_code_er);
  setproduct_hsn1_er(response.data.product_hsn_er);
  settransaction1_er(response.data.transaction_er);
  setquantity1_er(response.data.quantity_er);
  setrate1_er(response.data.product_details_er);
  setdiscount1_er(response.data.discount_er);
  setdetails1_er(response.data.details_er);
  setparticular1_er(response.data.particular_er);
  seterror_msg1("Cannot Update Transaction !");
  
  
  }
  
  //Mentor Already Exists
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  
  
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card1").style.display="block";

  
  }
  //failure
  if(response.data.successvar==6){
  seterror_msg("Transaction Not Updated !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** EDIT TRANSACTION FUNCTION END  ******************/



/***************** DELETE TRANSACTION FUNCTION START  ******************/
function deletetransaction(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(trno>0)){


    //Setting Error Variables
    setdate_er(" ");setproduct_id_er(" ");setproduct_name_er(" "); setproduct_code_er(" ");setproduct_hsn_er(" ");settransaction_er(" ");setquantity_er(" ");setrate_er(" ");setdiscount_er();setdetails_er();setparticular_er();
   
    
//Fetching Form Data
let noerror = 0;
const $date = document.getElementById("date1").value; 
const $product_id = document.getElementById("product_id1").value; 
const $product_name = document.getElementById("product_name1").value; 
const $product_code = document.getElementById("product_code1").value; 
const $product_hsn = document.getElementById("product_hsn1").value; 
const $transaction = document.getElementById("transaction1").value; 
const $quantity = document.getElementById("quantity1").value; 
const $rate = document.getElementById("rate1").value; 
//const $discount = document.getElementById("discount").value; 
const $details = document.getElementById("details1").value; 
const $particular = document.getElementById("particular1").value; 


//No primary errors
if(noerror==0){


  //Sending Data through axios
  axios.post("edit-inventory-transaction", { 
  Log_id:logid,
  Token:token,
  Date:$date,
  Product_id:$product_id,
  Product_name:$product_name,
  Product_code:$product_code,
  Product_hsn:$product_hsn,
  Transaction: $transaction,
  Quantity:$quantity,
  Rate:$rate,
  //Discount:$discount,
  Details:$details,
  Particular:$particular,
  Transaction_no:trno,
  Action:"delete"

  }
    )
  
  .then((response) => {

  
  //Validation Errors
  if(response.data.validatorerror){
  setdate1_er(response.data.validatorerror.Date);
  setproduct_id1_er(response.data.validatorerror.Product_id);
  setproduct_name1_er(response.data.validatorerror.Product_name);
  setproduct_code1_er(response.data.validatorerror.Product_code);
  setproduct_hsn1_er(response.data.validatorerror.Product_hsn);
  settransaction1_er(response.data.validatorerror.Transaction);
  setquantity1_er(response.data.validatorerror.Quantity);
  setrate1_er(response.data.validatorerror.Rate);
  setdiscount1_er(response.data.validatorerror.Discount);
  setdetails1_er(response.data.validatorerror.Details);
  setparticular1_er(response.data.validatorerror.Particular);
  seterror_msg1("Cannot Delete Transaction !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 
  setdate1_er(response.data.date_er);
  setproduct_id1_er(response.data.product_id_er);
  setproduct_name1_er(response.data.product_name_er);
  setproduct_code1_er(response.data.product_code_er);
  setproduct_hsn1_er(response.data.product_hsn_er);
  settransaction1_er(response.data.transaction_er);
  setquantity1_er(response.data.quantity_er);
  setrate1_er(response.data.product_details_er);
  setdiscount1_er(response.data.discount_er);
  setdetails1_er(response.data.details_er);
  setparticular1_er(response.data.particular_er);
  seterror_msg1("Cannot Delete Transaction !");
  
  
  }
  
  //Mentor Already Exists
  if(response.data.error==103){     seterror_msg(response.data.error_msg); return; }
  
  
  
  //Success
  if(response.data.successvar==1){
  
  document.getElementById("success-card2").style.display="block";

  
  }
  //failure
  if(response.data.successvar==6){
  seterror_msg("Transaction Not Deleted !");
  }
  }    );
  
  }
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** DELETE TRANSACTION FUNCTION END  ******************/

    return(
<>


<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Admin Panel</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">
   {/*}   <div class="row p-0 m-0 proBanner" id="proBanner">
        <div class="col-md-12 p-0 m-0">
          <div class="card-body card-body-padding d-flex align-items-center justify-content-between">
            <div class="ps-lg-1">
              <div class="d-flex align-items-center justify-content-between">
                <p class="mb-0 font-weight-medium me-3 buy-now-text">Free 24/7 customer support, updates, and more with this template!</p>
                <a href="https://www.bootstrapdash.com/product/plus-admin-template/?utm_source=organic&utm_medium=banner&utm_campaign=buynow_demo" target="_blank" class="btn me-2 buy-now-btn border-0">Get Pro</a>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <a href="https://www.bootstrapdash.com/product/plus-admin-template/"><i class="mdi mdi-home me-3 text-white"></i></a>
              <button id="bannerClose" class="btn border-0 p-0">
                <i class="mdi mdi-close text-white me-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}


     <Adminsidebar/>

      <div class="container-fluid page-body-wrapper">
    
     <Adminnavbar/>

        <div class="main-panel">
          <div class="content-wrapper pb-0">

             {/*  SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-inventory"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}

                 {/* UPDATE SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card1">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-inventory"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  UPDATE SUCCESS CARD END */}

                {/* DELETE SUCCESS CARD START */}
             <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card2">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Transaction Deleted Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-inventory"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  DELETE SUCCESS CARD END */}

            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{
                      const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; 
                }}>
                  <i class="mdi mdi-plus-circle"></i> New Transaction</button> 
              </div>
            </div>

        
            {/* ADD INVENTORY START */}
            <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("create-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                    /*    setedate_er(" ");setetype_er(" ");seteparticular_er(" ");seteamount_er(" ");seterror_msg(" ");   
                        setedate(" ");setetype(" ");seteparticular(" ");seteamount(" ");                  
                        sessionStorage.removeItem("Bankid00");
                        sessionStorage.removeItem("Trno00");   */   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">ADD NEW TRANSACTION</h4>
                    <div class="form-sample">
                      <p class="card-description">add new inventory transaction</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date" onChange={updatedate} />
                              <code>{date_er}</code>
                            </div>
                          </div>
                        </div>
                   
                          </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Transaction<code>*</code></label>
                            <div class="col-sm-9">
                              <select type="text" class="form-control" id="transaction" onChange={updatetransaction} style={{padding:"1rem"}}>
                              <option value="entry">entry</option>
                              <option value="drawings">drawings</option>
                              </select>
                              <code>{transaction_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Id<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="123" class="form-control" id="product_id" onChange={updateproduct_id} />
                              <code>{product_id_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Modern White Jhumka" class="form-control" id="product_name" onChange={updateproduct_name} />
                              <code>{product_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="JH123" class="form-control" id="product_code" onChange={updateproduct_code} />
                              <code>{product_code_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Hsn<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="452145" class="form-control" id="product_hsn" onChange={updateproduct_hsn} />
                              <code>{product_hsn_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="1000.00" class="form-control" id="quantity" onChange={updatequantity} />
                              <code>{quantity_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="1250.00" class="form-control" id="rate" onChange={updaterate} />
                              <code>{rate_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Details<code>*</code></label>
                            <div class="col-sm-9">
                         {/*    */}
                            <input type="text" placeholder="box" class="form-control" id="details" onChange={updatedetails} />

                              <code>{details_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                      
                      
                          <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Particular</label>
                        <textarea class="form-control"  rows="4" id="particular" onChange={updateparticular} ></textarea>
                        <code>{particular_er}</code>
                      </div>
                   
                          <button class="btn btn-lg btn-primary" onClick={addtransaction}>Add New Transaction</button>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* ADD INVENTORY END */}
                </div>


                  {/* EDIT INVENTORY START */}
            <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                         //close edit form button
                         document.getElementById("edit-form").style.display="none";
                        //Setting Variables to empty
                        //Setting Variables to empty 
                        settrno(" ");setdate1(" ");settransaction1(" ");setproduct_id1(" ");setproduct_code1(" "); setproduct_name1(" ");setquantity1(" ");setrate1(" ");setdetails1(" ");setparticular1(" ");   }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                    <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">EDIT INVENTORY TRANSACTION</h4>
                    <div class="form-sample">
                      <p class="card-description">edit inventory transaction</p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" id="date1" onChange={updatedate1} />
                              <code>{date1_er}</code>
                            </div>
                          </div>
                        </div>
                   
                          </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Transaction<code>*</code></label>
                            <div class="col-sm-9">
                              <select type="text" class="form-control" id="transaction1" onChange={updatetransaction1} style={{padding:"1rem"}}>
                              <option value="entry">entry</option>
                              <option value="drawings">drawings</option>
                              </select>
                              <code>{transaction1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Id<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="123" class="form-control" id="product_id1" onChange={updateproduct_id1} />
                              <code>{product_id1_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="Modern White Jhumka" class="form-control" id="product_name1" onChange={updateproduct_name1} />
                              <code>{product_name1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Code<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="JH123" class="form-control" id="product_code1" onChange={updateproduct_code1} />
                              <code>{product_code1_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Product Hsn<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="452145" class="form-control" id="product_hsn1" onChange={updateproduct_hsn1} />
                              <code>{product_hsn1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Quantity<code>*</code></label>
                            <div class="col-sm-9">
                            <input type="text" placeholder="1000.00" class="form-control" id="quantity1" onChange={updatequantity1} />
                              <code>{quantity1_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Rate<code></code></label>
                            <div class="col-sm-9">
                              <input type="text" placeholder="1250.00" class="form-control" id="rate1" onChange={updaterate1} />
                              <code>{rate1_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Details<code>*</code></label>
                            <div class="col-sm-9">
                         {/*    */}
                            <input type="text" placeholder="box" class="form-control" id="details1" onChange={updatedetails1} />

                              <code>{details1_er}</code>
                            </div>
                          </div>
                          </div>
                          </div>
                      
                      
                          <div class="form-group" style={{textAlign:"left"}}>
                        <label for="exampleTextarea1">Particular</label>
                        <textarea class="form-control"  rows="4" id="particular1" onChange={updateparticular1} ></textarea>
                        <code>{particular1_er}</code>
                      </div>
                   
                      <div class="d-flex justify-content-between">
                          <button class="btn btn-lg btn-primary" onClick={edittransaction}>Edit Transaction</button>
                          <button class="btn btn-lg btn-danger" onClick={deletetransaction}>Delete Transaction</button>
                          </div>
                        </div>
                    </div>
                    </div>
                  


                  </div>
                {/* EDIT INVENTORY END */}
                </div>

        
            <div class="row">
              <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Inventory</h4>
                    <p class="card-description">manage your inventory here
                    </p>
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th><b>Tr_no</b></th>
                            <th><b>Date</b></th>
                            <th><b>Product Id</b></th>
                            <th><b>Product Code</b></th>
                            <th><b>Product Name</b></th>
                            <th><b>Transaction</b></th>
                            <th><b>Quantity</b></th>
                            <th><b>Details</b></th>
                            <th><b>Rate</b></th>
                            <th><b>Amount</b></th>
                            <th><b>Updated On</b></th>
                            <th><b>Particular</b></th>
                            <th><b>Manage</b></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          <tr>
                            <td>{curElm.Transaction_id}</td>
                            <td>{curElm.Date}</td>
                            <td>{curElm.Product_id}</td>
                            <td>{curElm.Product_code}</td>
                            <td>{curElm.Product_name}</td>
                            <td> <label class="badge badge-warning" hidden={curElm.Transaction=="entry"}>drawings</label>
                            <label class="badge badge-success" hidden={curElm.Transaction=="drawings"}>entry</label></td>
                            <td>{curElm.Quantity_in+curElm.Quantity_out}</td>
                            <td>{curElm.Details}</td>
                            <td>{curElm.Rate_in+curElm.Rate_out}</td>
                            <td>{curElm.Amount_in+curElm.Amount_out}</td>
                            <td>{curElm.Date_updated}
                            <br/>{curElm.Time_updated}</td>
                            <td>{curElm.Particular}</td>
                            <td><button class="btn" onClick={()=>{
                              settrno(curElm.Transaction_id);
                              document.getElementById("date1").value=(curElm.Date);
                              document.getElementById("transaction1").value=(curElm.Transaction_id);
                              document.getElementById("product_id1").value=(curElm.Product_id);
                              document.getElementById("product_code1").value=(curElm.Product_code);
                              document.getElementById("product_name1").value=(curElm.Product_name);
                              document.getElementById("product_hsn1").value=(curElm.Product_hsn);
                              document.getElementById("quantity1").value=(curElm.Quantity_in+curElm.Quantity_out);
                              document.getElementById("rate1").value=(curElm.Rate_in+curElm.Rate_out);
                              document.getElementById("details1").value=(curElm.Details);
                              document.getElementById("particular1").value=(curElm.Particular);
                              document.getElementById("edit-form").style.display="block";
                            }}><i class="mdi mdi-pencil-box text-warning text-warning"></i></button></td>
                          </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                       
                        </tbody>
                      </table>

                           {/* PAGINATION SECTION START */}
                           <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690080.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><img src="https://cdn-icons-png.flaticon.com/128/5690/5690084.png" style={{height:"1.8rem",width:"1.8rem"}}/></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                    </div>
                  </div>
                </div>
              </div>

          </div>

          </div>
          {/* content-wrapper ends */}
          {/* partial:partials/_footer.html */}
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer>
          {/* partial */}
        </div>
        {/* main-panel ends */}
      </div>
      {/* page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>


</>
);

}

export default Admin_inventory;



